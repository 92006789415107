<template>
  <div class="content">
    <base-header>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="วงล้อ"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดซื้อ</span
              >
              <div class="font-weight-bold" style="font-size: 23px">
                {{spin_buy}}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดรางวัล</span
              >
              <div class="font-weight-bold" style="font-size: 23px">
                {{spin_reward}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="คืนยอดเสีย"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{return_lose}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="เครดิตฟรี"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{credit_free}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="สรุปเครดิตโบนัส"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{bonus_total}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="โบนัสโปรโมชั่น"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{promotion_total}}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="โบนัสเชิญเพื่อน"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{aff}}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ประวัติรับโบนัส
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  alternative
                  name="date1"
                  label="วันที่"
                  addon-right-icon="ni ni-calendar-grid-58"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ disableMobile: true,allowInput: true, enableTime: true }"
                      class="form-control datepicker"
                      v-model="DateStart"
                    >
                    </flat-picker>
                  </template>
                </base-input>
              </div>
              <div class="col-md-4">
                <base-input
                  alternative
                  name="date2"
                  label="ถึง"
                  addon-right-icon="ni ni-calendar-grid-58"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ disableMobile: true,allowInput: true, enableTime: true }"
                      class="form-control datepicker"
                      v-model="DateEnd"
                    >
                    </flat-picker>
                  </template>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="onClickQuery"
                >
                  ค้นหา
                </button>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    ประวัติรับโบนัสทั้งหมด {{ CurcountCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เบอร์โทรศัพท์"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-mobile-alt text-dark mr-2"></i>
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="เครดิต"
                      >
                        <template v-slot:default="props">
                          {{ props.row.credit }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="รายละเอียด"
                      >
                        <template v-slot:default="props">
                          {{ props.row.details }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  data() {
    return {
      promotion_total: "",
      bonus_total: "",
      aff: "",
      websitename: "",
      credit_free: "",
      return_lose: "",
      spin_buy: "",
      spin_reward: "",
      user: "",
      DateStart: null,
      DateEnd: null,
      CurcountCount: "0",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["phone", "details", "credit", "time", "date"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    checkAmountWallet(amount) {
      if (amount != undefined) {
        let value = amount.toString();
        let result = value.includes("-");
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if(resp.data.data != null && resp.data.data.website_name != null)
          {
            this.websitename = resp.data.data.website_name;
          }          
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
    checkNote(note) {
      if (note != undefined) {
        let result = note.includes(this.websitename);
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    onClickQuery() {
      if (this.DateStart != null && this.DateEnd != null && this.user != "") {
        this.fetchByDate();
      } else if (
        this.user == "" &&
        this.DateStart == null &&
        this.DateEnd == null
      ) {
        this.fetchAllData();
      }
    },
    fetchByDate() {
      const startArr = this.DateStart.split(" ");
      const endArr = this.DateEnd.split(" ");
      HTTP.get(
        "/admin/bonus_log?phone=" +
          this.user +
          "&s_date=" +
          startArr[0] +
          "&e_date=" +
          endArr[0] +
          "&s_time=" +
          startArr[1] +
          ":00&e_time=" +
          endArr[1] +
          ":59",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchAllData() {
      HTTP.get("/admin/bonus_log", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
            this.aff = resp.data.aff;
            this.credit_free = resp.data.credit_free;
            this.return_lose = resp.data.return_lose;
            this.spin_buy = resp.data.spin_buy;
            this.spin_reward = resp.data.spin_reward;
            this.bonus_total = resp.data.bonus_total;
            this.promotion_total = resp.data.promotion_total;
          }

        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
