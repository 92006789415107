<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานการฝาก / ถอนตามบุคคล
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  col-md-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    สมาชิกทั้งหมด {{ CurcountCount }} คน
                  </h3>
                </template>
                <div>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เบอร์"
                      >
                        <template v-slot:default="props">
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวนครั้งที่ฝาก"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.deposit_count) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ฝากทั้งหมด"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.deposit_amount) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="จำนวนครั้งที่รับโบนัส"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.bonus_count) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="โบนัสทั้งหมด"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.bonus_amount) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="จำนวนครั้งที่ถอน"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.withdraw_count) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="ถอนทั้งหมด"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.withdraw_amount) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="กำไร / ขาดทุน (ไม่รวมโบนัส)"
                      >
                        <template v-slot:default="props">
                          <div
                            v-if="props.row.profit > 0"
                          >
                            <i class="fa fa-arrow-circle-up text-success mr-2"></i>{{ toFloat(props.row.profit) }}
                          </div>
                          <div
                            v-else>
                            <i class="fa fa-arrow-circle-down text-danger mr-2"></i>{{ toFloat(props.row.profit) }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  data() {
    return {
      user: "",
      DateStart: null,
      DateEnd: null,
      CurcountCount: "0",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 500, 1000, 5000],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["phone"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    toFloat: function (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fetchAllData() {
      HTTP.get("/admin/report", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
