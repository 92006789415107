<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการส่งกิจกรรมแชร์เฟส
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
                <!-- {{ searchQuery }} -->
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-default"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการส่งกิจกรรมทั้งหมด {{ WithdrawCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="90px"
                        align="center"
                        label="สมาชิก"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-mobile-alt text-dark mr-2"></i>
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="ลิ้งค์"
                      >
                        <template v-slot:default="props">
                          {{ props.row.url }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="70px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="70px"
                        align="center"
                        label="สถานะ"
                      >
                        <template v-slot:default="props">
                          <badge type="default">{{ props.row.status }}</badge>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="จัดการ"
                      >
                        <template v-slot:default="props">
                          <base-button
                            size="sm"
                            type="primary"
                            icon="fas fal-check text-green"
                            @click="onConfirm(props.row.id)"
                            :disabled="disAuto_w"
                            >อนุมัติ</base-button
                          >
                          <base-button
                            size="sm"
                            type="primary"
                            icon="fas fal-check text-green"
                            @click="onClickCancel(props.row.id)"
                            :disabled="disAuto_w"
                            >ไม่อนุมัติ</base-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <modal v-model:show="Modal.Cancel" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">ยกเลิก</h3>
            </div>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="desc_id" class="form-control-label">เหตุผล</label>
                  <input
                    id="desc_id"
                    name="desc"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="FormCancel.desc"
                  />
                </div>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-primary" @click="onSubmitCancel">
                ยืนยัน
              </button>
              <base-button
                type="danger"
                @click="
                  (Modal.Cancel = false),
                    (FormCancel.desc = ''),
                    (FormCancel.id = '')
                "
                >ปิด</base-button
              >
            </div>
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import modal from "../../../components/Modal.vue";
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import "flatpickr/dist/flatpickr.css";
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  components: {
    modal,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      disAuto_w: false,
      FormCancel: {
        desc: "",
        id: "",
      },
      Modal: {
        Cancel: false,
      },
      loading: false,
      WithdrawCount: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["date", "time", "phone"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
    this.fetchPendingEventCount();
  },
  mounted() {
    this.intervalfetchPendingEventCount();
  },
  methods: {
    intervalfetchPendingEventCount() {
      setInterval(() => {
        this.fetchPendingEventCount();
      }, 5000);
    },
    fetchPendingEventCount() {
      HTTP.get("/admin/Notifaceshare", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            swal.fire({
              title: "แจ้งเตือน...",
              text:
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถโหลดข้อมูลแจ้งถอนได้ กรุณาลองใหม่",
              icon: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ตกลง",
            });
          } else {
            this.fetchAllData();
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              swal.fire({
                title: "แจ้งเตือน...",
                text:
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถโหลดข้อมูลแจ้งถอนได้ กรุณาลองใหม่",
                icon: "error",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    },
    onSubmitCancel() {
      if (this.FormCancel.desc != "") {
        HTTP.post(
          "/admin/Notifaceshare?cancle",
          {
            id: this.FormCancel.id,
            description: this.FormCancel.desc,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.Modal.Cancel = false;
              this.FormCancel.desc = "";
              this.FormCancel.id = "";
              this.fetchAllData();
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onClickCancel(id) {
      this.Modal.Cancel = true;
      this.FormCancel.id = id;
    },
    onConfirm(id) {
      swal
        .fire({
          title: "แจ้งเตือน...",
          text: "ยืนยันอนุมัติถอน Auto ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.disAuto_w = true;
            HTTP.post(
              "/admin/Notifaceshare?confirm",
              {
                id: id,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("adb_token"),
                },
              }
            )
              .then((resp) => {
                this.disAuto_w = false;
                if (resp.data.status != undefined && !resp.data.status) {
                  this.runToast(
                    "top-right",
                    "danger",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                } else {
                  this.runToast(
                    "top-right",
                    "success",
                    resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
                  );
                  this.fetchAllData();
                }
              })
              .catch((err) => {
                this.disAuto_w = false;
                if (err.response.status === 401) {
                  this.$router.push({
                    path: "/login",
                  });
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  if (err.response.data != "") {
                    this.runToast("top-right", "danger", err.response.data.msg);
                  } else {
                    this.runToast(
                      "top-right",
                      "danger",
                      "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                    );
                  }
                }
              });
          }
        });
    },
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/Notifaceshare", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = resp.data.data;
            this.WithdrawCount = this.tableData.length;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
