<template>
  <div>
    <modal v-model:show="showModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">เพิ่ม เซียน</h3>
          </div>
        </template>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="pic_id" class="form-control-label">รูปภาพ</label>
              <input
                class="form-control"
                @change="ImgfilesChange"
                ref="fileupload"
                type="file"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="bank_id" class="form-control-label">ช่องทาง</label>
              <select
                class="form-control input-group-alternative"
                id="type_id"
                name="type"
                v-model="Form.type"
              >
                <option selected value="">-- เลือก --</option>
                <option value="LINE">Line</option>
                <option value="YOUTUBE">Youtube</option>
                <option value="TWITTER">Twitter</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="url_id" class="form-control-label">URL</label>
              <input
                id="url_id"
                name="url"
                type="text"
                class="form-control input-group-alternative"
                v-model="Form.url"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="title_id" class="form-control-label">Title</label>
              <input
                id="title_id"
                name="title"
                type="text"
                class="form-control input-group-alternative"
                v-model="Form.title"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="detail_id" class="form-control-label">Detail</label>
              <input
                id="detail_id"
                name="detail"
                type="text"
                class="form-control input-group-alternative"
                v-model="Form.detail"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="win_id" class="form-control-label">Win</label>
              <input
                id="win_id"
                name="win"
                type="text"
                class="form-control input-group-alternative"
                v-model="Form.win"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="lose_id" class="form-control-label">Lose</label>
              <input
                id="lose_id"
                name="lose"
                type="text"
                class="form-control input-group-alternative"
                v-model="Form.lose"
              />
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-primary" @click="onSubmit">
            ยืนยัน
          </button>
          <base-button type="danger" @click="onClickCloseButton()"
            >ปิด</base-button
          >
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import modal from "../../Modal.vue";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";

export default {
  components: {
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      QueryId: "",
      Form: {
        img: "",
        type: "",
        url: "",
        title: "",
        detail: "",
        win: "",
        lose: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (
        this.Form.type != "" &&
        this.Form.url != "" &&
        this.Form.title != "" &&
        this.Form.detail != "" &&
        this.Form.win != "" &&
        this.Form.lose != ""
      ) {
        let formData = new FormData();
        formData.append("type", this.Form.type);
        formData.append("url", this.Form.url);
        formData.append("picture", this.Form.img);
        formData.append("title", this.Form.title);
        formData.append("detail", this.Form.detail);
        formData.append("win", this.Form.win);
        formData.append("lose", this.Form.lose);

        HTTP.post(
          "/admin/guru?edit&id=" + this.QueryId,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchAllData(val) {
      HTTP.get("/admin/guru?id=" + this.QueryId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            if (val) {
              this.Form.type = resp.data.data.type;
              this.Form.url = resp.data.data.url;
              this.Form.title = resp.data.data.title;
              this.Form.detail = resp.data.data.detail;
              this.Form.win = resp.data.data.win;
              this.Form.lose = resp.data.data.lose;
              this.showModal = val;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetFormData() {
      this.Form.img = "";
      this.Form.type = "";
      this.Form.url = "";
      this.Form.title = "";
      this.Form.detail = "";
      this.Form.win = "";
      this.Form.lose = "";
    },
    ImgfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.img = event.target.files[0];
        console.log(this.Form.img);
      }
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalEditZean", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      this.$refs.fileupload.value = null;
      this.resetFormData();
      if (val) {
        this.QueryId = this.id;
        this.fetchAllData(val);
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
