<template>
  <div>
    <modal v-model:show="showModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไข Code</h3>
          </div>
        </template>
        <div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="promotion_id" class="form-control-label"
                  >โปรโมชั่น</label
                >
                <select
                  class="form-control input-group-alternative"
                  id="promotion_id"
                  name="promotion"
                  v-model="Form.promotion"
                >
                  <option selected value="">-- เลือก --</option>
                  <option
                    :value="pro.name"
                    v-for="(pro, index) in Promotion"
                    :key="index"
                  >
                    {{ pro.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="code_id" class="form-control-label">Code</label>
                <input
                  id="code_id"
                  name="code"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="quantity_id" class="form-control-label"
                  >จำนวน</label
                >
                <input
                  id="quantity_id"
                  name="quantity"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.quantity"
                />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" @click="onSubmit()">ยืนยัน</button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    flatPicker,
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      QueryId: "",
      Form: {
        code: "",
        quantity: "",
        promotion: "",
      },
      Promotion: [],
    };
  },
  methods: {
    onSubmit() {
      if (
        this.Form.code != "" &&
        this.Form.quantity != "" &&
        this.Form.promotion != ""
      ) {
        HTTP.put(
          "/admin/code?id=" + this.QueryId,
          {
            code: this.Form.code,
            promotion: this.Form.promotion,
            quantity: this.Form.quantity,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchCode() {
      HTTP.get("/admin/code?id=" + this.QueryId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.code = resp.data.data.code;
            this.Form.quantity = resp.data.data.quantity;
            this.Form.promotion = resp.data.data.promotion;
            this.showModal = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchPromotion() {
      HTTP.get("/admin/code", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Promotion = resp.data.promotion;
            this.fetchCode();
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalEditCode", false);
    },
    resetForm() {
      this.Form.quantity = "";
      this.Form.promotion = "";
      this.Form.code = "";
      this.QueryId = "";
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      this.resetForm();
      if (val) {
        this.fetchPromotion();
        this.QueryId = this.id;
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
