<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตรวจสอบ
                  </h2>
                </div>
              </div>
            </template>
            <!-- ======================================================================================================== -->
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery2"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>
              <table style="font-weight: bold">
                <tr>
                  <td>User Betflix</td>
                  <td>:</td>
                  <td style="border-right: 0px solid">
                    {{ datauser.user_betflix }}
                  </td>
                  <td>โปรโมชั่น</td>
                  <td>:</td>
                  <td v-if="tableData == '-'">ไม่มี</td>
                  <td v-else>{{ tableData }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>:</td>
                  <td style="border-right: 0px solid">{{ datauser.phone }}</td>
                  <td>สเตตัส</td>
                  <td>:</td>
                  <td v-if="statusData == '0'">ไม่ติดโปร</td>
                  <td v-else-if="statusData == '1'">รับโปรสำเร็จ</td>
                  <td v-else-if="statusData == '2'">รอดำเนินการ</td>
                </tr>
              </table>
              <div></div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              ></div>
            </div>

            <div>
              <card
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการเล่น Betflix {{ betflixCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData2"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="80px"
                        align="center"
                        label="ลำดับ"
                      >
                        <template v-slot:default="props">
                          {{ props.row[5] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เมื่อ"
                      >
                        <template v-slot:default="props">
                          {{ props.row[0] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ก่อน"
                      >
                        <template v-slot:default="props">
                          {{ props.row[1] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="100px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          <div
                            style="background-color: red; color: white"
                            v-if="checkAmountWallet(props.row[2])"
                          >
                            {{ props.row[2] }}
                          </div>
                          <div v-else>
                            {{ props.row[2] }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="หลัง"
                      >
                        <template v-slot:default="props">
                          {{ props.row[3] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="โน๊ต"
                      >
                        <template v-slot:default="props">
                          <div
                            style="background-color: cyan"
                            v-if="checkNote(props.row[4])"
                          >
                            {{ props.row[4] }}
                          </div>
                          <div v-else>
                            {{ props.row[4] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row">
                    <div
                      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <div class="">
                        <p class="card-category">
                          แสดง {{ from2 + 1 }} ถึง {{ to2 }} จากทั้งหมด
                          {{ total2 }} รายการ
                        </p>
                      </div>
                      <div>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="getMoreData"
                        >
                          ดึงรายการเพิ่ม
                        </button>
                        <button
                          type="button"
                          class="btn btn-warning"
                          @click="checkSpin"
                        >
                          เช็คฟรีสปิน
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                      <base-pagination
                        class="pagination-no-border"
                        v-model="pagination2.currentPage"
                        :per-page="pagination2.perPage"
                        :total="total2"
                      >
                      </base-pagination>
                    </div>
                  </div> -->
                </template>
              </card>
            </div>

            <div>
              <card
                header-classes="bg-danger"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">เช็คฟรีสปิน</h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData3"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="80px"
                        align="center"
                        label="ลำดับ"
                      >
                        <template v-slot:default="props">
                          {{ props.row[5] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เมื่อ"
                      >
                        <template v-slot:default="props">
                          {{ props.row[0] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ก่อน"
                      >
                        <template v-slot:default="props">
                          {{ props.row[1] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="100px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          <div
                            style="background-color: red; color: white"
                            v-if="checkAmountWallet(props.row[2])"
                          >
                            {{ props.row[2] }}
                          </div>
                          <div v-else>
                            {{ props.row[2] }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="หลัง"
                      >
                        <template v-slot:default="props">
                          {{ props.row[3] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="โน๊ต"
                      >
                        <template v-slot:default="props">
                          <div
                            style="background-color: cyan"
                            v-if="checkNote(props.row[4])"
                          >
                            {{ props.row[4] }}
                          </div>
                          <div v-else>
                            {{ props.row[4] }}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row">
                    <div
                      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <div class="">
                        <p class="card-category">
                          แสดง {{ from3 + 1 }} ถึง {{ to3 }} จากทั้งหมด
                          {{ total3 }} รายการ
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                      <base-pagination
                        class="pagination-no-border"
                        v-model="pagination3.currentPage"
                        :per-page="pagination3.perPage"
                        :total="total3"
                      >
                      </base-pagination>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    // flatPicker,
  },
  data() {
    return {
      counter: 0,
      datauser: [],
      last_log: "",
      loading: false,
      user: "",
      DateStart: null,
      websitename: "",
      DateEnd: null,
      betflixCount: 0,
      creditCount: 0,
      pagination: {
        perPage: 30000,
        currentPage: 1,
        perPageOptions: [500, 1000],
        total: 0,
      },
      pagination2: {
        perPage: 30000,
        currentPage: 1,
        perPageOptions: [500, 1000],
        total: 0,
      },
      pagination3: {
        perPage: 30000,
        currentPage: 1,
        perPageOptions: [500, 1000],
        total: 0,
      },
      searchQuery: "",
      searchQuery2: "",
      searchQuery3: "",
      propsToSearch: [
        "date",
        "time",
        "w_before",
        "w_move",
        "w_cut",
        "w_after",
        "promotion",
        "ref",
      ],
      propsToSearch2: ["0", "1", "2", "3", "4"],
      propsToSearch3: ["0", "1", "2", "3", "4"],
      tableData: [],
      statusData: [],
      betflixData: [],
      checkSpinData: [],
      fuseSearch: null,
      searchedData: [],
      searchedData2: [],
      searchedData3: [],
      queryId: "",
      checkStatus: "",
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      let id = this.$route.query.id != undefined ? this.$route.query.id : "";
      this.queryId = id;
      this.fetchAllData(id);
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if(resp.data.data != null && resp.data.data.website_name != null)
          {
            this.websitename = resp.data.data.website_name;
          }          
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
    checkSpin() {
      var pg_test1 = [];
      var pp_test1 = [];
      var result_check = [];
      var b = [];
      var c = [];
      var pg_b1 = [];
      var pg_c1 = [];
      var pp_b1 = [];
      var pp_c1 = [];
      var last_bill = [];

      this.betflixData.forEach(function (item, index) {
        let check_pg = item[4].substring(0, 2);
        // เพิ่มข้อมูล Arr ใหม่ เฉพาะ PG
        if (check_pg == "PG") {
          pg_test1.push([item[0], item[1], item[2], item[3], item[4], item[5]]);
        } else if (check_pg == "PP") {
          // เพิ่มข้อมูล Arr ใหม่ เฉพาะ PP
          pp_test1.push([item[0], item[1], item[2], item[3], item[4], item[5]]);
        } else {
          // เพิ่มข้อมูล Arr ใหม่ไม่เอา PG
          last_bill.push([
            item[0],
            item[1],
            item[2],
            item[3],
            item[4],
            item[5],
          ]);
        }
      });

      // เช็คซ้ำ
      last_bill.forEach(function (item, index) {
        if (b.indexOf(item[4]) < 0) {
          b.push(item[4]);
        } else {
          c.push(item[4]);
        }
      });
      // เช็คซ้ำ PG
      pg_test1.forEach(function (item, index) {
        if (pg_b1.indexOf(item[4]) < 0) {
          pg_b1.push(item[4]);
        } else {
          pg_c1.push([item[4], item[5]]);
        }
      });
      // เช็คซ้ำ PP
      pp_test1.forEach(function (item, index) {
        // if (pp_b1.indexOf(item[4]) < 0) {
        //   pp_b1.push(item[4]);
        // } else {
        pp_c1.push([item[4], item[5]]);
        // }
      });

      let result_pg = [];
      let pg_checkindex = [];
      let result_pp = [];
      let pp_checkindex = [];

      // =========== ตัดอันที่ซ้ำให้เหลืออันเดียว PG ===========
      const uniqueIds = [];

      const uniquePg = pg_c1.filter((element) => {
        const isDuplicate = uniqueIds.includes(element[0]);

        if (!isDuplicate) {
          uniqueIds.push(element[0]);

          return true;
        }

        return false;
      });
      // ============================================

      // =========== ตัดอันที่ซ้ำให้เหลืออันเดียว PP ===========
      const uniqueIdsPp = [];

      const uniquePp = pp_c1.filter((element) => {
        const isDuplicatePp = uniqueIdsPp.includes(element[0]);

        if (!isDuplicatePp) {
          uniqueIdsPp.push(element[0]);

          return true;
        }

        return false;
      });
      // ============================================

      // วนลูปใช้ func หาค่า index ที่ห่างกัน PG
      for (let i = 0; i < uniquePg.length; i++) {
        checkIndexPg(uniquePg[i][0]);
      }

      // วนลูปใช้ func หาค่า index ที่ห่างกัน PP
      for (let i = 0; i < uniquePp.length; i++) {
        checkIndexPp(uniquePp[i][0]);
      }

      // func หาค่า index ที่ห่างกัน PP
      function checkIndexPp(note) {
        pp_checkindex = [];

        // หาค่า index ที่ของค่าที่ซ้ำกันทั้งหมด
        pp_c1.reduce(function (a, e, i) {
          if (e[0] === note) {
            pp_checkindex.push(i);
          }
        });

        if (pp_checkindex.length >= 2) {
          console.log("====== PP =======");
          console.log(note);
          let previous_index = null;
          // วนลูป index มาลบกันทีละตัวหาค่าที่ลบกันแล้วมากกว่า 1
          for (let i = 0; i < pp_checkindex.length; i++) {
            console.log(pp_checkindex[i]);
            if (previous_index != null) {
              let test_result =
                parseInt(pp_checkindex[i]) - parseInt(previous_index);
              console.log(
                "CHECK " +
                  pp_checkindex[i] +
                  " - " +
                  previous_index +
                  " = " +
                  test_result
              );
              previous_index = pp_checkindex[i];
              if (test_result > 1) {
                result_pp.push(note);
                break;
              }
            } else {
              previous_index = pp_checkindex[i];
            }
          }
        }
      }

      // func หาค่า index ที่ห่างกัน PG
      function checkIndexPg(note) {
        pg_checkindex = [];

        // หาค่า index ที่ของค่าที่ซ้ำกันทั้งหมด
        pg_c1.reduce(function (a, e, i) {
          if (e[0] === note) {
            pg_checkindex.push(i);
          }
        });

        if (pg_checkindex.length >= 2) {
          console.log("====== PG =======");
          console.log(note);
          let previous_index = null;
          // วนลูป index มาลบกันทีละตัวหาค่าที่ลบกันแล้วมากกว่า 1
          for (let i = 0; i < pg_checkindex.length; i++) {
            console.log(pg_checkindex[i]);
            if (previous_index != null) {
              let test_result =
                parseInt(pg_checkindex[i]) - parseInt(previous_index);
              console.log(
                "CHECK " +
                  pg_checkindex[i] +
                  " - " +
                  previous_index +
                  " = " +
                  test_result
              );
              previous_index = pg_checkindex[i];
              if (test_result > 1) {
                result_pg.push(note);
                break;
              }
            } else {
              previous_index = pg_checkindex[i];
            }
          }
        }
      }

      console.log("======= RESULT PG ======");
      console.log(result_pg);
      console.log("======= RESULT PP ======");
      console.log(result_pp);

      let pgArr3 = pg_test1.filter((el) => result_pg.includes(el[4]));
      let ppArr = pp_test1.filter((el) => result_pp.includes(el[4]));
      let myArray = last_bill.filter((el) => !c.includes(el[4]));

      pgArr3.forEach(function (item, index) {
        result_check.push([
          item[0],
          item[1],
          item[2],
          item[3],
          item[4],
          item[5],
        ]);
      });
      ppArr.forEach(function (item, index) {
        result_check.push([
          item[0],
          item[1],
          item[2],
          item[3],
          item[4],
          item[5],
        ]);
      });
      myArray.forEach(function (item, index) {
        let amount = item[2].toString();
        let result = amount.includes("-");
        if (!result) {
          result_check.push([
            item[0],
            item[1],
            item[2],
            item[3],
            item[4],
            item[5],
          ]);
        }
      });

      this.checkSpinData = result_check;

      const found = this.checkSpinData.find(
        (element) => !element[4].includes(this.websitename)
      );

      if (found != undefined) {
        this.checkStatus = false;
      } else {
        this.checkStatus = true;
      }

      console.log(this.checkStatus);
    },
    checkAmountWallet(amount) {
      if (amount != undefined) {
        let value = amount.toString();
        let result = value.includes("-");
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkNote(note) {
      if (note != undefined) {
        let result = note.includes(this.websitename);
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    // onClickQuery() {
    //   if (this.DateStart != null && this.DaWteEnd != null && this.user != "") {
    //     this.fetchByDate();
    //   } else if (
    //     this.user != "" &&
    //     this.DateStart == null &&
    //     this.DateEnd == null
    //   ) {
    //     this.fetchAllData();
    //   }
    // },
    // fetchByDate() {
    //   const startArr = this.DateStart.split(" ");
    //   const endArr = this.DateEnd.split(" ");
    //   HTTP.get(
    //     "/admin/tranfer_log?phone=" +
    //       this.user +
    //       "&s_date=" +
    //       startArr[0] +
    //       "&e_date=" +
    //       endArr[0] +
    //       "&s_time=" +
    //       startArr[1] +
    //       ":00&e_time=" +
    //       endArr[1] +
    //       ":59",
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("adb_token"),
    //       },
    //     }
    //   )
    //     .then((resp) => {
    //       if (resp.data.status != undefined && !resp.data.status) {
    //         this.runToast(
    //           "top-right",
    //           "danger",
    //           resp.data.msg != undefined
    //             ? resp.data.msg
    //             : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
    //         );
    //       } else {
    //         this.tableData = resp.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         if (err.response.status === 401) {
    //           this.$router.push({
    //             path: "/login",
    //           });
    //           this.$router.push("/login");
    //           this.$store.dispatch("logoutAuth");
    //         } else {
    //           if (err.response.data != "") {
    //             this.runToast("top-right", "danger", err.response.data.msg);
    //           } else {
    //             this.runToast(
    //               "top-right",
    //               "danger",
    //               "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
    //             );
    //           }
    //         }
    //       }
    //     });
    // },
    fetchAllData(id) {
      this.loading = true;
      HTTP.get("/admin/withdraw_check?id=" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          this.loading = false;
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            let betflixArr = [];
            let i = 1;
            resp.data.betflix_log.forEach((element) => {
              betflixArr.push([
                element.created_at,
                element.before_wallet,
                element.amount,
                element.after_wallet,
                element.note,
                i++,
              ]);
            });

            this.betflixData = betflixArr;

            this.tableData = resp.data.promotion[0].name;
            this.statusData = resp.data.promotion[0].status;
            // this.betflixData = resp.data.betflix_log;
            this.datauser = resp.data.data;
            this.betflixCount = this.betflixData.length;
            this.creditCount = this.tableData.length;
            let last_element = this.betflixData[this.betflixData.length - 1];
            this.last_log = last_element;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    getMoreData() {
      let datetime = this.last_log[0].split(" ");
      HTTP.get(
        "/admin/withdraw_checkmore?id=" +
          this.queryId +
          "&date=" +
          datetime[0] +
          "&time=" +
          datetime[1],
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            if (resp.data.data != undefined && resp.data.data.length != 0) {
              let betflixArr = [];
              let i = this.betflixData.length + 1;
              resp.data.data.forEach((element) => {
                betflixArr.push([
                  element.created_at,
                  element.before_wallet,
                  element.amount,
                  element.after_wallet,
                  element.note,
                  i++,
                ]);
              });

              this.betflixData.push(...betflixArr);

              this.betflixCount = this.betflixData.length;
              let last_element = this.betflixData[this.betflixData.length - 1];
              this.last_log[0] = last_element[0];
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    // ===========================
    pagedData2() {
      return this.betflixData.slice(this.from2, this.to2);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData2() {
      if (!this.searchQuery2) {
        return this.pagedData2;
      }
      let result = this.betflixData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch2) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery2)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.from2, this.to2);
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.betflixData.length;
    },
    // ===========================
    pagedData3() {
      return this.checkSpinData.slice(this.from3, this.to3);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData3() {
      if (!this.searchQuery3) {
        return this.pagedData3;
      }
      let result = this.checkSpinData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch3) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery3)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.from2, this.to2);
    },
    to3() {
      let highBound = this.from3 + this.pagination3.perPage;
      if (this.total3 < highBound) {
        highBound = this.total3;
      }
      return highBound;
    },
    from3() {
      return this.pagination3.perPage * (this.pagination3.currentPage - 1);
    },
    total3() {
      return this.searchedData3.length > 0
        ? this.searchedData3.length
        : this.checkSpinData.length;
    },
  },
};
</script>

<style></style>
