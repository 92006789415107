<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการเดินบัญชี SCB
                  </h2>
                </div>
              </div>
            </template>
            <div>
              <card
                v-if="this.Deposit != 0"
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีฝาก1 {{ CurcountCount }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchDeposit">
                      ดึงรายการบัญชีฝาก1
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                v-if="this.Deposit3 != 0"
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีฝาก2 {{ CurcountCount3 }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchDeposit3">
                      ดึงรายการบัญชีฝาก2
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData3"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                v-if="this.Deposit5 != 0"
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีฝาก3 {{ CurcountCount5 }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchDeposit5">
                      ดึงรายการบัญชีฝาก3
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData5"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                v-if="this.Withdraw != 0"
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีถอน1 {{ CurcountCount1 }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchWithdraw">
                      ดึงรายการบัญชีถอน1
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData1"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                v-if="this.Withdraw4 != 0"
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีถอน2 {{ CurcountCount1 }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchWithdraw">
                      ดึงรายการบัญชีถอน2
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData1"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                v-if="this.Withdraw6 != 0"
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h3 class="mb-0" style="color: white">
                      บัญชีถอน3 {{ CurcountCount1 }} รายการ
                    </h3>
                    <button class="btn btn-secondary" @click="fetchWithdraw">
                      ดึงรายการบัญชีถอน3
                    </button>
                  </div>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData1"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="txnDateTime"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.txnDateTime).format(
                              "YYYY-MM-DD | hh:mm:ss"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnCode.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnRemark"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnRemark }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.txnAmount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import moment from "moment";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      CurcountCount: "0",
      CurcountCount3: "0",
      CurcountCount5: "0",
      CurcountCount1: "0",
      DepositData: [],
      DepositData3: [],
      DepositData5: [],
      WithdrawData: [],
      fuseSearch: null,
      Deposit: 0,
      Deposit3: 0,
      Deposit5: 0,
      Withdraw: 0,
      Withdraw4: 0,
      Withdraw6: 0,
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchDepositData();
      this.fetchWithdrawData();
    }
  },
  methods: {
    moment: function (row) {
      return moment(row);
    },
    fetchDepositData() {
      HTTP.get("/admin/bank_scb?deposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Deposit = resp.data.data.status;
            this.Deposit3 = resp.data.data3.status;
            this.Deposit5 = resp.data.data5.status;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchWithdrawData() {
      HTTP.get("/admin/bank_scb?withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            // this.Withdraw.status = resp.data.data.status === "1" ? true : false;
            // this.Withdraw.number = resp.data.data.number;
            // this.Withdraw.name = resp.data.data.fullname;
            // this.Withdraw.pin = resp.data.data.pin;
            this.Withdraw = resp.data.data.status;
            this.Withdraw4 = resp.data.data4.status;
            this.Withdraw6 = resp.data.data6.status;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchDeposit() {
      HTTP.get("/bank/scb/deposit?transaction", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.data) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.DepositData = resp.data.data;
            this.CurcountCount = this.DepositData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchDeposit3() {
      HTTP.get("/bank/scb/deposit3?transaction", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.data) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.DepositData3 = resp.data.data;
            this.CurcountCount3 = this.DepositData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchDeposit5() {
      HTTP.get("/bank/scb/deposit5?transaction", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.data) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.DepositData5 = resp.data.data;
            this.CurcountCount5 = this.DepositData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchWithdraw() {
      HTTP.get("/bank/scb/withdraw?transaction", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.data) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.WithdrawData = resp.data.data;
            this.CurcountCount1 = this.WithdrawData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.DepositData.slice(this.from, this.to);
    },
    pagedData3() {
      return this.DepositData3.slice(this.from, this.to);
    },
    pagedData5() {
      return this.DepositData5.slice(this.from, this.to);
    },
    pagedData1() {
      return this.WithdrawData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.DepositData.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    queriedData3() {
      if (!this.searchQuery) {
        return this.pagedData3;
      }
      let result = this.DepositData3.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    queriedData5() {
      if (!this.searchQuery) {
        return this.pagedData5;
      }
      let result = this.DepositData5.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    queriedData1() {
      if (!this.searchQuery) {
        return this.pagedData1;
      }
      let result = this.WithdrawData.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
  },
};
</script>

<style></style>
