<template>
  <div>
    <div>
      <!-- Header -->
      <div
        class="header py-7 py-lg-8 pt-lg-9"
        :style="{ background: this.primaryColor }"
      >
        <div class="container">
          <div class="header-body text-center mb-5">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <img :src="webLogo" class="logo-size" />
                <h1 class="text-white">Welcome!</h1>
                <p class="text-lead text-white">{{ website_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              class="fill-secondary"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <!-- Page content -->
      <div class="container mt--8 pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary border-0 mb-0">
              <div class="card-header bg-transparent pb-2">
                <div class="text-muted text-center mt-2 mb-3">
                  <h4>เข้าสู่ระบบ</h4>
                </div>
              </div>
              <div class="card-body px-lg-5 py-lg-5">
                <Form @submit="onSubmitLogin" :validation-schema="schema">
                  <base-input
                    alternative
                    name="username"
                    addon-left-icon="ni ni-email-83"
                    placeholder="Username"
                  >
                  </base-input>

                  <base-input
                    alternative
                    name="password"
                    addon-left-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >เข้าสู่ระบบ</base-button
                    >
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <modal v-model:show="showModal" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">2Factor Authentication</h3>
            </div>
          </template>
          <div class="text-center mb-3" v-if="qrcode2fac != ''">
            <img :src="qrcode2fac" />
          </div>
          <Form @submit="onSubmitOtp" :validation-schema="schemaOTP">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <base-input
                    alternative
                    name="otp"
                    addon-left-icon="ni ni-lock-circle-open"
                    type="text"
                    placeholder="OTP"
                  >
                  </base-input>
                </div>
              </div>
            </div>
            <div class="text-right">
              <button type="submit" class="btn btn-primary">ยืนยัน</button>
              <base-button type="danger" @click="showModal = false"
                >ปิด</base-button
              >
            </div>
          </Form>
        </card>
      </modal>
      <modal v-model:show="showOwnerModal" size="sm">
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">OWNER!!! Authentication</h3>
          </div>
        </template>
        <Form @submit="onSubmitOwnerOtp">
          <base-input
            alternative
            name="ownerOtp"
            addon-left-icon="ni ni-lock-circle-open"
            type="text"
            placeholder="Owner OTP"
          >
          </base-input>
          <base-button type="primary" native-type="submit">ยืนยัน</base-button>
          <base-button type="danger" @click="showOwnerModal = false"
            >ปิด</base-button
          >
        </Form>
      </modal>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/services/http-common";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../components/Notification.vue";
import modal from "../../components/Modal.vue";

export default {
  components: {
    Form,
    modal,
  },
  data() {
    return {
      primaryColor: "#1d009c",
      webLogo: "",
      website_name: "",
      showModal: false,
      showOwnerModal: false,
      ipChanged: false,
      username: "",
      qrcode2fac: "",
      schemaOTP: Yup.object({
        otp: Yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
      }),
      schema: Yup.object({
        username: Yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
        password: Yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
      }),
    };
  },
  created() {
    if (this.Auth) {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    onSubmitLogin(values) {
      HTTP.post("/admin/login", {
        username: values.username,
        password: values.password,
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่"
            );
          } else {
            if (resp.data.qrcode != undefined) {
              this.qrcode2fac = resp.data.qrcode;
            } else {
              this.qrcode2fac = "";
            }
            this.username = values.username;
            this.showModal = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.runToast(
              "top-right",
              "danger",
              err.response.data.msg != undefined
                ? err.response.data.msg
                : "ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่"
            );
          }
        });
    },
    onSubmitOtp(values) {
      HTTP.post("/admin/authentication?adminlogin", {
        username: this.username,
        otp: values.otp,
      })
        .then((resp) => {
          if (
            resp.data.status != undefined &&
            !resp.data.status &&
            resp.status === 201 &&
            resp.data.ipChanged
          ) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ผิดพลาด กรุณาลองใหม่"
            );
            this.showOwnerModal = true;
            this.showModal = false;
          } else {
            this.$store.dispatch("loginAuth", resp.data.token);
            this.$router.push("/dashboard");
          }
        })
        .catch((err) => {
          if (err.response) {
            this.runToast(
              "top-right",
              "danger",
              err.response.data.msg != undefined
                ? err.response.data.msg
                : "ผิดพลาด กรุณาลองใหม่"
            );
          }
        });
    },
    onSubmitOwnerOtp(values) {
      HTTP.post("/admin/authentication?ownerlogin", {
        username: this.username,
        otp: values.ownerOtp,
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ผิดพลาด กรุณาลองใหม่"
            );
          } else {
            this.$store.dispatch("loginAuth", resp.data.token);
            this.$router.push("/dashboard");
          }
        })
        .catch((err) => {
          if (err.response) {
            this.runToast(
              "top-right",
              "danger",
              err.response.data.msg != undefined
                ? err.response.data.msg
                : "ผิดพลาด กรุณาลองใหม่"
            );
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if (resp.data.data != null && resp.data.data.logo != null) {
            this.webLogo = resp.data.data.logo;
            this.website_name = resp.data.data.website_name;
            this.primaryColor = resp.data.data.color1;
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style>
.ct-notification .Vue-Toastification__toast--default {
  padding: 0;
  overflow: inherit;
  box-shadow: none;
  background-color: transparent;
}
.logo-size {
  width: 30%;
  height: auto;
}
</style>
