<template>
  <div class="content">
    <base-header>
      <div class="row"></div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานอันดับสมาชิก
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  col-md-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div class="row">
              <card
                header-classes="bg-success"
                class="no-border-card col-6"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-01" style="color: white">อันดับยอดฝาก</h3>
                </template>
                <div>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                      Boolean="true"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="อันดับ"
                      >
                        <template v-slot:default="props">
                          {{ props.row.rank }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เบอร์"
                      >
                        <template v-slot:default="props">
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="ยอดฝาก"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.amount) }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </card>
              <card
                header-classes="bg-danger"
                class="no-border-card col-6"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-01" style="color: white">อันดับยอดถอน</h3>
                </template>
                <div>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData1"
                      header-row-class-name="thead-light"
                      Boolean="true"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="อันดับ"
                      >
                        <template v-slot:default="props">
                          {{ props.row.rank }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เบอร์"
                      >
                        <template v-slot:default="props">
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="ยอดถอน"
                      >
                        <template v-slot:default="props">
                          {{ toFloat(props.row.amount) }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      sortKey: "",
      reverse: false,
      loading: false,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 1000, 2000, 5000],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["phone"],
      deposit: [],
      withdraw: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    toFloat: function (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/ranking", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status === true) {
            this.loading = false;
            this.deposit = resp.data.deposit;
            this.withdraw = resp.data.withdraw;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),

    pagedData() {
      return this.deposit.slice(this.from, this.to);
    },
    pagedData1() {
      return this.withdraw.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.deposit.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    queriedData1() {
      if (!this.searchQuery) {
        return this.pagedData1;
      }
      let result = this.withdraw.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.deposit.length;
    },
  },
};
</script>

<style></style>
