<template>
  <div>
    <modal v-model:show="showModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">เลือกธนาคาร</h3>
          </div>
        </template>
        <div>
          <!-- <div class="row mb-4">
            <div class="col-md-12">
              <label for="datetime" class="form-control-label"
                >วันที่ / เวลา</label
              >
              <flat-picker
                :config="{ allowInput: true, enableTime: true }"
                class="form-control datepicker input-group-alternative"
                id="datetime"
                v-model="Form.datetime"
              >
              </flat-picker>
              <input v-model="Form.datetime" v-show="false" name="datetime" />
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="id" class="form-control-label">ID</label>
                <input
                  id="id"
                  name="id"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.id"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <select
                  class="form-control input-group-alternative"
                  id="bank"
                  name="bank"
                  v-model="Form.bank"
                >
                  <option selected value="">-- เลือก --</option>
                  <option value="SCB">SCB</option>
                  <option value="KBANK">KBANK</option>
                  <option value="TMN">TrueMoneywallet</option>
                </select>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              class="btn btn-primary"
              @click="onWithdraw()"
              id="submitAdd"
            >
              ยืนยัน
            </button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  components: {
    flatPicker,
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      typeCredit: "wallet",
      Form: {
        id: "",
        bank: "",
      },
    };
  },
  methods: {
    onWithdraw(id) {
      swal
        .fire({
          title: "แจ้งเตือน...",
          text: "ยืนยันอนุมัติถอน",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
            HTTP.post(
              "/admin/withdraw?manual",
              {
                id: this.Form.id,
                bank: this.Form.bank,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("adb_token"),
                },
              }
            )
              .then((resp) => {
                if (resp.data.status != undefined && !resp.data.status) {
                  this.runToast(
                    "top-right",
                    "danger",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                } else {
                  this.runToast(
                    "top-right",
                    "success",
                    resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
                  );
                  this.fetchAllData();
                  this.onClickCloseButton();
                }
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  this.$router.push({
                    path: "/login",
                  });
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  if (err.response.data != "") {
                    this.runToast("top-right", "danger", err.response.data.msg);
                  } else {
                    this.runToast(
                      "top-right",
                      "danger",
                      "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                    );
                  }
                }
              });
          }
        });
    },
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = resp.data.data;
            this.WithdrawCount = this.tableData.length;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalAddreserv", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetFormDate() {
      this.Form.bank = "";
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.resetFormDate();
      this.Form.datetime = new Date();
    },
    id: function (val) {
      this.Form.id = val;
    },
  },
};
</script>

<style></style>
