<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <base-header class="pb-6">
      <!-- Card stats -->
      <div class="row pt-4">
        <div class="col-xl-3 col-md-6">
          <base-input
            alternative
            name="date1"
            label="ยอดของวันที่"
            addon-right-icon="ni ni-calendar-grid-58"
          >
            <template v-slot="{ focus, blur }">
              <flat-picker
                @on-open="focus"
                @on-close="blur"
                :config="{ disableMobile: true,allowInput: true, enableTime: false }"
                class="form-control datepicker"
                @on-change="fetchAllData"
                v-model="fromDate"
              >
              </flat-picker>
            </template>
          </base-input>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-arrow-down">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝากทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.deposit_total }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝากเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.deposit_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.deposit_daily }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-red" icon="fa fa-arrow-up">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.withdraw_total }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.withdraw_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.withdraw_daily }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-hand-holding-usd">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >กำไร-ขาดทุน ทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.profit_total }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >กำไร-ขาดทุน เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.profit_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >กำไร-ขาดทุน วันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.profit_daily }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green" icon="fa fa-users">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.member_total }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่เดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.member_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >สมาชิกใหม่วันนี้/สมาชิกใหม่ฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.member_daily }}/{{ AllData.member_deposit }}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-red" icon="fa fa-gift">
            <template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >จำนวนโบนัสทั้งหมด</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.bonus_total }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >จำนวนโบนัสเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.bonus_monthly }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >จำนวนโบนัสวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.bonus_daily }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >รายการโบนัส</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.bonus_count }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="manual credit"
            type="gradient-red"
            :sub-title="sum"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดฝากเดือนนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.deposit_manual_monthly }}
              </div>
              <br />
              <span class="text-nowrap font-weight-bold" style="font-size: 20px"
                >ยอดฝากวันนี้</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 23px">
                {{ AllData.deposit_manual_daily }}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <card header-classes="bg-default" style="border-radius: 15px">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col text-center">
                  <h5 class="h3 mb-0 text-white">รายการเงินย้อนหลัง</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="Chart.ChartID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";
import "sweetalert2/dist/sweetalert2.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";

// Charts
import Chart from "chart.js";
import moment from "moment";

export default {
  data() {
    return {
      fromDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      AllData: [],
      Chart: {
        ChartID: "salesChart",
        Data: {
          allData: [[], [], [], []],
        },
      },
    };
  },
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fromDate = moment(new Date()).format("YYYY-MM-DD HH:mm"),
      this.fetchAllData();
      this.fetchChart();
    }
  },
  methods: {
    fetchChart() {
      HTTP.get("/admin/chart")
        .then((resp) => {
          this.fetchChartData(
            resp.data.depositSevenDay,
            resp.data.withdrawSevenDay
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAllData() {
      HTTP.get("/admin/dashboard", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
        params: {
          date: this.fromDate.split(" ", 1)[0],
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.AllData = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    fetchChartData(Deposit, Withdraw) {
      this.Chart.Data.allData[0] = Withdraw.map((e) =>
        moment(e.temporary_date).format("DD/MM/YYYY")
      );
      this.Chart.Data.allData[1] = Withdraw.map((e) => +e.withdraw);
      this.Chart.Data.allData[2] = Deposit.map((e) => +e.deposit);

      let profits = [];
      for (let index = 0; index < Deposit.length; ++index) {
        let profit_result = Deposit[index].deposit - Withdraw[index].withdraw;
        profits.push(profit_result);
      }
      this.Chart.Data.allData[3] = profits;

      this.createChart();
    },
    createChart() {
      const ctx = document.getElementById(this.Chart.ChartID).getContext("2d");

      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.Chart.Data.allData[0],
          datasets: [
            {
              label: "ยอดถอน",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "red",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[1],
              barPercentage: 1.6,
            },
            {
              label: "ยอดฝาก",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "blue",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[2],
              barPercentage: 1.6,
            },
            {
              label: "กำไร",
              tension: 0.4,
              borderWidth: 4,
              borderColor: "green",
              pointRadius: 0,
              backgroundColor: "transparent",
              data: this.Chart.Data.allData[3],
              barPercentage: 1.6,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  borderDash: [2],
                  borderDashOffset: [2],
                  color: "#C7CACD",
                  zeroLineColor: "#C7CACD",
                },
                ticks: {
                  beginAtZero: true,
                  padding: 20,
                  fontColor: "black",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  color: "transparent",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "black",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style></style>
