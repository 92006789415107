<template>
  <div>
    <modal v-model:show="showModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">เพิ่ม Role</h3>
          </div>
        </template>
        <div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name_id" class="form-control-label">ชื่อ</label>
                <input
                  id="name_id"
                  name="name"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.name"
                />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" @click="onSubmit()">ยืนยัน</button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    modal,
  },
  props: { show: Boolean },
  data() {
    return {
      showModal: false,
      Form: {
        name: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.Form.name != "") {
        HTTP.post(
          "/admin/admin?createRole",
          {
            name: this.Form.name,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalAddRole", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      this.Form.name = "";
      this.showModal = val;
    },
  },
};
</script>

<style></style>
