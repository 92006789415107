<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    แก้ไขรายการ
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  alternative
                  name="date1"
                  label="วันที่"
                  addon-right-icon="ni ni-calendar-grid-58"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="QuerySelect.DateStart"
                    >
                    </flat-picker>
                  </template>
                </base-input>

                <!-- {{ QuerySelect.DateStart }} -->
              </div>

              <div class="col-md-4">
                <base-input
                  alternative
                  name="date2"
                  label="ถึง"
                  addon-right-icon="ni ni-calendar-grid-58"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="QuerySelect.DateEnd"
                    >
                    </flat-picker>
                  </template>
                </base-input>

                <!-- {{ QuerySelect.DateEnd }} -->
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
                <!-- {{ searchQuery }} -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div class="d-flex">
                  <base-radio
                    name="all"
                    class="mb-3 font-weight-bold mr-3"
                    v-model="QuerySelect.checkAll"
                    @change="fetchAllData()"
                  >
                    ทั้งหมด
                  </base-radio>
                </div>
              </div>
              <div class="col-md-7">
                <div class="d-flex float-right">
                  <base-radio
                    name="all"
                    class="mb-3 font-weight-bold mr-3"
                    @change="statusChange()"
                    v-model="QuerySelect.SelectStatus"
                  >
                    ทั้งหมด
                  </base-radio>
                  <base-radio
                    name="เติมเครดิต"
                    class="mb-3 font-weight-bold mr-3"
                    @change="statusChange()"
                    v-model="QuerySelect.SelectStatus"
                  >
                    เติมเครดิต
                  </base-radio>
                  <base-radio
                    name="ตัดเครดิต"
                    class="mb-3 font-weight-bold mr-3"
                    @change="statusChange()"
                    v-model="QuerySelect.SelectStatus"
                  >
                    ตัดเครดิต
                  </base-radio>
                  <base-radio
                    name="ธนาคารไม่รัน"
                    class="mb-3 font-weight-bold"
                    @change="statusChange()"
                    v-model="QuerySelect.SelectStatus"
                  >
                    ธนาคารไม่รัน
                  </base-radio>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-info"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการแก้ไขทั้งหมด {{ FixCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="สมาชิก"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-user text-primary mr-2"></i
                          >{{ props.row.user_fname }} {{ props.row.user_lname }}
                          <br />
                          <i class="fa fa-mobile-alt text-dark mr-2"></i>
                          {{ props.row.fix_phone }}
                          <br />
                          <i class="fa fa-gamepad text-info mr-1"></i>
                          {{ props.row.game_id }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ประเภท"
                      >
                        <template v-slot:default="props">
                          <badge type="primary">{{ props.row.fix_type }}</badge>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ checkCredit(props.row.fix_credit) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="รายละเอียด"
                      >
                        <template v-slot:default="props">
                          {{ props.row.fix_datetime }}
                          <br />
                          <badge type="danger"
                            ><i class="fa fa-user-shield"></i>
                            {{ props.row.fix_admin }}</badge
                          >
                          <br />
                          <badge type="secondary"
                            >หมายเหตุ : {{ props.row.fix_description }}</badge
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="170px"
                        align="center"
                        label="วันที่ / เวลา โอนเงิน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.fix_datetime_transfer }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="แก้ไขข้อมูล"
                      >
                        <template v-slot:default="props">
                          <base-button
                            size="sm"
                            type="danger"
                            @click="
                              (modals.ReEdit = true),
                                (modals.ReEditId = props.row.id)
                            "
                            ><i class="fa fa-hand-point-right"></i
                            >ทำรายการนี้อีกครั้ง</base-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <ReEditModal :show="modals.ReEdit" :id="modals.ReEditId" @CloseModalReEdit="CloseModalReEdit" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { HTTP } from "@/services/http-common";
import ReEditModal from "../../../components/Autoinfi/Edit/ReEditModal.vue";

var moment = require("moment");

export default {
  components: {
    ReEditModal,
    flatPicker,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      modals: {
        ReEdit: false,
        ReEditId: "",
      },
      loading: false,
      FixCount: "",
      QuerySelect: {
        checkAll: "",
        SelectStatus: "all",
        DateStart: moment(new Date()).format("YYYY-MM-DD"),
        DateEnd: moment(new Date()).format("YYYY-MM-DD"),
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllDataByDate();
    }
  },
  methods: {
    fetchAllDataByDate() {
      if (
        this.QuerySelect.DateStart != null &&
        this.QuerySelect.DateEnd != null
      ) {
        this.QuerySelect.checkAll = "";
        this.loading = true;
        HTTP.post(
          "/dashboard/fixlist/getbydate/",
          {
            DateStart: this.QuerySelect.DateStart + " 00:00:00",
            DateEnd: this.QuerySelect.DateEnd + " 23:59:59",
            SelectStatus: this.QuerySelect.SelectStatus,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status === true) {
              this.loading = false;
              this.tableData = resp.data.response;
              this.FixCount = this.tableData.length;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchAllData() {
      if (this.QuerySelect.checkAll == "all") {
        this.QuerySelect.DateStart = null;
        this.QuerySelect.DateEnd = null;
        this.loading = true;
        HTTP.post(
          "/dashboard/fixlist/",
          {
            SelectStatus: this.QuerySelect.SelectStatus,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status === true) {
              this.loading = false;
              this.tableData = resp.data.response;
              this.FixCount = this.tableData.length;
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    statusChange() {
      if (
        this.QuerySelect.DateStart != null &&
        this.QuerySelect.DateEnd != null
      ) {
        this.fetchAllDataByDate();
      }
      if (this.QuerySelect.checkAll === "all") {
        this.fetchAllData();
      }
    },
    checkCredit(credit) {
      return (Math.round(credit * 100) / 100).toFixed(2);
    },
    CloseModalReEdit(value) {
      this.modals.ReEdit = value;
    },
  },
  watch: {
    "QuerySelect.DateStart": function () {
      this.fetchAllDataByDate();
    },
    "QuerySelect.DateEnd": function () {
      this.fetchAllDataByDate();
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
