<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานการคืนยอดเสีย
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label class="form-control-label">เบอร์โทรศัพท์</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="user"
                  />
                </div>
              </div>
              <div class="col-md-1 text-center">
                <br />
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="onClickQuery"
                >
                  ค้นหา
                </button>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <table>
                  <tr>
                    <td>
                      <span>ยอดคืนยอดเสีย</span>
                    </td>
                    <td  style="position: relative; text-align: right">{{ lose_log }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>รับยอดเสียล่าสุด</span>
                    </td>
                    <td  style="position: relative; text-align: right">{{ lose_date }}&nbsp;&nbsp;{{ lose_time }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>ยอดถอนหลังจากรับยอดเสีย</span>
                    </td>
                    <td style="position: relative; text-align: right">{{sum_withdraw}}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>ยอดฝากหลังจากรับยอดเสีย</span>
                    </td>
                    <td style="position: relative; text-align: right">{{checkCredit(sum_deposit)}}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>กำไรหลังจากรับยอดเสีย</span>
                    </td>
                    <td style="position: relative; text-align: right">{{checkReward(sum_withdraw,sum_deposit)}}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              ></div>
            </div>

            <div>
              <card
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการฝาก {{ CurcountCount1 }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedDiposit"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ธนาคาร"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountName }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เลขบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="100px"
                        align="center"
                        label="แอดมิน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.admin }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="รายละเอียด"
                      >
                        <template v-slot:default="props">
                          {{ props.row.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จาก"
                      >
                        <template v-slot:default="props">
                          {{ props.row.fromBank }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ช่องทาง"
                      >
                        <template v-slot:default="props">
                          {{ props.row.way }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}<br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                header-classes="bg-warning"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการถอน {{ CurcountCount2 }} รายการ
                  </h3>
                </template>

                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedWithdraw"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ธนาคาร"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountName }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เลขบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="100px"
                        align="center"
                        label="แอดมิน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.admin }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.credit }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวนสุทธิ"
                      >
                        <template v-slot:default="props">
                          {{ props.row.credit_up }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="รายละเอียด"
                      >
                        <template v-slot:default="props">
                          {{ props.row.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จาก"
                      >
                        <template v-slot:default="props">
                          {{ props.row.fromBank }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}<br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>

                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";

var moment = require("moment");

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  data() {
    return {
      sum: [],
      websitename: "",
      lose_date: "",
      lose_time: "",
      member_credit: [],
      sum_deposit: "",
      sum_withdraw: "",
      lose_log: "",
      last_log: "",
      user: "",
      DateStart: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      DateEnd: moment(new Date()).format("YYYY-MM-DD 23:59"),
      CurcountCount1: "0",
      CurcountCount2: "0",
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [500, 1000],
      },
      searchQuery: "",
      propsToSearch: ["phone"],
      tableDeposit: [],
      tableWithdraw: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    checkCredit(credit) {
      return (Math.round(credit * 100) / 100).toFixed(2);
    },
    checkReward(credit1,credit2) {
      return (Math.round((credit1-credit2) * 100) / 100).toFixed(2);
    },
    checkAmountWallet(amount) {
      if (amount != undefined) {
        let value = amount.toString();
        let result = value.includes("-");
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if(resp.data.data != null && resp.data.data.website_name != null)
          {
            this.websitename = resp.data.data.website_name;
          }          
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
    checkNote(note) {
      if (note != undefined) {
        let result = note.includes(this.websitename);
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    onClickQuery() {
      if (this.user != "") {
        this.fetchAllData();
      } else if (this.user != "") {
        this.fetchAllData();
      }
    },
    fetchAllData() {
      HTTP.get("/admin/lose_log?phone=" + this.user, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableDeposit = resp.data.deposit;
            this.tableWithdraw = resp.data.withdraw;
            this.member_credit = resp.data.member;
            this.lose_date = resp.data.lose_date;
            this.lose_time = resp.data.lose_time;
            this.lose_log = resp.data.lose_log;
            this.sum_deposit = resp.data.sum_deposit;
            this.sum_withdraw = resp.data.sum_withdraw;
            this.sum = this.sum_withdraw - this.sum_deposit;
            this.CurcountCount1 = this.tableDeposit.length;
            this.CurcountCount2 = this.tableWithdraw.length;
            let last_element1 = this.tableDeposit[this.tableDeposit.length - 1];
            let last_element2 = this.tableWithdraw[
              this.tableWithdraw.length - 1
            ];
            this.last_log = last_element1.created_at;
            this.last_log = last_element2.created_at;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableDeposit.slice(this.from, this.to);
    },
    pagedData1() {
      return this.tableWithdraw.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedDiposit() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result1 = this.tableDeposit.filter((row) => {
        let isIncluded1 = false;
        for (let key of this.propsToSearch) {
          let rowValue1 = row[key].toString();
          if (rowValue1.includes && rowValue1.includes(this.searchQuery)) {
            isIncluded1 = true;
          }
        }
        return isIncluded1;
      });
    },
    queriedWithdraw() {
      if (!this.searchQuery) {
        return this.pagedData1;
      }
      let result2 = this.tableWithdraw.filter((row) => {
        let isIncluded2 = false;
        for (let key of this.propsToSearch) {
          let rowValue2 = row[key].toString();
          if (rowValue2.includes && rowValue2.includes(this.searchQuery)) {
            isIncluded2 = true;
          }
        }
        return isIncluded2;
      });
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableDeposit.length;
    },
  },
};
</script>

<style></style>
