<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่า Popup
                  </h2>
                </div>
              </div>
            </template>
            <div class="row text-center mt-4">
              <div class="col-md-12">
                <label class="custom-toggle">
                  <input type="checkbox" v-model="Form.stat" />
                  <span
                    class="custom-toggle-slider rounded-circle"
                    data-label-off="ปิด"
                    data-label-on="เปิด"
                  >
                  </span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="typenotice_id" class="form-control-label"
                    >รูปแบบ</label
                  >
                  <select
                    class="form-control input-group-alternative"
                    id="typenotice_id"
                    name="typenotice"
                    v-model="Form.type"
                  >
                    <option selected value="">-- เลือกรูปแบบ --</option>
                    <option value="แนะนำ">แนะนำ</option>
                    <option value="ประกาศ">ประกาศ</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="subject_id" class="form-control-label"
                    >หัวข้อเรื่อง</label
                  >
                  <input
                    id="subject_id"
                    name="subject"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.sub"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="textb_id" class="form-control-label"
                    >เนื้อหา(แนะนำไม่ควรเกิน 450 ตัวอักษร)
                  </label>
                  <div
                    id="editor"
                    ref="editor"
                    class="form-control input-group-alternative"
                  ></div>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="banner1_id" class="form-control-label"
                    >รูปภาพ (แนะนำขนาด 1:1 หรือ 1000*1000px)</label
                  >
                  <div v-if="newImagePreview || Form.imgu" class="mb-3">
                    <img
                      :src="newImagePreview ? newImagePreview : Form.imgu"
                      alt="Current Image"
                      class="img-thumbnail mx-auto d-block"
                      style="max-width: 50%"
                    />
                  </div>
                  <input
                    class="form-control"
                    @change="ImgfilesChange1"
                    ref="fileupload1"
                    type="file"
                  />
                  <small class="form-text text-muted mt-2">
                    แนะนำ: ใช้รูปภาพที่มีขนาดไม่เกิน 300KB
                    เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                    กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                    <a
                      href="https://shortpixel.com/online-image-compression"
                      target="_blank"
                      rel="noopener noreferrer"
                      >แนะนำเว็บย่อขนาดรูป</a
                    >.
                  </small>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary" @click="onSubmit">
                ยืนยัน
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import Quill from "quill";
import { HTTP } from "@/services/http-common";

export default {
  data() {
    return {
      Form: {
        type: "",
        sub: "",
        texT: "",
        imgu: "",
        stat: false,
      },
      newImageFile: null,
      newImagePreview: null,
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  mounted() {
    this.initQuill();
  },
  methods: {
    ImgfilesChange1(event) {
      const file = event.target.files[0];
      if (file) {
        this.newImageFile = file; // Store the actual file for later use

        const reader = new FileReader();
        reader.onload = (e) => {
          this.newImagePreview = e.target.result; // Show the new image preview
        };
        reader.readAsDataURL(file);
      }
    },
    initQuill() {
      this.editor = new Quill(this.$refs.editor, {
        theme: "snow",
        placeholder: "ใส่ข้อความ...",
        modules: {
          toolbar: [
            [{ color: [] }, { background: [] }], // Color and background options
            ["bold", "italic", "underline"], // Basic formatting options
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            ["link"], // Add link
            ["clean"], // Remove formatting button
          ],
        },
      });

      // Initialize editor content with existing Form.texT value
      if (this.Form.texT) {
        this.editor.root.innerHTML = this.Form.texT;
      }

      this.editor.on("text-change", () => {
        this.Form.texT = this.editor.root.innerHTML;
      });
    },
    onSubmit() {
      let formData = new FormData();
      formData.append("type_Notice", this.Form.type);
      formData.append("subJ", this.Form.sub);
      formData.append("texTbroD", this.Form.texT);
      if (this.newImageFile) {
        formData.append("imgurL", this.newImageFile);
      } else {
        formData.append("imgurL", this.Form.imgu);
      }
      formData.append("status", this.Form.stat === true ? "1" : "0");

      HTTP.post("/admin/popup", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
            );
            this.fetchAllData();
            this.newImageFile = null;
            this.newImagePreview = null;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchAllData() {
      HTTP.get("/admin/popup", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.imgu = resp.data.data.imgurL;
            this.Form.type = resp.data.data.type_Notice;
            this.Form.sub = resp.data.data.subJ;
            this.Form.texT = resp.data.data.texTbroD;
            this.editor.root.innerHTML = this.Form.texT;
            this.Form.stat = resp.data.data.status === "1" ? true : false;

            this.$refs.fileupload1.value = null;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style>
@import "~quill/dist/quill.snow.css";
.ql-container {
  min-height: 350px;
  font-size: 16px;
  border-radius: 0.375rem;
  padding: 0.5rem;
}

.ql-editor {
  min-height: 320px;
  padding: 0.375rem 0.75rem;
}

.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0 0;
  padding: 0.5rem;
}
</style>
