<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    Boardcast
                  </h2>
                </div>
              </div>
            </template>
            <div class="row mb-4">
              <div class="col-md-12">
                <base-button type="primary" @click="AddModal.show = true"
                  ><i class="fa fa-bullhorn text-danger"></i> เพิ่ม
                  Boardcast</base-button
                >
                <small class="form-text text-muted mt-2">
                    แนะนำ: ใช้รูปภาพที่มีขนาด (1280*720)px และ ไม่เกิน 300KB
                    เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                    กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                    <a
                      href="https://shortpixel.com/online-image-compression"
                      target="_blank"
                      rel="noopener noreferrer"
                      >แนะนำเว็บย่อขนาดรูป</a
                    >.
                  </small>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-warning"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    Boardcast ทั้งหมด {{ BoardCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column align="center" label="รูป">
                        <template v-slot:default="props">
                          <img
                            :src="props.row.url"
                            style="width: 50%; height: auto"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column align="center" label="แก้ไข / ลบ">
                        <template v-slot:default="props">
                          <div>
                            <base-button
                              round
                              size="lg"
                              type="default"
                              @click="
                                EditModal.show = true;
                                EditModal.id = props.row.id;
                              "
                              ><i class="fa fa-pen-square"></i
                            ></base-button>
                            <base-button
                              round
                              size="lg"
                              type="danger"
                              @click="onClickDelete(props.row.id)"
                              ><i class="fa fa-trash"></i
                            ></base-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <AddBoardcastModal
        :show="AddModal.show"
        @CloseModalAddBoardcast="CloseModalAddBoardcast"
      />
      <EditBoardcastModal
        :show="EditModal.show"
        :id="EditModal.id"
        @CloseModalEditBoardcast="CloseModalEditBoardcast"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import AddBoardcastModal from "../../../components/Autoinfi/Boardcast/AddBoardcastModal.vue";
import EditBoardcastModal from "../../../components/Autoinfi/Boardcast/EditBoardcastModal.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    AddBoardcastModal,
    EditBoardcastModal,
  },
  data() {
    return {
      AddModal: {
        show: false,
      },
      EditModal: {
        show: false,
        id: "",
      },
      BoardCount: "",
      loading: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.broadcast != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    fetchAllData() {
      HTTP.get("/admin/broadcast", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            swal.fire({
              title: "แจ้งเตือน...",
              text:
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถโหลดโปรไฟล์ได้ กรุณาลองใหม่",
              icon: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ตกลง",
            });
          } else {
            this.tableData = resp.data.data;
            this.BoardCount = this.tableData.length;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    onClickDelete(id) {
      const originalTableData = [...this.tableData];
      this.tableData = this.tableData.filter(row => row.id !== id);

      swal
        .fire({
          title: "แจ้งเตือน...",
          text: "ลบ Boardcast ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
            HTTP.delete(
              "/admin/broadcast?id=" + id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("adb_token"),
                },
              }
            )
              .then((resp) => {
                if (resp.data.status != undefined && !resp.data.status) {
                  this.runToast(
                    "top-right",
                    "danger",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                  this.tableData = originalTableData;
                } else {
                  this.runToast(
                    "top-right",
                    "success",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ทำรายการสำเร็จ"
                  );
                }
              })
              .catch((err) => {
                this.tableData = originalTableData;
                if (err.response) {
                  if (err.response.status === 401) {
                    this.$router.push("/login");
                    this.$store.dispatch("logoutAuth");
                  } else {
                    this.runToast(
                      "top-right",
                      "danger",
                      err.response.data.msg != undefined
                        ? err.response.data.msg
                        : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                    );
                  }
                }
              });
          } else {
                this.tableData = originalTableData; // Restore the original data if deletion is canceled
            }
        });
    },
    CloseModalAddBoardcast(value) {
      this.AddModal.show = value;
      if (!this.value) {
        this.fetchAllData();
      }
    },
    CloseModalEditBoardcast(value) {
      this.EditModal.show = value;
      if (!this.value) {
        this.fetchAllData();
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
