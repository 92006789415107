<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าการแจ้งเตือน
                  </h2>
                </div>
              </div>
            </template>
            <div class="row mb-3">
              <div class="col-md-12 text-center font-weight-bold">
                Line Notification Token
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="register_id" class="form-control-label"
                    >สมัครสมาชิก</label
                  >
                  <input
                    id="register_id"
                    name="register"
                    type="text"
                    v-model="Form.Register"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="deposit_id" class="form-control-label">ฝาก</label>
                  <input
                    id="deposit_id"
                    name="deposit"
                    type="text"
                    v-model="Form.Deposit"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="withdraw_id" class="form-control-label"
                    >ถอน</label
                  >
                  <input
                    id="withdraw_id"
                    name="withdraw"
                    type="text"
                    v-model="Form.Withdraw"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="admin_id" class="form-control-label"
                    >แอดมินเข้าสู่ระบบ</label
                  >
                  <input
                    id="admin_id"
                    name="admin"
                    type="text"
                    v-model="Form.Admin"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="credit_id" class="form-control-label"
                    >เพิ่ม - ลดเครดิต</label
                  >
                  <input
                    id="credit_id"
                    name="credit"
                    type="text"
                    v-model="Form.Credit"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="trans_id" class="form-control-label"
                    >โยกเงิน</label
                  >
                  <input
                    id="trans_id"
                    name="trans"
                    type="text"
                    v-model="Form.Trans"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="credit_id" class="form-control-label"
                    >สถานะระบบออโต้</label
                  >
                  <input
                    id="credit_id"
                    name="credit"
                    type="text"
                    v-model="Form.Auto"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="acc_id" class="form-control-label"
                    >แก้ไขรายงานบัญชี</label
                  >
                  <input
                    id="acc_id"
                    name="acc"
                    type="text"
                    v-model="Form.acc"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="bank_id" class="form-control-label"
                    >แก้ไขธนาคาร</label
                  >
                  <input
                    id="bank_id"
                    name="bank"
                    type="text"
                    v-model="Form.bank"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary" @click="onSubmit">
                ยืนยัน
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      Form: {
        Deposit: "",
        Withdraw: "",
        Register: "",
        Admin: "",
        Credit: "",
        Trans: "",
        Auto: "",
        acc: "",
        bank: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.linenotify != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    fetchAllData() {
      HTTP.get("/admin/linenotify", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.Deposit = resp.data.data[1].token;
            this.Form.Withdraw = resp.data.data[2].token;
            this.Form.Register = resp.data.data[0].token;
            this.Form.Admin = resp.data.data[3].token;
            this.Form.Credit = resp.data.data[4].token;
            this.Form.Trans = resp.data.data[5].token;
            this.Form.Auto = resp.data.data[6].token;
            this.Form.acc = resp.data.data[7].token;
            this.Form.bank = resp.data.data[8].token;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onSubmit() {
      HTTP.put(
        "/admin/linenotify",
        {
          deposit: this.Form.Deposit,
          withdraw: this.Form.Withdraw,
          register: this.Form.Register,
          admin: this.Form.Admin,
          credit: this.Form.Credit,
          trans: this.Form.Trans,
          auto: this.Form.Auto,
          acc: this.Form.acc,
          bank: this.Form.bank,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "สำเร็จ !"
            );
            this.fetchAllData();
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
