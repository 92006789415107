<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่า Spin
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6 text-center">
                <label class="custom-toggle">
                  <input type="checkbox" v-model="Form.status" />
                  <span
                    class="custom-toggle-slider rounded-circle"
                    data-label-off="ปิด"
                    data-label-on="เปิด"
                  >
                  </span>
                </label>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="spin_id" class="form-control-label">ยอดฝาก</label>
                  <input
                    v-model="Form.deposit"
                    id="spin_id"
                    name="spin"
                    type="text"
                    class="form-control input-group-alternative"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pic_id" class="form-control-label">BG</label>
                  <input
                    class="form-control"
                    @change="ImgfilesChange"
                    type="file"
                    ref="FileUploadBg"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="pic_id" class="form-control-label">Wheel</label>
                  <input
                    class="form-control"
                    @change="ImgfilesChange2"
                    type="file"
                    ref="FileUploadWheel"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward1"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance1"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward2"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance2"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward3"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance3"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward4"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance4"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward5"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance5"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward6"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance6"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward7"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance7"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">รางวัล</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.reward8"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label">เปอร์เซ็น</label>
                  <input
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.chance8"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary" @click="onSubmit">
                บันทึก
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    HTTP,
  },
  data() {
    return {
      Form: {
        status: false,
        deposit: "",
        img_bg: "",
        img_wheel: "",
        reward1: "",
        chance1: "",
        reward2: "",
        chance2: "",
        reward3: "",
        chance3: "",
        reward4: "",
        chance4: "",
        reward5: "",
        chance5: "",
        reward6: "",
        chance6: "",
        reward7: "",
        chance7: "",
        reward8: "",
        chance8: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.spin != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    onSubmit() {
      let formData = new FormData();
      formData.append("credit_deposit", this.Form.deposit);
      formData.append("status", this.Form.status ? "1" : "0");
      formData.append("reward1", this.Form.reward1);
      formData.append("reward2", this.Form.reward2);
      formData.append("reward3", this.Form.reward3);
      formData.append("reward4", this.Form.reward4);
      formData.append("reward5", this.Form.reward5);
      formData.append("reward6", this.Form.reward6);
      formData.append("reward7", this.Form.reward7);
      formData.append("reward8", this.Form.reward8);
      formData.append("chance1", this.Form.chance1);
      formData.append("chance2", this.Form.chance2);
      formData.append("chance3", this.Form.chance3);
      formData.append("chance4", this.Form.chance4);
      formData.append("chance5", this.Form.chance5);
      formData.append("chance6", this.Form.chance6);
      formData.append("chance7", this.Form.chance7);
      formData.append("chance8", this.Form.chance8);
      formData.append("bg", this.Form.img_bg);
      formData.append("wheel", this.Form.img_wheel);

      HTTP.post(
        "/admin/spin?updateSpin",
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
            );
            this.fetchAllData();
            this.$refs.FileUploadWheel.value = null;
            this.$refs.FileUploadBg.value = null;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchAllData() {
      HTTP.get("/admin/spin", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.status = resp.data.spin.status === "1" ? true : falsel;
            this.Form.deposit = resp.data.spin.credit_deposit;
            this.Form.reward1 = resp.data.spin_chance[0].reward;
            this.Form.chance1 = resp.data.spin_chance[0].chance;
            this.Form.reward2 = resp.data.spin_chance[1].reward;
            this.Form.chance2 = resp.data.spin_chance[1].chance;
            this.Form.reward3 = resp.data.spin_chance[2].reward;
            this.Form.chance3 = resp.data.spin_chance[2].chance;
            this.Form.reward4 = resp.data.spin_chance[3].reward;
            this.Form.chance4 = resp.data.spin_chance[3].chance;
            this.Form.reward5 = resp.data.spin_chance[4].reward;
            this.Form.chance5 = resp.data.spin_chance[4].chance;
            this.Form.reward6 = resp.data.spin_chance[5].reward;
            this.Form.chance6 = resp.data.spin_chance[5].chance;
            this.Form.reward7 = resp.data.spin_chance[6].reward;
            this.Form.chance7 = resp.data.spin_chance[6].chance;
            this.Form.reward8 = resp.data.spin_chance[7].reward;
            this.Form.chance8 = resp.data.spin_chance[7].chance;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    ImgfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.img_bg = event.target.files[0];
        console.log(this.Form.img_bg);
      }
    },
    ImgfilesChange2(event) {
      if (event.target.files[0] != undefined) {
        this.Form.img_wheel = event.target.files[0];
        console.log(this.Form.img_wheel);
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Role"]),
    ...mapGetters(["Auth"]),
  },
};
</script>

<style></style>
