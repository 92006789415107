<template>
  <div>
    <modal v-model:show="showModal" size="lg">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">รายการ</h3>
          </div>
        </template>
        <div>
          <Form @submit="onSubmit" :validation-schema="schema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tpye_select" class="form-control-label"
                    >ประเภทเติมเงิน</label
                  >
                  <Field
                    id="tpye_select"
                    name="type"
                    as="select"
                    class="form-control input-group-alternative"
                    v-model="type"
                  >
                    <option value="" selected>เลือก...</option>
                    <option value="เติมเครดิต">เติมเครดิต</option>
                    <option value="ตัดเครดิต">ตัดเครดิต</option>
                    <option value="ธนาคารไม่รัน">ธนาคารไม่รัน</option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    class="text-danger invalid-feedback"
                    style="display: block"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="row d-flex align-items-center justify-content-center h-100"
                >
                  <i class="fa fa-user text-primary mr-2"></i
                  ><span class="font-weight-bold mr-3"
                    >ชื่อลูกค้า : {{ name }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phone_id" class="form-control-label"
                    >เบอร์โทรศัพท์</label
                  >
                  <Field
                    id="phone_id"
                    name="phone"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="phone"
                  />
                  <ErrorMessage
                    name="phone"
                    class="text-danger invalid-feedback"
                    style="display: block"
                  />
                  <!-- {{ phone }} -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="banknumber_id" class="form-control-label"
                    >เลขบัญชี</label
                  >
                  <Field
                    id="banknumber_id"
                    name="banknumber"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="banknumber"
                  />
                  <ErrorMessage
                    name="banknumber"
                    class="text-danger invalid-feedback"
                    style="display: block"
                  />
                  <!-- {{ banknumber }} -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="datetime" class="form-control-label"
                  >วันที่ / เวลา</label
                >
                <flat-picker
                  :config="{
                    disableMobile: true,
                    allowInput: true,
                    enableTime: false,
                  }"
                  class="form-control datepicker input-group-alternative"
                  id="datetime"
                  v-model="datetime"
                >
                </flat-picker>
                <Field v-model="datetime" v-show="false" name="datetime" />
                <ErrorMessage
                  name="datetime"
                  class="text-danger invalid-feedback"
                  style="display: block"
                />
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="description_id" class="form-control-label"
                    >หมายเหตุ</label
                  >
                  <Field
                    id="description_id"
                    name="description"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="description"
                  />
                  <ErrorMessage
                    name="description"
                    class="text-danger invalid-feedback"
                    style="display: block"
                  />
                  <!-- {{ description }} -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="credit_id" class="form-control-label"
                    >จำนวน</label
                  >
                  <Field
                    id="credit_id"
                    name="credit"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="credit"
                    readonly
                  />
                  <ErrorMessage
                    name="credit"
                    class="text-danger invalid-feedback"
                    style="display: block"
                  />
                  <!-- {{ credit }} -->
                </div>
              </div>
            </div>
            <div class="text-right">
              <button type="submit" class="btn btn-primary">ยืนยัน</button>
              <base-button type="danger" @click="onClickButton()"
                >ปิด</base-button
              >
            </div>
          </Form>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import FileInput from "@/components/Inputs/FileInput";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    FileInput,
    Field,
    Form,
    ErrorMessage,
    flatPicker,
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      QueryId: "",
      type: "",
      phone: "",
      banknumber: "",
      datetime: "",
      description: "",
      credit: "",
      name: "",
      schema: yup.object({
        type: yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
        phone: yup
          .string()
          .matches(/^[0-9]+$/, "ตัวเลขเท่านั้น")
          .required("กรอกข้อมูลให้ครบถ้วน")
          .min(10, "ตัวเลข 10 ตัวอักษรเท่านั้น")
          .max(10, "ตัวเลข 10 ตัวอักษรเท่านั้น"),
        banknumber: yup
          .string()
          .matches(/^[0-9]+$/, "ตัวเลขเท่านั้น")
          .required("กรอกข้อมูลให้ครบถ้วน")
          .min(10, "ตัวเลข 10 ตัวอักษรเท่านั้น")
          .max(10, "ตัวเลข 10 ตัวอักษรเท่านั้น"),
        description: yup.string().required("กรอกข้อมูลให้ครบถ้วน"),
        credit: yup
          .string()
          .matches(/^[0-9]+$/, "ตัวเลขเท่านั้น")
          .required("กรอกข้อมูลให้ครบถ้วน"),
        datetime: yup
          .date()
          .typeError("รูปแบบข้อมูลไม่ถูกต้อง")
          .required("กรอกข้อมูลให้ครบถ้วน"),
      }),
    };
  },
  methods: {
    fetchAllData(modal) {
      HTTP.post(
        "/dashboard/fixlist/getbyid/",
        {
          id: this.QueryId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status === true) {
            this.type = resp.data.response.fix_type;
            this.phone = resp.data.response.fix_phone;
            this.banknumber = resp.data.response.fix_banknumber;
            this.description = resp.data.response.fix_description;
            this.credit = resp.data.response.fix_credit;
            this.name =
              resp.data.response.user_fname +
              " " +
              resp.data.response.user_lname;
            this.showModal = modal;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit(values) {
      console.log(values);
    },
    onClickButton(event) {
      this.$emit("CloseModalReEdit", false);
    },
    resetFormData() {
      this.type = "";
      this.phone = "";
      this.banknumber = "";
      this.description = "";
      this.credit = "";
      this.name = "";
    },
  },
  watch: {
    show: function (val) {
      this.resetFormData();
      if (val === true) {
        this.QueryId = this.id;
        this.datetime = new Date();
        this.fetchAllData(val);
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
