<template>
  <div class="content">
    <base-header>
      <!-- <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="ค่าบริการระบบ"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ระบบ</span
              >
              <div class="font-weight-bold" style="font-size: 23px">
                {{ spin_buy }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ทรู+proxy</span
              >
              <div class="font-weight-bold" style="font-size: 23px">
                {{ spin_reward }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="ค่าเครดิตกระดาน"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{ return_lose }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="ยอดรวมที่ต้องชำระ"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <div class="font-weight-bold" style="font-size: 23px">
                {{ credit_free }}
              </div>
            </template>
          </stats-card>
        </div>
      </div> -->
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ชำระเงิน
                  </h2>
                </div>
              </div>
            </template>
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="form-group text-center">
                  <label for="phone_id" class="form-control-label"
                    >จำนวนเงินที่ต้องชำระ</label
                  >
                  <div class="input-container">
                    <input
                      id="vaulepay_id"
                      name="vaulepay"
                      type="text"
                      class="form-control input-group-alternative text-center"
                      v-model="totalFee"
                      disabled
                    />
                    <i
                      class="fas fa-sync-alt refresh-icon"
                      @click="fetchvaulepay"
                    ></i>
                  </div>
                </div>
                <div class="form-group text-center">
                  <label for="phone_id" class="form-control-label"
                    >ระบุ เลขบัญชี ,ชื่อ-นามสกุล ของบัญชีที่ใช้ในการโอน</label
                  >
                  <br />
                  <input
                    id="accno_id"
                    name="accno"
                    inputmode="numeric"
                    v-model="Form.accno"
                    class="form-control col-md-12 text-center"
                    placeholder="เลขบัญชี"
                  />
                  <input
                    id="name_id"
                    name="name"
                    type="text"
                    v-model="Form.name"
                    class="form-control col-md-6 text-center"
                    :style="{ display: 'inline' }"
                    placeholder="ชื่อ"
                  />
                  <input
                    id="surname_id"
                    name="surname"
                    type="text"
                    v-model="Form.surname"
                    class="form-control col-md-6 text-center"
                    :style="{ display: 'inline' }"
                    placeholder="นามสกุล"
                  />
                </div>
                <div class="form-group text-center">
                  <label for="phone_id" class="form-control-label"
                    >ระบุยอดที่ต้องการชำระ</label
                  >
                  <input
                    id="amount_id"
                    name="amount"
                    v-model="Form.amount"
                    type="text"
                    class="form-control input-group-alternative text-center"
                    placeholder="ชำระยอดขั้นต่ำ 1000 - สูงสุด 50000บาท/ครั้ง"
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center form-group">
              <button
                class="btn btn-primary"
                @click="genarateQR()"
                id="submitAdd"
                :disabled="
                  Form.accno == '' ||
                  Form.name == '' ||
                  Form.surname == '' ||
                  Form.amount < '1000' ||
                  Form.amount > '50000'
                "
              >
                ชำระเงิน
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div
      class="row justify-content-center form-group"
      :style="{
        alignItems: 'center',
        width: '100%',
        height: '1000px',
      }"
      v-if="shouldShowIframe"
    >
      <button class="form-group hidemenu" @click="hide">
        <i class="fas fa-times"></i>
      </button>
      <div class="warning-text">
        <ul :style="{ marginBottom: '0px' }">
          <br />
          <br />
          <li>คำเตือน!!! หากไม่ปฏิบัติตามกฏท่านจะไม่ได้รับเครดิตเงินฝาก</li>
          <li>ห้ามนำ QRCODE นี้กลับมาใช้ซ้ำ</li>
          <li>ห้ามทำรายการหากเกินเวลาที่กำหนด ให้กดรับQRCODEใหม่</li>
          <li>ห้ามกด QRCODE หลายๆรอบโดยไม่ทำการฝาก</li>
        </ul>
      </div>
      <iframe
        :src="iframeSrc"
        frameborder="0"
        style="width: 100%; height: 100%; overflow: hidden"
      ></iframe>
    </div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ประวัติรับชำระเงิน
                  </h2>
                </div>
              </div>
            </template>
            <div>
              <card
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการชำระเงินทั้งหมด {{ CurcountCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เลขที่บิล"
                      >
                        <template v-slot:default="props">
                          {{ props.row.order_number }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="220px"
                        align="center"
                        label="สถานะ"
                      >
                        <template v-slot:default="props">
                          <badge type="success" v-if="props.row.status === '2'"
                            >สำเร็จ</badge
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
// import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      Form: {
        accno: "",
        name: "",
        surname: "",
        amount: "",
      },
      payseramount: {
        sysFee: 0,
        proxFee: 0,
        creditFee: 0,
        etcFee: 0,
        discounT: 0,
        payconfirm: 0,
      },
      iframeSrc: "",
      shouldShowIframe: false,
      DateStart: null,
      DateEnd: null,
      CurcountCount: "0",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["phone", "details", "credit", "time", "date"],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
      this.fetchvaulepay();
    }
  },
  mounted() {},
  methods: {
    hide() {
      this.shouldShowIframe = false;
    },
    fetchvaulepay() {
      HTTP.get("/admin/payservice?vaulepaY", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            // this.payseramount = resp.data.data;
            this.payseramount.sysFee = resp.data.data.sysFee;
            this.payseramount.proxFee = resp.data.data.proxFee;
            this.payseramount.creditFee = resp.data.data.creditFee;
            this.payseramount.etcFee = resp.data.data.etcFee;
            this.payseramount.discounT = resp.data.data.discounT;
            this.payseramount.payconfirm = resp.data.data.payconfirm;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    genarateQR() {
      HTTP.post(
        `/admin/payservice?Qrgen`,
        {
          amount: this.Form.amount,
          name: this.Form.name,
          surname: this.Form.surname,
          accno: this.Form.accno,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((response) => {
          if (response.data.data) {
            // Extract UUID and construct the direct QR code image URL
            // this.qrCodeUrl = `https://api.vpayth.com/storage/prompt_pay/${uuidMatch[1]}.png`;
            // this.qrCodeUrl = response.data.data;
            // window.open(this.qrCodeUrl, "_blank").focus();
            this.iframeSrc = response.data.data;
            this.shouldShowIframe = true;
          } else {
            this.$toast.error(
              response.data.msg != undefined
                ? response.data.msg
                : "ทำรายการไม่สำร็จ โปรดทำรายการอีกครั้ง"
            );
          }
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดข้อมูลธนาคารได้ กรุณาลองใหม่"
          );
        });
    },
    fetchAllData() {
      HTTP.get("/admin/payservice?fetchAllData", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    totalFee: {
    get() {
      const sysFee = Number(this.payseramount.sysFee) || 0;
      const proxFee = Number(this.payseramount.proxFee) || 0;
      const creditFee = Number(this.payseramount.creditFee) || 0;
      const etcFee = Number(this.payseramount.etcFee) || 0;
      const discounT = Number(this.payseramount.discounT) || 0;
      const payconfirm = Number(this.payseramount.payconfirm) || 0;

      const total = sysFee + proxFee + creditFee + etcFee - discounT - payconfirm;

      return total.toFixed(2); // Ensure two digits after the decimal
    },
    set(value) {
      const sysFee = Number(this.payseramount.sysFee) || 0;
      const proxFee = Number(this.payseramount.proxFee) || 0;
      const creditFee = Number(this.payseramount.creditFee) || 0;
      const etcFee = Number(this.payseramount.etcFee) || 0;
      const discounT = Number(this.payseramount.discounT) || 0;
      const payconfirm = Number(this.payseramount.payconfirm) || 0;

      const currentTotal =
        sysFee + proxFee + creditFee + etcFee - discounT - payconfirm;

      const difference = value - currentTotal;

      this.payseramount.sysFee = (sysFee + difference / 4).toFixed(2);
      this.payseramount.proxFee = (proxFee + difference / 4).toFixed(2);
      this.payseramount.creditFee = (creditFee + difference / 4).toFixed(2);
      this.payseramount.etcFee = (etcFee + difference / 4).toFixed(2);
      this.payseramount.discounT = (discounT - difference / 4).toFixed(2);
      this.payseramount.payconfirm = (payconfirm - difference / 4).toFixed(2);
    },
  },
  },
};
</script>

<style>
.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.refresh-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
