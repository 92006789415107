<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่า PAYMENTGATEWAY
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-12">
                <div
                  class="row text-center mt-4 mb-4"
                  v-show="Role.tw_status === '1'"
                >
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <p>สถานะฝาก</p>
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Form.depstatus" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <p>สถานะถอน</p>
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Form.widstatus" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row" v-show="Role.tw_pw_pin === '1'">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="key_id" class="form-control-label"
                        >COMPANYID</label
                      >
                      <input
                        id="key_id"
                        name="key"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.compid"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Logintoken_id" class="form-control-label"
                        >TYPE_GATEWAY</label
                      >
                      <input
                        id="Logintoken_id"
                        name="Logintoken"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.typegate"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="TMN_id" class="form-control-label"
                        >TOKEN</label
                      >
                      <input
                        id="TMN_id"
                        name="TMNid"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Form.authtoken"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      showModal: false,
      Form: {
        depstatus: false,
        widstatus: false,
        compid: "",
        typegate: "",
        authtoken: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    onSubmit() {
      if (
        this.Form.compid != "" &&
        this.Form.typegate != "" &&
        this.Form.authtoken != ""
      ) {
        HTTP.put(
          "/admin/bank_pp",
          {
            compid: this.Form.compid,
            typegate: this.Form.typegate,
            authtoken: this.Form.authtoken,
            widstatus: this.Form.widstatus === true ? "1" : "0",
            depstatus: this.Form.depstatus === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchAllData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchAllData() {
      HTTP.get("/admin/bank_pp", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.depstatus =
              resp.data.data.depstatus === "1" ? true : false;
            this.Form.widstatus =
              resp.data.data.widstatus === "1" ? true : false;
            this.Form.compid = resp.data.data.companyID;
            this.Form.typegate = resp.data.data.type_gateway;
            this.Form.authtoken = resp.data.data.authToken;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
