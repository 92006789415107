<template>
  <div>
    <modal v-model:show="showModal" size="md">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">ข้อมูลที่จำเป็นในการรับDevice ID</h3>
          </div>
        </template>
        <!-- --------------------------------step1-------------------------------- -->
        <div class="re01" v-if="state == 1">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="personID_id" class="form-control-label"
                  >หมายเลขบัตรประชาชน</label
                >
                <input
                  id="person_id"
                  name="personID"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.personID"
                  placeholder="e.g.1200000000"
                  maxlength="13"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="birthday_id" class="form-control-label"
                  >ปีเกิด(ค.ศ.)-เดือน-วัน</label
                >
                <input
                  id="birthday_id"
                  name="birthday"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.birthday"
                  placeholder="e.g.1998-10-24"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="tel_id" class="form-control-label"
                  >เบอร์โทรศัพท์</label
                >
                <input
                  id="tel_id"
                  name="tel"
                  type="tel"
                  class="form-control input-group-alternative"
                  v-model="Form.tel"
                  placeholder="e.g.0987654321"
                  maxlength="10"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="form-group">
                <label for="pin_id" class="form-control-label">PIN</label>
                <input
                  id="pin_id"
                  name="pin"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.pin"
                  placeholder="e.g.123456"
                  maxlength="6"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label
                  for="credit_id"
                  class="form-control-label"
                  style="color: white"
                  >OO</label
                >
                <button class="btn btn-info" @click="gototag()" id="submitReq">
                  กดเพื่อขอotp
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <base-button
                type="danger"
                @click="onClickCloseButton()"
                style="width: 110px"
                >ปิด</base-button
              >
            </div>
          </div>
        </div>
        <!-- --------------------------------step1-------------------------------- -->
        <!-- --------------------------------step2-------------------------------- -->
        <div class="re02" v-if="state == 2">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="ref_id" class="form-control-label">REF.</label>
                  <input
                    id="ref_id"
                    name="ref"
                    type="text"
                    class="form-control input-group-alternative"
                    disabled
                    v-model="FormOtp.ref"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="otp_id" class="form-control-label">OTP</label>
                  <input
                    id="otp_id"
                    name="otp"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="FormOtp.otp"
                  />
                </div>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-primary" @click="onSubmitOtp">
                ยืนยัน
              </button>
              <base-button type="danger" @click="onClickCloseButton()"
                >ปิด</base-button
              >
            </div>
          </div>
        </div>
        <!-- --------------------------------step2-------------------------------- -->
        <!-- --------------------------------step3-------------------------------- -->
        <div class="re02" v-if="state == 3">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input
                  id="pin_id"
                  name="pin"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="this.Formsav.deviceId"
                  placeholder="xxx-xxxx-xxxx-xxx"
                  disabled
                />
              </div>
              <select class="inputstyle" v-model="Formsav.Id">
                <option selected value="">
                  -- เลือกธนาคารที่จะทำการบันทึก --
                </option>
                <option value="1">บัญชีธนาคาร SCB ฝาก</option>
                <option value="2">บัญชีธนาคาร SCB ถอน</option>
              </select>
              <div class="text-right">
                <button class="btn btn-primary" @click="onSaveDevice">
                  ยืนยัน
                </button>
                <base-button type="danger" @click="onClickCloseButton()"
                  >ปิด</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------------step3-------------------------------- -->
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    modal,
  },
  props: { show: Boolean, phone: String },
  data() {
    return {
      showModal: false,
      state: 1,
      typeCredit: "wallet",
      Formsav: {
        deviceId: "",
        Id: "",
      },
      FormOtp: {
        Auth: "",
        otp: "",
        tokenUUID: "",
        ref: "",
      },
      Form: {
        bankID: "",
        pin: "",
        birthday: "",
        tel: "",
        personID: "",
        tag: "",
      },
    };
  },
  methods: {
    gototag() {
      if (
        this.Form.personID != "" &&
        this.Form.tel != "" &&
        this.Form.birthday != ""
      ) {
        // console.log({
        //   cardId: this.Form.personID,
        //   phone: this.Form.tel,
        //   date: this.Form.birthday,
        // });
        HTTP.post(
          "/bank/scb/action?verifyUser",
          {
            cardId: this.Form.personID,
            phone: this.Form.tel,
            date: this.Form.birthday,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data != "") {
              this.FormOtp.ref = resp.data.pac;
              this.FormOtp.Auth = resp.data.Auth;
              this.FormOtp.tokenUUID = resp.data.tokenUUID;
              this.state = 2;
            } else {
              this.runToast(
                "top-right",
                "danger",
                "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onSubmitOtp() {
      if (this.FormOtp.otp != "") {
        HTTP.post(
          "/bank/scb/action?submitOTP",
          {
            Auth: this.FormOtp.Auth,
            tokenUUID: this.FormOtp.tokenUUID,
            OTP: this.FormOtp.otp,
            phone: this.Form.tel,
            pin: this.Form.pin,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status === "success") {
              this.runToast("top-right", "success", "สำเร็จ");
              this.showModalotp = false;
              this.Formsav.deviceId = resp.data.desc;
              this.state = 3;
              // console.log({
              //   deviceId: this.deviceId,
              // });
            } else if (resp.data.status === "error") {
              this.runToast(
                "top-right",
                "danger",
                "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            } else {
              this.runToast(
                "top-right",
                "danger",
                "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onSaveDevice() {
      if (this.deviceId != "") {
        HTTP.put(
          "admin/bank_scb?dSaveDevice",
          {
            deviceId: this.Formsav.deviceId,
            id: this.Formsav.Id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ไม่มี DEVICE ID ที่จะบันทึก");
      }
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalAddreserv", false);
      this.state = 1;
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetFormDate() {
      this.bankID = "";
      this.Form.pin = "";
      this.birthday = "";
      this.Form.tel = "";
      this.Form.personID = "";
      this.Form.tag = "";
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.resetFormDate();
      this.Form.datetime = new Date();
    },
    phone: function (val) {
      this.Form.phone = val;
    },
  },
};
</script>

<style></style>
