<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าบัญชีไทยพาณิชย์
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <!-- <div class="col-md-12 text-center">
                <button
                  type="submit"
                  class="btn btn-info w-15"
                  @click="requestdeviceModal.show = true"
                >
                  Device ID<br />
                  request
                </button>
              </div> -->
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ฝาก</div>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="optionSelect">Select Option</label>
                  <select
                    id="optionSelect"
                    class="form-control"
                    v-model="selectedepOption"
                  >
                    <option value="1">บัญชีฝากที่ 1</option>
                    <option value="3">บัญชีฝากที่ 2</option>
                    <option value="5">บัญชีฝากที่ 3</option>
                    <option value="7">บัญชีฝากที่ 4</option>
                    <option value="9">บัญชีฝากที่ 5</option>
                  </select>
                </div>
                <!-- ------------------ depoption1 ------------------ -->
                <div v-if="selectedepOption === '1'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-6">
                      <p>สถานะระบบ</p>
                      <label class="custom-toggle">
                        <input type="checkbox" v-model="Deposit.data.status" />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <p>แสดงบัญชีหน้าระบบ</p>
                      <label class="custom-toggle">
                        <input
                          type="checkbox"
                          v-model="Deposit.data.showbank"
                        />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPde"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-if="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Depositdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavedepDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onDepositSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------depoption1---------------------- -->
                <!-- ------------------ depoption3 ------------------ -->
                <div v-if="selectedepOption === '3'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-6">
                      <p>สถานะระบบ</p>
                      <label class="custom-toggle">
                        <input type="checkbox" v-model="Deposit.data3.status" />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <p>แสดงบัญชีหน้าระบบ</p>
                      <label class="custom-toggle">
                        <input
                          type="checkbox"
                          v-model="Deposit.data3.showbank"
                        />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPde"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data3.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data3.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-if="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data3.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data3.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Depositdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavedepDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onDepositSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------depoption3---------------------- -->
                <!-- ------------------ depoption5 ------------------ -->
                <div v-if="selectedepOption === '5'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-6">
                      <p>สถานะระบบ</p>
                      <label class="custom-toggle">
                        <input type="checkbox" v-model="Deposit.data5.status" />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <p>แสดงบัญชีหน้าระบบ</p>
                      <label class="custom-toggle">
                        <input
                          type="checkbox"
                          v-model="Deposit.data5.showbank"
                        />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPde"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data5.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data5.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-if="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data5.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Deposit.data5.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Depositdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavedepDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onDepositSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------depoption5---------------------- -->
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ถอน</div>
                  </div>
                </div>
                <div class="form-group col-md-3 ml-auto">
                  <label for="optionSelect">Select Option</label>
                  <select
                    id="optionSelect"
                    class="form-control"
                    v-model="selectewidOption"
                  >
                    <option value="2">บัญชีถอนที่ 1</option>
                    <option value="4">บัญชีถอนที่ 2</option>
                    <option value="6">บัญชีถอนที่ 3</option>
                    <option value="8">บัญชีถอนที่ 4</option>
                    <option value="10">บัญชีถอนที่ 5</option>
                  </select>
                </div>
                <!-- ----------------------widoption2---------------------- -->
                <div v-if="selectewidOption === '2'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-12">
                      <label class="custom-toggle">
                        <input type="checkbox" v-model="Withdraw.data.status" />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPwi"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-show="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Withdrawdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavewidDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onWithdrawSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------widoption2---------------------- -->
                <!-- ----------------------widoption4---------------------- -->
                <div v-if="selectewidOption === '4'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-12">
                      <label class="custom-toggle">
                        <input
                          type="checkbox"
                          v-model="Withdraw.data4.status"
                        />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPwi"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data4.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data4.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-show="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data4.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data4.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Withdrawdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavewidDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onWithdrawSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------widoption4---------------------- -->
                <!-- ----------------------widoption6---------------------- -->
                <div v-if="selectewidOption === '6'">
                  <div
                    class="row text-center mt-4"
                    v-show="Role.scb_status === '1'"
                  >
                    <div class="col-md-12">
                      <label class="custom-toggle">
                        <input
                          type="checkbox"
                          v-model="Withdraw.data6.status"
                        />
                        <span
                          class="custom-toggle-slider rounded-circle"
                          data-label-off="ปิด"
                          data-label-on="เปิด"
                        >
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="onCheckAPwi"
                    >
                      checkAPI
                    </button>
                  </div>
                  <div class="row" v-if="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="banknumber_id" class="form-control-label"
                          >เลขบัญชี</label
                        >
                        <input
                          id="banknumber_id"
                          name="banknumber"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data6.number"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="fname_id" class="form-control-label"
                          >ชื่อ - นามสกุล</label
                        >
                        <input
                          id="fname_id"
                          name="fname"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data6.fullname"
                          :disabled="Role.scb_num_name != '1'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" v-show="Role.scb_pin_device === '1'">
                      <div class="form-group">
                        <label for="pin_id" class="form-control-label"
                          >Pin</label
                        >
                        <input
                          id="pin_id"
                          name="pin"
                          type="password"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data6.pin"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="trans_id" class="form-control-label"
                          >TransPage</label
                        >
                        <input
                          id="trans_id"
                          name="trans"
                          type="text"
                          class="form-control input-group-alternative"
                          v-model="Withdraw.data6.trans_page"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="Role.scb_pin_device === '1'">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Device ID"
                          id="deviceid_id"
                          name="deviceid"
                          v-model="Withdrawdevice"
                          aria-label="Device ID"
                          aria-describedby="button-addon"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            type="button"
                            id="button-addon"
                            @click="onSavewidDevice"
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onWithdrawSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <!-- ----------------------widoption6---------------------- -->
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <RequestDeviceModal
      :show="requestdeviceModal.show"
      @CloseModalAddreserv="CloseModalAddreserv"
    />
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";
import RequestDeviceModal from "../../../components/Autoinfi/Bank/RequestDeviceModalscb.vue";

export default {
  components: {
    RequestDeviceModal,
  },
  data() {
    return {
      requestdeviceModal: {
        show: false,
      },
      selectedepOption: "1",
      Deposit: {
        data: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
          showbank: false,
        },
        data3: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
          showbank: false,
        },
        data5: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
          showbank: false,
        },
      },
      selectewidOption: "2",
      Withdraw: {
        data: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
        },
        data4: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
        },
        data6: {
          status: false,
          number: "",
          fullname: "",
          pin: "",
          trans_page: "",
        },
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchDepositData();
        this.fetchWithdrawData();
      }
    }
  },
  methods: {
    onDepositSubmit() {
      let depositData;

      // Determine which set of data to use based on selectedepOption
      switch (this.selectedepOption) {
        case "1":
          depositData = this.Deposit.data;
          break;
        case "3":
          depositData = this.Deposit.data3;
          break;
        case "5":
          depositData = this.Deposit.data5;
          break;
        default:
          console.error("Invalid selectedepOption");
          return;
      }
      if (
        depositData.number &&
        depositData.name &&
        depositData.pin &&
        depositData.trans_page
      ) {
        HTTP.put(
          "/admin/bank_scb?deposit",
          {
            id: this.selectedepOption,
            number: depositData.number,
            name: "ไทยพาณิชย์",
            fullname: depositData.fullname,
            pin: depositData.pin,
            trans_page: depositData.trans_page,
            showbank: depositData.showbank === true ? "1" : "0",
            status: depositData.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchDepositData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onCheckAPde() {
      HTTP.get("/admin/bank_scb?statusDeposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onCheckAPwi() {
      HTTP.get("/bank/scb/withdraw?login", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onWithdrawSubmit() {
      let withdrawData;

      // Determine which set of data to use based on selectewidOption
      switch (this.selectewidOption) {
        case "2":
          withdrawData = this.Withdraw.data;
          break;
        case "4":
          withdrawData = this.Withdraw.data4;
          break;
        case "6":
          withdrawData = this.Withdraw.data6;
          break;
        default:
          console.error("Invalid selectewidOption");
          return;
      }
      if (
        withdrawData.number &&
        withdrawData.name &&
        withdrawData.pin
        // withdrawData.trans_page
      ) {
        HTTP.put(
          "/admin/bank_scb?withdraw",
          {
            id: this.selectewidOption,
            number: withdrawData.number,
            name: "ไทยพาณิชย์",
            fullname: withdrawData.fullname,
            pin: withdrawData.pin,
            trans_page: withdrawData.trans_page,
            status: withdrawData.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchWithdrawData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchDepositData() {
      HTTP.get("/admin/bank_scb?deposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            // this.Deposit.status = resp.data.data.status === "1" ? true : false;
            // this.Deposit.number = resp.data.data.number;
            // this.Deposit.name = resp.data.data.fullname;
            // this.Deposit.pin = resp.data.data.pin;
            // this.Deposit.transPage = resp.data.data.trans_page;
            this.Deposit.data = resp.data.data;
            this.Deposit.data.status =
              resp.data.data.status === "1" ? true : false;
            this.Deposit.data.showbank =
              resp.data.data.showbank === "1" ? true : false;
            this.Deposit.data3 = resp.data.data3;
            this.Deposit.data3.status =
              resp.data.data3.status === "1" ? true : false;
            this.Deposit.data3.showbank =
              resp.data.data3.showbank === "1" ? true : false;
            this.Deposit.data5 = resp.data.data5;
            this.Deposit.data5.status =
              resp.data.data5.status === "1" ? true : false;
            this.Deposit.data5.showbank =
              resp.data.data5.showbank === "1" ? true : false;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchWithdrawData() {
      HTTP.get("/admin/bank_scb?withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            // this.Withdraw.status = resp.data.data.status === "1" ? true : false;
            // this.Withdraw.number = resp.data.data.number;
            // this.Withdraw.name = resp.data.data.fullname;
            // this.Withdraw.pin = resp.data.data.pin;
            this.Withdraw.data = resp.data.data;
            this.Withdraw.data.status =
              resp.data.data.status === "1" ? true : false;
            this.Withdraw.data4 = resp.data.data4;
            this.Withdraw.data4.status =
              resp.data.data4.status === "1" ? true : false;
            this.Withdraw.data6 = resp.data.data6;
            this.Withdraw.data6.status =
              resp.data.data6.status === "1" ? true : false;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onSavedepDevice() {
      if (this.Depositdevice != "") {
        HTTP.put(
          "admin/bank_scb?dSaveDevice",
          {
            deviceId: this.Depositdevice,
            id: this.selectedepOption,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
        this.Depositdevice = "";
        // console.log(this.Depositdevice,this.selectedepOption);
      } else {
        this.runToast("top-right", "danger", "ไม่มี DEVICE ID ที่จะบันทึก");
      }
    },
    onSavewidDevice() {
      if (this.Withdrawdevice != "") {
        HTTP.put(
          "admin/bank_scb?dSaveDevice",
          {
            deviceId: this.Withdrawdevice,
            id: this.selectewidOption,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
        // console.log(this.Withdrawdevice,this.selectewidOption);
        this.Withdrawdevice = "";
      } else {
        this.runToast("top-right", "danger", "ไม่มี DEVICE ID ที่จะบันทึก");
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    CloseModalAddreserv(value) {
      this.requestdeviceModal.show = value;
      this.fetchAllData();
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
