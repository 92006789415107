<template>
  <div>
    <modal v-model:show="showModal" size="md">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">ข้อมูลที่จำเป็นในการรับDevice ID</h3>
          </div>
        </template>
        <!-- --------------------------------step1-------------------------------- -->
        <div class="re02" v-if="state == 1">
          <div class="row">
            <div class="col-md-12 text-center">
              <select class="inputstyle" v-model="FormsavId">
                <option selected value="">
                  -- เลือกธนาคารที่จะทำการบันทึก --
                </option>
                <option value="1">บัญชีธนาคาร GSB ฝาก</option>
                <option value="2">บัญชีธนาคาร GSB ถอน</option>
              </select>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 text-center">
              <button class="btn btn-primary" @click="ondevicereq">
                กดเพื่อขอ OTP
              </button>
              <base-button type="danger" @click="onClickCloseButton()"
                >ปิด</base-button
              >
            </div>
          </div>
        </div>
        <!-- --------------------------------step1-------------------------------- -->
        <!-- --------------------------------step2-------------------------------- -->
        <div class="re02" v-if="state == 2">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="otp_id" class="form-control-label">OTP</label>
                  <input
                    id="otp_id"
                    name="otp"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="FormOtp"
                  />
                </div>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-primary" @click="onSubmitOtp">
                ยืนยัน
              </button>
              <base-button type="danger" @click="onClickCloseButton()"
                >ปิด</base-button
              >
            </div>
          </div>
        </div>
        <!-- --------------------------------step2-------------------------------- -->
        <!-- --------------------------------step3-------------------------------- -->
        <div class="re02" v-if="state == 3">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input
                  id="device_id"
                  name="device"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="this.deviceId"
                  placeholder="{SHA512}xxxxxxxxxxx|xxxxx"
                  disabled
                />
              </div>
              <div class="text-right">
                <button class="btn btn-primary" @click="onSaveDevice">
                  บันทึก DEVICE ID
                </button>
                <base-button type="danger" @click="onClickCloseButton()"
                  >ปิด</base-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------------step3-------------------------------- -->
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    modal,
  },
  props: { show: Boolean, phone: String },
  data() {
    return {
      showModal: false,
      state: 1,
      deviceId: "",
      id: "",
      seed: "",
      sid: "",
      FormsavId: "",
      FormOtp: "",
    };
  },
  methods: {
    ondevicereq() {
      if (this.FormsavId === "1" || this.FormsavId === "2") {
        const endpoint =
          this.FormsavId === "1"
            ? "/bank/gsb/deposit?Gendevice"
            : "/bank/gsb/withdraw?Gendevice";
        HTTP.get(endpoint, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        })
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.state = 1;
            } else {
              this.state = 2;
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast(
          "top-right",
          "danger",
          "กรุณาเลือกบัญชีที่ต้องการทำรายการ"
        );
      }
    },
    onSubmitOtp() {
      if (this.FormsavId === "1" || this.FormsavId === "2") {
        if (this.FormOtp != "") {
          const endpoint =
            this.FormsavId === "1"
              ? "/bank/gsb/deposit?Subotp"
              : "/bank/gsb/withdraw?Subotp";
          HTTP.post(
            endpoint,
            {
              otp: this.FormOtp,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("adb_token"),
              },
            }
          )
            .then((resp) => {
              if (resp.data.status !== undefined && !resp.data.status) {
                this.runToast(
                  "top-right",
                  "danger",
                  resp.data.msg !== undefined
                    ? resp.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
                this.onClickCloseButton();
              } else {
                this.runToast("top-right", "success", "ทำรายการสำเร็จ");
                this.state = 3;
                this.deviceId = resp.data.msg;
              }
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 401) {
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  this.runToast(
                    "top-right",
                    "danger",
                    err.response.data.msg !== undefined
                      ? err.response.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                }
              }
            });
        } else {
          this.runToast("top-right", "danger", "ไม่มี DEVICE ID ที่จะบันทึก");
        }
      } else {
        this.runToast(
          "top-right",
          "danger",
          "ไม่สามารถเลือกบัญชีที่ต้องการทำรายการ ERROR:9854"
        );
      }
    },
    onSaveDevice() {
      if (this.FormsavId === "1" || this.FormsavId === "2") {
        if (this.deviceId != "") {
          HTTP.put(
            "admin/bank_gsb?SaveDevice",
            {
              deviceId: this.deviceId,
              id: this.FormsavId,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("adb_token"),
              },
            }
          )
            .then((resp) => {
              if (resp.data.status !== undefined && !resp.data.status) {
                this.runToast(
                  "top-right",
                  "danger",
                  resp.data.msg !== undefined
                    ? resp.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
                this.onClickCloseButton();
              } else {
                this.runToast(
                  "top-right",
                  "success",
                  resp.data.msg !== undefined
                    ? resp.data.msg
                    : "บันทึกDEVICE ID สำเร็จ"
                );
                this.onClickCloseButton();
              }
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 401) {
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  this.runToast(
                    "top-right",
                    "danger",
                    err.response.data.msg !== undefined
                      ? err.response.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                }
              }
            });
        } else {
          this.runToast("top-right", "danger", "ไม่มี DEVICE ID ที่จะบันทึก");
        }
      } else {
        this.runToast(
          "top-right",
          "danger",
          "ไม่สามารถเลือกบัญชีที่ต้องการทำรายการ ERROR:9854"
        );
      }
    },
    onClickCloseButton() {
      this.$emit("CloseModalAddreserv", false);
      this.state = 1;
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetFormDate() {
      this.deviceId = "";
      this.saveTo = "";
      this.id = "";
      this.FormsavId = "";
      this.FormOtp = "";
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.resetFormDate();
    },
  },
};
</script>

<style></style>
