<template>
  <div class="content">
    <base-header>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="SCB"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ฝากไปถอน</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ scb_deposit_to_withdraw }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ถอนไปพัก</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ scb_withdraw_to_reserve }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="KBANK"
            type="gradient-green"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ฝากไปถอน</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ kbank_deposit_to_withdraw }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ถอนไปพัก</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ kbank_withdraw_to_reserve }}
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="TrueMoneywallet"
            type="gradient-orange"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ฝากไปถอน</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">0.00</div>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >ถอนไปพัก</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ truewallet_withdraw_to_reserve }}
              </div>
            </template>
          </stats-card>
        </div>
         <div class="col-xl-3 col-md-6">
          <stats-card
            title="บัญชีพัก"
            type="gradient-orange"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >พักไปฝากธนาคาร</span
              >
              <div class="font-weight-bold" style="font-size: 15px">
                {{ bank_reserve_to_deposit }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 18px"
                >พักไปฝากทรูวอลเล็ต</span
              >
              <br />
              <div class="font-weight-bold" style="font-size: 15px">
                {{ truewallet_reserve_to_deposit }}
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการโยกเงิน
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
                <!-- {{ searchQuery }} -->
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <div>
                  <base-button
                    type="primary"
                    @click="addreservModal.show = true"
                    ><i class="fa fa-plus"></i>เพิ่ม</base-button
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-success"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการฝากทั้งหมด {{ WithdrawCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="ธนาคาร"
                      >
                        <template v-slot:default="props">
                          <img
                            :src="checkBankImg(props.row.accountName)"
                            width="30"
                            alt=""
                            srcset=""
                          />
                          <br />
                          ธนาคาร{{
                            props.row.accountName === "truewallet"
                              ? "ทรูมันนี่วอลเล็ท"
                              : props.row.accountName
                          }}
                          <br />
                          {{ props.row.accountNumber }}
                          <br />
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="200px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="รายละเอียด"
                      >
                        <template v-slot:default="props">
                          {{ props.row.detail }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <AddReservModal
        :show="addreservModal.show"
        @CloseModalAddreserv="CloseModalAddreserv"
      />
    </div>
  </div>
</template>

<script>
import AddReservModal from "../../../components/Autoinfi/Bank/AddReservModal.vue";
import modal from "../../../components/Modal.vue";
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { HTTP } from "@/services/http-common";

var moment = require("moment");

export default {
  components: {
    AddReservModal,
    modal,
    flatPicker,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      addreservModal: {
        show: false,
      },
      kbank_deposit_to_withdraw: "",
      kbank_withdraw_to_reserve: "",

      scb_deposit_to_withdraw: "",
      scb_withdraw_to_reserve: "",

      truewallet_withdraw_to_reserve: "",

      bank_reserve_to_deposit: "",
      truewallet_reserve_to_deposit: "",

      loading: false,
      WithdrawCount: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "accountName",
        "accountNumber",
        "amount",
        "date",
        "detail",
        "time",
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      BankImg: {
        bbl: require("../../../assets/images/bank/bangkok.svg"),
        kbank: require("../../../assets/images/bank/kbank.svg"),
        ktb: require("../../../assets/images/bank/krungthai.svg"),
        tmb: require("../../../assets/images/bank/tmb.svg"),
        scb: require("../../../assets/images/bank/scb.svg"),
        bay: require("../../../assets/images/bank/krungsri.svg"),
        gsb: require("../../../assets/images/bank/aomsin.svg"),
        baac: require("../../../assets/images/bank/baac.svg"),
        tbank: require("../../../assets/images/bank/tnc.svg"),
        ghb: require("../../../assets/images/bank/ghb.png"),
        scbt: require("../../../assets/images/bank/scbt.png"),
        ttb: require("../../../assets/images/bank/ttb.png"),
        city: require("../../../assets/images/bank/city.png"),
        cimb: require("../../../assets/images/bank/cimb.png"),
        uob: require("../../../assets/images/bank/uob.png"),
        islamic: require("../../../assets/images/bank/islamic.png"),
        tisco: require("../../../assets/images/bank/tisco.png"),
        kkp: require("../../../assets/images/bank/kkp.png"),
        icbc: require("../../../assets/images/bank/icbc.png"),
        thaicredit: require("../../../assets/images/bank/thaicredit.png"),
        lh: require("../../../assets/images/bank/lh.svg"),
        truewallet: require("../../../assets/images/bank/truewallet.png"),
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/reserve_log", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.tableData = resp.data.data;
            this.kbank_deposit_to_withdraw =
              resp.data.kbank_deposit_to_withdraw;
            this.kbank_withdraw_to_reserve =
              resp.data.kbank_withdraw_to_reserve;
            this.scb_deposit_to_withdraw = resp.data.scb_deposit_to_withdraw;
            this.scb_withdraw_to_reserve = resp.data.scb_withdraw_to_reserve;
            this.truewallet_withdraw_to_reserve =
              resp.data.truewallet_withdraw_to_reserve;
                    this.bank_reserve_to_deposit =
              resp.data.bank_reserve_to_deposit;
      this.truewallet_reserve_to_deposit =
              resp.data.truewallet_reserve_to_deposit;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    checkCredit(credit) {
      return (Math.round(credit * 100) / 100).toFixed(2);
    },
    checkBankImg(bank) {
      if (bank === "กรุงเทพ") return this.BankImg.bbl;
      if (bank === "กสิกรไทย") return this.BankImg.kbank;
      if (bank === "กรุงไทย") return this.BankImg.ktb;
      if (bank === "ทหารไทยธนชาต") return this.BankImg.tmb;
      if (bank === "ไทยพาณิชย์") return this.BankImg.scb;
      if (bank === "ธกส") return this.BankImg.baac;
      if (bank === "กรุงศรีฯ") return this.BankImg.bay;
      if (bank === "ออมสิน") return this.BankImg.gsb;
      if (bank === "ธอส") return this.BankImg.ghb;
      if (bank === "แสตนดาร์ดชาร์เตอร์") return this.BankImg.scbt;
      if (bank === "ซิตี้แบงค์") return this.BankImg.city;
      if (bank === "ซีไอเอ็มบีไทย") return this.BankImg.cimb;
      if (bank === "ยูโอบี") return this.BankImg.uob;
      if (bank === "อิสลาม") return this.BankImg.islamic;
      if (bank === "ทิสโก้") return this.BankImg.tisco;
      if (bank === "เกียรตินาคินภัทร") return this.BankImg.kkp;
      if (bank === "ไอซีบีซี") return this.BankImg.icbc;
      if (bank === "ไทยเครดิต") return this.BankImg.thaicredit;
      if (bank === "LHBANK") return this.BankImg.lh;
      if (bank === "truewallet") return this.BankImg.truewallet;
    },
    checkFromBank(bank) {
      if (bank === "KBANK") return this.BankImg.kbank;
      if (bank === "SCB") return this.BankImg.scb;
      if (bank === "TMN") return this.BankImg.truewallet;
    },
    CloseModalAddreserv(value) {
      this.addreservModal.show = value;
      this.fetchAllData();
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
