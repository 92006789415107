<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่า Code เครดิตฟรี
                  </h2>
                </div>
              </div>
            </template>
            <div class="row mb-4" v-if="Role.code_create === '1'">
              <div class="col-md-12">
                <base-button type="primary" @click="modals.AddCodeModal = true"
                  ><i class="fa fa-gift text-danger"></i> เพิ่ม
                  Code</base-button
                >
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
            <div>
              <card
                header-classes="bg-default"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    Code ทั้งหมด {{ BonustCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="Code"
                      >
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.code }}
                            <img
                              src="../../../assets/images/copy-solid.svg"
                              width="15"
                              height="15"
                              @click="onclickCopy(props.row.code)"
                            />
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="โปรโมชั่น"
                      >
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            <i class="fa fa-gift text-danger mr-1"></i>
                            {{ props.row.promotion }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="จำนวน"
                      >
                        <template v-slot:default="props">
                          <div class="font-weight-bold">
                            {{ props.row.quantity }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        v-if="Role.code_edit != '0' || Role.code_delete != '0'"
                        min-width="150px"
                        align="center"
                        label="แก้ไข / ลบ"
                      >
                        <template v-slot:default="props">
                          <base-button
                            v-if="Role.code_edit === '1'"
                            round
                            size="lg"
                            type="primary"
                            @click="
                              (modals.EditCodeId = props.row.id),
                                (modals.EditCodeModal = true)
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </base-button>
                          <base-button
                            v-if="Role.code_delete === '1'"
                            round
                            size="lg"
                            type="danger"
                            @click="onSubmitDelete(props.row.id)"
                          >
                            <i class="fa fa-trash-alt"></i>
                          </base-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <AddCodeModal
        :show="modals.AddCodeModal"
        @CloseModalCodeBonus="CloseModalCodeBonus"
      />
      <EditCodeModal
        :show="modals.EditCodeModal"
        :id="modals.EditCodeId"
        @CloseModalEditCode="CloseModalEditCode"
      />
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import { mapGetters } from "vuex";
import AddCodeModal from "../../../components/Autoinfi/Code/AddCodeModal.vue";
import EditCodeModal from "../../../components/Autoinfi/Code/EditCodeModal.vue";
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  components: {
    AddCodeModal,
    EditCodeModal,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      modals: {
        AddCodeModal: false,
        EditCodeModal: false,
        EditCodeId: "",
      },
      loading: false,
      BonustCount: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    onclickCopy(reftocopy) {
      navigator.clipboard.writeText(reftocopy);
      this.runToast("top-right", "success", "คัดลอกสำเร็จ");
    },
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/code", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.BonustCount = this.tableData.length;
            this.loading = false;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onSubmitDelete(id) {
      swal
        .fire({
          title: "แจ้งเตือน...",
          text: "ลบ Code ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
            HTTP.delete("/admin/code?id=" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("adb_token"),
              },
            })
              .then((resp) => {
                if (resp.data.status != undefined && !resp.data.status) {
                  this.runToast(
                    "top-right",
                    "danger",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                } else {
                  this.runToast(
                    "top-right",
                    "success",
                    resp.data.msg != undefined
                      ? resp.data.msg
                      : "ทำรายการสำเร็จ"
                  );
                  this.fetchAllData();
                }
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 401) {
                    this.$router.push("/login");
                    this.$store.dispatch("logoutAuth");
                  } else {
                    this.runToast(
                      "top-right",
                      "danger",
                      err.response.data.msg != undefined
                        ? err.response.data.msg
                        : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                    );
                  }
                }
              });
          }
        });
    },
    checkStatus(value) {
      if (value == 1) return "เปิดใช้งาน";
      if (value == 0) return "ปิดใช้งาน";
    },
    CloseModalCodeBonus(value) {
      this.modals.AddCodeModal = value;
      if (!this.value) {
        this.fetchAllData();
      }
    },
    CloseModalEditCode(value) {
      this.modals.EditCodeModal = value;
      if (!this.value) {
        this.fetchAllData();
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
