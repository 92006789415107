<template>
  <modal v-model:show="showModal" size="sm">
    <template v-slot:header>
      <div class="text-center">
        <h3 class="modal-title">แก้ไขยอดเงินก่อน</h3>
      </div>
    </template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report SCB ฝาก</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField1"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report SCB ถอน</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField2"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report KBANK ฝาก</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField3"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report KBANK ถอน</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField4"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Reportบัญชีพัก</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField5"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report TrueMoneyWallet ฝาก/ถอน</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField6"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >Report TrueMoneyWallet พัก</label
            >
            <input
              id="user_id"
              name="user"
              type="number"
              class="form-control input-group-alternative"
              v-model="Form.EditField7"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button class="btn btn-primary" @click="onSubmit">ยืนยัน</button>
      <base-button type="danger" @click="onClickCloseButton()">ปิด</base-button>
    </div>
  </modal>
</template>

<script>
import modal from "../../../components/Modal.vue";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    modal,
  },
  props: { dateparam: String },

  data() {
    return {
      showModal: false,
      Form: {
        EditField1: "",
        EditField2: "",
        EditField3: "",
        EditField4: "",
        EditField5: "",
        EditField6: "",
        EditField7: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (
        this.Form.EditField1 != "" ||
        this.Form.EditField2 != "" ||
        this.Form.EditField3 != "" ||
        this.Form.EditField4 != "" ||
        this.Form.EditField5 != "" ||
        this.Form.EditField6 != "" ||
        this.Form.EditField7 != ""
      ) {
        console.log("submit process.");
        HTTP.put(
          "/admin/summary?updateYesterday",
          {
            date: this.dateparam.split(" ", 1)[0],
            SCB_DEPOSIT: this.Form.EditField1,
            SCB_WITHDRAW: this.Form.EditField2,
            KBANK_DEPOSIT: this.Form.EditField3,
            KBANK_WITHDRAW: this.Form.EditField4,
            BANK_RESERVE: this.Form.EditField5,
            TMN: this.Form.EditField6,
            TMN_RESERVE: this.Form.EditField7,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "กรุณากรอกข้อมูล");
      }
    },
    onClickCloseButton() {
      this.resetForm();
      this.$emit("CloseModalEdit", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetForm() {
      this.Form = {
        EditField1: "",
        EditField2: "",
        EditField3: "",
        EditField4: "",
        EditField5: "",
        EditField6: "",
        EditField7: "",
      };
    },
    show() {
      this.resetForm();
      this.showModal = true;
    },
  },
};
</script>

<style></style>
