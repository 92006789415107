<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-spaceship text-info',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.admin === '1'"
          :link="{
            name: 'จัดการผู้ดูแล',
            icon: 'fa fa-user-shield text-default',
            path: '/admin',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'รายการสมาชิก',
            icon: 'fa fa-users text-orange',
            path: '/member',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'รายการแจ้งถอน',
            icon: 'fa fa-comment-dollar text-primary',
            path: '/noti-withdraw',
          }"
          :badge-count="WithdrawCount"
        />
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'รายงานทั้งหมด',
            icon: 'fa fa-play text-success',
          }"
        >
          <sidebar-item
            :link="{ name: 'รายงานการเล่น Betflix', path: '/betflixlog' }"
          />
          <sidebar-item
            :link="{ name: 'รายงานเข้าออกเครดิต', path: '/creditlog' }"
          />
          <sidebar-item
            :link="{ name: 'รายงานคืนยอดเสีย', path: '/loselog' }"
          />
          <sidebar-item :link="{ name: 'รายงานอันดับสมาชิก', path: '/rank' }" />
          <sidebar-item :link="{ name: 'รายงานตามบุคคล', path: '/report' }" />
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'รายการเงิน',
            icon: 'fa fa-university text-pink',
          }"
        >
          <sidebar-item :link="{ name: 'รายการฝาก', path: '/deposit' }" />
          <sidebar-item :link="{ name: 'รายการถอน', path: '/withdraw' }" />
          <sidebar-item :link="{ name: 'รายการโยกเงิน', path: '/transfer' }" />
        </sidebar-item>
        <!-- ============================ -->
        <!-- <sidebar-item
          :link="{
            name: 'กิจกรรม',
            icon: 'fa fa-check text-red',
          }"
        >
          <sidebar-item
            :link="{ name: 'ตั้งค่ากิจกรรม', path: '/eventsetting' }"
          />
          <sidebar-item
            :link="{ name: 'faceshared', path: '/notifaceshare' }"
          />
          <sidebar-item :link="{ name: 'แต้มสะสมสมาชิก', path: '/withdraw' }" />
          <sidebar-item :link="{ name: 'ประวัติคะแนน', path: '/transfer' }" />
        </sidebar-item> -->
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'โปรโมชั่น',
            icon: 'fa fa-gift text-default',
          }"
        >
          <sidebar-item :link="{ name: 'ตั้งค่าโปรโมชั่น', path: '/bonus' }" />
          <sidebar-item
            :link="{ name: 'ประวัติรับโปรโมชั่น', path: '/bonushistory' }"
          />
          <sidebar-item
            :link="{ name: 'โปรโมชั่นปัจจุบัน', path: '/bonuscurrent' }"
          />
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'Code&nbsp;เครดิตฟรี',
            icon: 'fa fa-barcode text-orange',
          }"
        >
          <sidebar-item :link="{ name: 'ตั้งค่า Code', path: '/code' }" />
          <sidebar-item :link="{ name: 'ประวัติรับ Code', path: '/logcode' }" />
          <sidebar-item
            :link="{ name: 'ประวัติสร้าง Code', path: '/logcodecreate' }"
          />
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.spin === '1'"
          :link="{
            name: 'ประวัติ Spin',
            path: '/spinhistory',
            icon: 'fa fa-spinner text-default',
          }"
        >
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'ประวัติรับโบนัส',
            path: '/logbonus',
            icon: 'fa fa-hands text-orange',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.broadcast === '1'"
          :link="{
            name: 'Boardcast',
            path: '/boardcast',
            icon: 'fa fa-bullhorn text-info',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.website === '1'"
          :link="{
            name: 'เว็บไซต์',
            icon: 'fa fa-edit text-primary',
            path: '/website',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          :link="{
            name: 'ตั้งค่า Popup',
            path: '/popup',
            icon: 'fa fa-bullhorn text-orange',
          }"
        />
        <sidebar-item
          v-if="Role.bank === '1'"
          :link="{
            name: 'ธนาคาร',
            icon: 'fa fa-money-check-alt text-success',
          }"
        >
          <sidebar-item :link="{ name: 'BANKRESERVE', path: '/bankreserve' }" />
          <sidebar-item :link="{ name: 'SCB', path: '/scb' }" />
          <sidebar-item
            :link="{ name: 'Gatewaypayment', path: '/Gatewaypayment' }"
          />
          <!-- <sidebar-item :link="{ name: 'GSB', path: '/gsb' }" /> -->
          <!-- <sidebar-item :link="{ name: 'KBANK', path: '/kbank' }" /> -->
          <sidebar-item :link="{ name: 'TRUEWALLET', path: '/truewallet' }" />
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.bank === '1'"
          :link="{
            name: 'รายการเดินบัญชี',
            icon: 'fa fa-file-invoice-dollar text-pink',
          }"
        >
          <sidebar-item :link="{ name: 'SCB', path: '/transaction-scb' }" />
          <!-- <sidebar-item :link="{ name: 'GSB', path: '/transaction-gsb' }" /> -->
          <!-- <sidebar-item :link="{ name: 'KBANK', path: '/transaction-kbank' }" /> -->
          <sidebar-item
            :link="{ name: 'TRUEWALLET', path: '/transaction-truewallet' }"
          />
          <sidebar-item
            :link="{ name: 'Gatewaypayment', path: '/transaction-gatewayp' }"
          />
        </sidebar-item>
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.linenotify === '1'"
          :link="{
            name: 'Line Notification',
            icon: 'fa fa-bell text-info',
            path: '/line',
          }"
        />
        <!-- ============================ -->
        <sidebar-item
          v-if="Role.statusx === '1'"
          :link="{
            name: 'สถานะระบบ',
            icon: 'fa fa-signal text-primary',
            path: '/status',
          }"
        />
        <sidebar-item
          v-if="Role.summary === '1'"
          :link="{
            name: 'รายงานบัญชี(ตัดรอบ)',
            icon: 'fa fa-money-check-alt text-success',
            path: '/summary',
          }"
        />
        <sidebar-item
          v-if="this.statuspay"
          :link="{
            name: 'ชำระค่าบริการ',
            icon: 'fa fa-money-check-alt text-success',
            path: '/payservice',
          }"
        />
        <sidebar-item
          v-if="
            (User.username == 'adminkira' || User.username == 'admin5') &&
            WebSetting.website_name == 'DRAGONBALL99'
          "
          :link="{
            name: 'แจ้งยอดเครดิต',
            icon: 'fa fa-money-check-alt text-success',
            path: '/servicecalpay',
          }"
        />
      </template>
      <template v-slot:links-after>
        <hr class="my-3" />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import swal from "sweetalert2";
import DashboardNavbar from "./DashboardNavbar.vue";
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    DashboardNavbar,
  },
  data() {
    return {
      WithdrawCount: 0,
      statuspay: false,
      soundurl: "https://soundbible.com/mp3/glass_ping-Go445-1207030150.mp3",
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchWithdrawCount();
      this.fetchstatvaulepay();
    }
  },
  mounted() {
    this.initScrollbar();
    this.intervalfetchWithdrawCount();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    intervalfetchWithdrawCount() {
      setInterval(() => {
        this.fetchWithdrawCount();
      }, 10000);
    },
    fetchWithdrawCount() {
      let cur_count = parseInt(this.WithdrawCount);
      HTTP.get("/admin/withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            swal.fire({
              title: "แจ้งเตือน...",
              text:
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถโหลดข้อมูลแจ้งถอนได้ กรุณาลองใหม่",
              icon: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ตกลง",
            });
          } else {
            this.loading = false;
            this.tableData = resp.data.data;
            this.WithdrawCount = this.tableData.length;
            if (parseInt(resp.data.data2[0].amount) > cur_count) {
              this.playSound();
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              swal.fire({
                title: "แจ้งเตือน...",
                text:
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถโหลดข้อมูลแจ้งถอนได้ กรุณาลองใหม่",
                icon: "error",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ตกลง",
              });
            }
          }
        });
    },
    playSound() {
      var audio = new Audio(this.soundurl);
      // Attempt to play the audio
      var playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Automatic playback started successfully
          })
          .catch(() => {
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
            console.log(
              "Playback failed due to user didn't interact with the document first."
            );
            // Optionally, implement logic to show a notification or button to the user, prompting them to enable sound
          });
      }
    },
    fetchstatvaulepay() {
      HTTP.get("/admin/payservice?vaulepaY", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.statuspay = resp.data.data.status === "1" ? true : false;
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
    ...mapGetters(["User"]),
    ...mapGetters(["WebSetting"]),
  },
};
</script>
<style lang="scss"></style>
