<template>
  <div class="header" :style="{ background: this.primaryColor }">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/services/http-common";
export default {
  name: "base-header",
  data() {
    return {
      primaryColor:"#1d009c",
    };
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          this.$store.dispatch("savePageSetting", resp.data.data);
          if(resp.data.data != null && resp.data.data.color1 != null)
          {
            this.primaryColor = resp.data.data.color1;
          }          
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },    
  }
};
</script>
<style></style>
