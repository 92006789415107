<template>
  <div>
    <modal v-model:show="showModal" size="lg">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไข Role</h3>
          </div>
        </template>
        <div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="status_id" class="form-control-label"
                      >Role</label
                    >
                    <select
                      class="form-control input-group-alternative"
                      id="status_id"
                      name="status"
                      v-model="selectRole"
                      @change="roleChange"
                    >
                      <option selected value="">-- เลือก --</option>
                      <option
                        :value="name.id"
                        v-for="(name, index) in roleNameData"
                        :key="index"
                      >
                        {{ name.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="name_id" class="form-control-label">ชื่อ</label>
                    <input
                      id="name_id"
                      name="name"
                      type="text"
                      class="form-control input-group-alternative"
                      v-model="FormRole.name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>เมนู</label>
                  <base-checkbox v-model="FormRole.admin"
                    >จัดการผู้ดูแล</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.guru">เซียน</base-checkbox>
                  <base-checkbox v-model="FormRole.website"
                    >เว็บไซต์</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.broadcast"
                    >Broadcast</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.bank">ธนาคาร</base-checkbox>
                  <base-checkbox v-model="FormRole.linenotify"
                    >Line Notification</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.spin"
                    >ตั้งค่า Spin</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.tag"
                    >ตั้งค่า TAG</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.summary"
                    >summary</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.statusx"
                    >statusx</base-checkbox
                  >
                  <label>จัดการผู้ดูแล</label>
                  <base-checkbox v-model="FormRole.modifyro"
                    >เพิ่ม/แก้ไข สิทธิ์</base-checkbox
                  >
                </div>
                <div class="col-md-6">
                  <label>ธนาคาร</label>
                  <base-checkbox v-model="FormRole.reserve_numname"
                    >RESERVE เลขบัญชี</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.reserve_save"
                    >RESERVE ปุ่มบันทึก</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.reserve_trans"
                    >RESERVE ปุ่มโยกเงิน</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.scb_num_name"
                    >SCB ชื่อ - เลขบัญ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.scb_pin_device"
                    >SCB Pin - Device</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.scb_status"
                    >SCB สถานะ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.kbank_num_name"
                    >KBANK ชื่อ - เลข</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.kbank_status"
                    >KBANK สถานะ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.kbank_save"
                    >KBANK บันทึก</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.tw_num_name"
                    >TMW ชื่อ - เลข</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.tw_pw_pin"
                    >TMW Pass - Pin</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.tw_status"
                    >TMW สถานะ</base-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <label>รายการสมาชิก</label>
                  <base-checkbox v-model="FormRole.member_credit"
                    >แก้ไขเครดิต</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.member_edit"
                    >แก้ไขข้อมูล</base-checkbox
                  >

                  <label>รายการแจ้งถอน</label>
                  <base-checkbox v-model="FormRole.withdraw_notiauto"
                    >ปุ่มถอนออโต้</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.withdraw_edit"
                    >แก้ไขยอดถอน</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.withdraw_manual"
                    >ถอนมือ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.withdraw_noticancle"
                    >ปุ่มยกเลิก</base-checkbox
                  >
                  <label>ตั้งค่าโปรโมชั่น</label>
                  <base-checkbox v-model="FormRole.promotion_create"
                    >เพิ่มโปรโมชั่น</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.promotion_edit"
                    >แก้ไขโปรโมชั่น</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.promotion_delete"
                    >ลบโปรโมชั่น</base-checkbox
                  >
                  <label>ตั้งค่า Code</label>
                  <base-checkbox v-model="FormRole.code_create"
                    >เพิ่ม Code</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.code_edit"
                    >แก้ไข Code</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.code_delete"
                    >ลบ Code</base-checkbox
                  >
                </div>
                <div class="col-md-6">
                  <label>เว็บไซต์</label>
                  <base-checkbox v-model="FormRole.website_line"
                    >LineOA</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.website_percent_deposit"
                    >% ฝาก</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.website_percent_withdraw"
                    >% ถอน</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.website_deposit_min"
                    >ฝากขั้นต่ำ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.website_withdraw_min"
                    >ถอนขั้นต่ำ</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.website_auto"
                    >ถอน Auto</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.betflix_agent"
                    >Betflix Agent</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.betflix_api"
                    >Betflix Api</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.betflix_apikey"
                    >Betflix ApiKey</base-checkbox
                  >
                </div>
                <div class="col-md-6">
                  <label>2FA OWNER</label>
                  <base-checkbox v-model="FormRole.checkip2fa"
                    >Admin IP</base-checkbox
                  >
                  <base-checkbox v-model="FormRole.mandepwid2fa"
                    >ฝากมือมาถอน</base-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              ยืนยัน
            </button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";

export default {
  components: {
    flatPicker,
    modal,
  },
  props: { show: Boolean },
  data() {
    return {
      showModal: false,
      roleNameData: [],
      selectRole: "",
      FormRole: {
        name: "",
        audit: "",
        guru: false,
        website: false,
        tag: false,
        bank: false,
        broadcast: false,
        linenotify: false,
        admin: false,
        spin: false,
        summary: false,
        statusx: false,
        member_credit: false,
        member_edit: false,
        withdraw_notiauto: false,
        withdraw_edit: false,
        withdraw_manual: false,
        withdraw_noticancle: false,
        promotion_create: false,
        promotion_edit: false,
        promotion_delete: false,
        code_create: false,
        code_edit: false,
        code_delete: false,
        website_line: false,
        website_percent_deposit: false,
        website_percent_withdraw: false,
        website_deposit_min: false,
        website_withdraw_min: false,
        website_auto: false,
        betflix_agent: false,
        betflix_api: false,
        betflix_apikey: false,
        ufa_agent: false,
        ufa_password: false,
        ufa_apikey: false,
        reserve_numname: false,
        reserve_save: false,
        reserve_trans: false,
        scb_num_name: false,
        scb_pin_device: false,
        scb_status: false,
        kbank_num_name: false,
        kbank_status: false,
        kbank_save: false,
        tw_pw_pin: false,
        tw_num_name: false,
        tw_status: false,
        checkip2fa: false,
        mandepwid2fa: false,
        modifyro: false,
      },
    };
  },
  methods: {
    fetchRoleName() {
      HTTP.get("/admin/admin?role", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.roleNameData = resp.data.data;
            this.showModal = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onSubmit() {
      HTTP.put(
        "/admin/admin?updateRole&id=" +
          this.selectRole,
        {
          name: this.FormRole.name,
          audit: this.FormRole.audit? "1" : "0",
          guru: this.FormRole.guru ? "1" : "0",
          website: this.FormRole.website ? "1" : "0",
          tag: this.FormRole.tag ? "1" : "0",
          bank: this.FormRole.bank ? "1" : "0",
          broadcast: this.FormRole.broadcast ? "1" : "0",
          linenotify: this.FormRole.linenotify ? "1" : "0",
          admin: this.FormRole.admin ? "1" : "0",
          spin: this.FormRole.spin ? "1" : "0",
          summary: this.FormRole.summary ? "1" : "0",
          statusx: this.FormRole.statusx ? "1" : "0",
          member_credit: this.FormRole.member_credit ? "1" : "0",
          member_edit: this.FormRole.member_edit ? "1" : "0",
          withdraw_notiauto: this.FormRole.withdraw_notiauto ? "1" : "0",
          withdraw_edit: this.FormRole.withdraw_edit ? "1" : "0",
          withdraw_manual: this.FormRole.withdraw_manual ? "1" : "0",
          withdraw_noticancle: this.FormRole.withdraw_noticancle ? "1" : "0",
          promotion_create: this.FormRole.promotion_create ? "1" : "0",
          promotion_edit: this.FormRole.promotion_edit ? "1" : "0",
          promotion_delete: this.FormRole.promotion_delete ? "1" : "0",
          code_create: this.FormRole.code_create ? "1" : "0",
          code_edit: this.FormRole.code_edit ? "1" : "0",
          code_delete: this.FormRole.code_delete ? "1" : "0",
          website_line: this.FormRole.website_line ? "1" : "0",
          website_percent_deposit: this.FormRole.website_percent_deposit
            ? "1"
            : "0",
          website_percent_withdraw: this.FormRole.website_percent_withdraw
            ? "1"
            : "0",
          website_deposit_min: this.FormRole.website_deposit_min ? "1" : "0",
          website_withdraw_min: this.FormRole.website_withdraw_min ? "1" : "0",
          website_auto: this.FormRole.website_auto ? "1" : "0",
          betflix_agent: this.FormRole.betflix_agent ? "1" : "0",
          betflix_api: this.FormRole.betflix_api ? "1" : "0",
          betflix_apikey: this.FormRole.betflix_apikey ? "1" : "0",
          ufa_agent: this.FormRole.ufa_agent ? "1" : "0",
          ufa_password: this.FormRole.ufa_password ? "1" : "0",
          ufa_apikey: this.FormRole.ufa_apikey ? "1" : "0",
          reserve_numname: this.FormRole.reserve_numname ? "1" : "0",
          reserve_save: this.FormRole.reserve_save ? "1" : "0",
          reserve_trans: this.FormRole.reserve_trans ? "1" : "0",
          scb_num_name: this.FormRole.scb_num_name ? "1" : "0",
          scb_pin_device: this.FormRole.scb_pin_device ? "1" : "0",
          scb_status: this.FormRole.scb_status ? "1" : "0",
          kbank_num_name: this.FormRole.kbank_num_name ? "1" : "0",
          kbank_status: this.FormRole.kbank_status ? "1" : "0",
          kbank_save: this.FormRole.kbank_save ? "1" : "0",
          tw_pw_pin: this.FormRole.tw_pw_pin ? "1" : "0",
          tw_num_name: this.FormRole.tw_num_name ? "1" : "0",
          tw_status: this.FormRole.tw_status ? "1" : "0",
          checkip2fa: this.FormRole.checkip2fa ? "1" : "0",
          mandepwid2fa: this.FormRole.mandepwid2fa ? "1" : "0",
          modifyro: this.FormRole.modifyro ? "1" : "0",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
            );
            this.onClickCloseButton();
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    roleChange() {
      if (this.selectRole != "") {
        HTTP.get(
          "/admin/admin?roleID=" + this.selectRole,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.FormRole.name = resp.data.data.name;
              this.FormRole.guru = resp.data.data.guru === "1" ? true : false;
              this.FormRole.audit = resp.data.data.audit=== "1" ? true : false;
              this.FormRole.summary = resp.data.data.summary=== "1" ? true : false;
              this.FormRole.statusx = resp.data.data.statusx=== "1" ? true : false;
              this.FormRole.website =
                resp.data.data.website === "1" ? true : false;
              this.FormRole.tag = resp.data.data.tag === "1" ? true : false;
              this.FormRole.bank = resp.data.data.bank === "1" ? true : false;
              this.FormRole.broadcast =
                resp.data.data.broadcast === "1" ? true : false;
              this.FormRole.linenotify =
                resp.data.data.linenotify === "1" ? true : false;
              this.FormRole.admin = resp.data.data.admin === "1" ? true : false;
              this.FormRole.spin = resp.data.data.spin === "1" ? true : false;
              this.FormRole.member_credit =
                resp.data.data.member_credit === "1" ? true : false;
              this.FormRole.member_edit =
                resp.data.data.member_edit === "1" ? true : false;
              this.FormRole.withdraw_notiauto =
                resp.data.data.withdraw_notiauto === "1" ? true : false;  
              this.FormRole.withdraw_edit =
                resp.data.data.withdraw_edit === "1" ? true : false;
              this.FormRole.withdraw_manual =
                resp.data.data.withdraw_manual === "1" ? true : false;
              this.FormRole.withdraw_noticancle =
                resp.data.data.withdraw_noticancle === "1" ? true : false;  
              this.FormRole.promotion_create =
                resp.data.data.promotion_create === "1" ? true : false;
              this.FormRole.promotion_edit =
                resp.data.data.promotion_edit === "1" ? true : false;
              this.FormRole.promotion_delete =
                resp.data.data.promotion_delete === "1" ? true : false;
              this.FormRole.code_create =
                resp.data.data.code_create === "1" ? true : false;
              this.FormRole.code_edit =
                resp.data.data.code_edit === "1" ? true : false;
              this.FormRole.code_delete =
                resp.data.data.code_delete === "1" ? true : false;
              this.FormRole.website_line =
                resp.data.data.website_line === "1" ? true : false;
              this.FormRole.website_percent_deposit =
                resp.data.data.website_percent_deposit === "1" ? true : false;
              this.FormRole.website_percent_withdraw =
                resp.data.data.website_percent_withdraw === "1" ? true : false;
              this.FormRole.website_deposit_min =
                resp.data.data.website_deposit_min === "1" ? true : false;
              this.FormRole.website_withdraw_min =
                resp.data.data.website_withdraw_min === "1" ? true : false;
              this.FormRole.website_auto =
                resp.data.data.website_auto === "1" ? true : false;
              this.FormRole.betflix_agent =
                resp.data.data.betflix_agent === "1" ? true : false;
              this.FormRole.betflix_api =
                resp.data.data.betflix_api === "1" ? true : false;
              this.FormRole.betflix_apikey =
                resp.data.data.betflix_apikey === "1" ? true : false;
              this.FormRole.ufa_agent =
                resp.data.data.ufa_agent === "1" ? true : false;
              this.FormRole.ufa_password =
                resp.data.data.ufa_password === "1" ? true : false;
              this.FormRole.ufa_apikey =
                resp.data.data.ufa_apikey === "1" ? true : false;
              this.FormRole.reserve_numname =
                resp.data.data.reserve_numname === "1" ? true : false;
              this.FormRole.reserve_save =
                resp.data.data.reserve_save === "1" ? true : false;
              this.FormRole.reserve_trans =
                resp.data.data.reserve_trans === "1" ? true : false;
              this.FormRole.scb_num_name =
                resp.data.data.scb_num_name === "1" ? true : false;
              this.FormRole.scb_pin_device =
                resp.data.data.scb_pin_device === "1" ? true : false;
              this.FormRole.scb_status =
                resp.data.data.scb_status === "1" ? true : false;
              this.FormRole.kbank_num_name =
                resp.data.data.kbank_num_name === "1" ? true : false;
              this.FormRole.kbank_status =
                resp.data.data.kbank_status === "1" ? true : false;
              this.FormRole.kbank_save =
                resp.data.data.kbank_save === "1" ? true : false;
              this.FormRole.tw_pw_pin =
                resp.data.data.tw_pw_pin === "1" ? true : false;
              this.FormRole.tw_num_name =
                resp.data.data.tw_num_name === "1" ? true : false;
              this.FormRole.tw_status =
                resp.data.data.tw_status === "1" ? true : false;
              this.FormRole.checkip2fa =
                resp.data.data.checkip2fa === "1" ? true : false;
              this.FormRole.mandepwid2fa =
                resp.data.data.mandepwid2fa === "1" ? true : false;
              this.FormRole.modifyro =
                resp.data.data.modifyro === "1" ? true : false;
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      }
    },
    resetForm() {
      this.selectRole = "";
      this.FormRole.name = "";
       this.FormRole.audit = false;
      this.FormRole.guru = false;
      this.FormRole.website = false;
      this.FormRole.tag = false;
      this.FormRole.bank = false;
      this.FormRole.broadcast = false;
      this.FormRole.linenotify = false;
      this.FormRole.admin = false;
      this.FormRole.spin = false;
      this.FormRole.summary = false;
      this.FormRole.statusx = false;
      this.FormRole.member_credit = false;
      this.FormRole.member_edit = false;
      this.FormRole.withdraw_notiauto = false;
      this.FormRole.withdraw_edit = false;
      this.FormRole.withdraw_manual = false;
      this.FormRole.withdraw_noticancle = false;
      this.FormRole.promotion_create = false;
      this.FormRole.promotion_edit = false;
      this.FormRole.promotion_delete = false;
      this.FormRole.code_create = false;
      this.FormRole.code_edit = false;
      this.FormRole.code_delete = false;
      this.FormRole.website_line = false;
      this.FormRole.website_percent_deposit = false;
      this.FormRole.website_percent_withdraw = false;
      this.FormRole.website_deposit_min = false;
      this.FormRole.website_withdraw_min = false;
      this.FormRole.website_auto = false;
      this.FormRole.betflix_agent = false;
      this.FormRole.betflix_api = false;
      this.FormRole.betflix_apikey = false;
      this.FormRole.ufa_agent = false;
      this.FormRole.ufa_password = false;
      this.FormRole.ufa_apikey = false;
      this.FormRole.reserve_numname = false;
      this.FormRole.reserve_save = false;
      this.FormRole.reserve_trans = false;
      this.FormRole.scb_num_name = false;
      this.FormRole.scb_pin_device = false;
      this.FormRole.scb_status = false;
      this.FormRole.kbank_num_name = false;
      this.FormRole.kbank_status = false;
      this.FormRole.kbank_save = false;
      this.FormRole.tw_pw_pin = false;
      this.FormRole.tw_num_name = false;
      this.FormRole.tw_status = false;
      this.FormRole.checkip2fa = false;
      this.FormRole.mandepwid2fa = false;
      this.FormRole.modifyro = false;
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalEditRole", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      this.resetForm();
      if (val) {
        this.fetchRoleName();
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
