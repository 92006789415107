const token = localStorage.getItem("adb_token");
const initialState = token
  ? { loggedIn: true, username: null }
  : { loggedIn: false, username: null };

export const user = {
  state() {
    return {
      user: initialState,
      role: {},
      webSetting: {
        website_name: null,
        lineId: null,
        logo: null,
        title: null,
        withdraw_min: null,
        withdrawtimemax: null,
        color1: null,
        url_affiliate: null,
        website_annoucetext: null,
      },
      // role: {
      //   broadcast: null,
      //   code_create: null,
      //   code_delete: null,
      //   code_edit: null,
      //   guru: null,
      //   kbank_num_name: null,
      //   kbank_save: null,
      //   kbank_status: null,
      //   linenotify: null,
      //   member_credit: null,
      //   member_edit: null,
      //   promotion_create: null,
      //   promotion_delete: null,
      //   promotion_edit: null,
      //   scb_num_name: null,
      //   scb_pin_device: null,
      //   scb_status: null,
      //   spin: null,
      //   tag: null,
      //   tw_num_name: null,
      //   tw_pw_pin: null,
      //   tw_status: null,
      //   ufa_agent: null,
      //   ufa_apikey: null,
      //   ufa_password: null,
      //   website: null,
      //   website_auto: null,
      //   website_deposit_min: null,
      //   website_line: null,
      //   website_percent_deposit: null,
      //   website_percent_withdraw: null,
      //   website_withdraw_min: null,
      //   withdraw_edit: null,
      //   withdraw_manual: null,
      //   betflix_agent: null,
      //   betflix_api: null,
      //   betflix_apikey: null,
      //   bank: null,
      //   admin: null,
      // }
    };
  },
  getters: {
    Auth: (state) => {
      return state.user.loggedIn;
    },
    User: (state) => {
      return state.user;
    },
    Role: (state) => {
      return state.role;
    },
    WebSetting: (state) => {
      return state.webSetting;
    },
  },
  actions: {
    loginAuth(context, token) {
      context.commit("loginAuth", token);
    },
    getProfile(context, user) {
      context.commit("getProfile", user);
    },
    logoutAuth(context) {
      context.commit("logoutAuth");
    },
    savePageSetting(context, setting) {
      context.commit("savePageSetting", setting);
    },
  },
  mutations: {
    loginAuth(state, token) {
      state.user.loggedIn = true;
      localStorage.setItem("adb_token", token);
    },
    getProfile(state, user) {
      state.user.username = user.username;
      state.role = user;
    },
    logoutAuth(state) {
      state.user.loggedIn = false;
      state.user.username = null;
      localStorage.removeItem("adb_token");
    },
    savePageSetting(state, setting) {
      state.webSetting.lineId = setting.line_id;
      state.webSetting.logo = setting.logo;
      state.webSetting.title = setting.title;
      state.webSetting.withdraw_min = setting.withdraw_min;
      state.webSetting.withdrawtimemax = setting.withdrawtimemax;
      state.webSetting.bgpicture = setting.bgpicture;
      state.webSetting.color1 = setting.color1;
      state.webSetting.website_name = setting.website_name;
      state.webSetting.url_affiliate = setting.url_affiliate;
      state.webSetting.website_annoucetext = setting.website_annoucetext;
    },
  },
};
