<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าบัญชีกสิกรไทย
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ฝาก</div>
                  </div>
                </div>
                <div
                  class="row text-center mt-4"
                  v-show="Role.kbank_status === '1'"
                >
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Deposit.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="btn btn-warning w-15"
                    @click="onCheckAPde"
                  >
                    checkAPI
                  </button>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.number"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >ชื่อ - นามสกุล</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.name"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >data</label
                      >
                      <textarea
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.data"
                        :disabled="Role.kbank_num_name != '1'"
                        rows="10"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >pin</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Deposit.pin"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.kbank_save === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onDepositSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ถอน</div>
                  </div>
                </div>
                <div
                  class="row text-center mt-4"
                  v-show="Role.kbank_status === '1'"
                >
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Withdraw.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="btn btn-warning w-15"
                    @click="onCheckAPwi"
                  >
                    checkAPI
                  </button>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.number"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >ชื่อ - นามสกุล</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.name"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >data</label
                      >
                      <textarea
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.data"
                        :disabled="Role.kbank_num_name != '1'"
                        rows="10"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >pin</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.pin"
                        :disabled="Role.kbank_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.kbank_save === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onWithdrawSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      Deposit: {
        status: false,
        number: "",
        name: "",
        data: "",
        pin: "",
      },
      Withdraw: {
        status: false,
        number: "",
        name: "",
        data: "",
        pin: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchDepositData();
        this.fetchWithdrawData();
      }
    }
  },
  methods: {
    onDepositSubmit() {
      if (
        this.Deposit.number != "" &&
        this.Deposit.name != "" &&
        this.Deposit.data != "" &&
        this.Deposit.pin != ""
      ) {
        HTTP.put(
          "/admin/bank_kbank?deposit",
          {
            number: this.Deposit.number,
            name: "กสิกรไทย",
            fullname: this.Deposit.name,
            data: this.Deposit.data,
            pin: this.Deposit.pin,
            status: this.Deposit.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchDepositData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onWithdrawSubmit() {
      if (
        this.Withdraw.number != "" &&
        this.Withdraw.name != "" &&
        this.Withdraw.data != "" &&
        this.Withdraw.pin != ""
      ) {
        HTTP.put(
          "/admin/bank_kbank?withdraw",
          {
            number: this.Withdraw.number,
            name: "กสิกรไทย",
            fullname: this.Withdraw.name,
            data: this.Withdraw.data,
            pin: this.Withdraw.pin,
            status: this.Withdraw.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchWithdrawData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchDepositData() {
      HTTP.get("/admin/bank_kbank?deposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Deposit.status = resp.data.data.status === "1" ? true : false;
            this.Deposit.number = resp.data.data.number;
            this.Deposit.name = resp.data.data.fullname;
            this.Deposit.data = resp.data.data.data;
            this.Deposit.pin = resp.data.data.pin;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onCheckAPde() {
      HTTP.get("/admin/bank_kbank?statusDeposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onCheckAPwi() {
      HTTP.get("/admin/bank_kbank?statusWithdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchWithdrawData() {
      HTTP.get("/admin/bank_kbank?withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Withdraw.status = resp.data.data.status === "1" ? true : false;
            this.Withdraw.number = resp.data.data.number;
            this.Withdraw.name = resp.data.data.fullname;
            this.Withdraw.data = resp.data.data.data;
            this.Withdraw.pin = resp.data.data.pin;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
