<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    จัดการบัญชีพัก
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ธนาคาร</div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Bank.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                    <div class="row" v-if="Role.reserve_trans === '1'">
                      <div class="col-md-12 text-center">
                        <button
                          type="submit"
                          class="btn btn-info w-30"
                          @click="transfermoneyData"
                        >
                          โยกเงิน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Bank.number"
                        :disabled="Role.reserve_numname != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="bankname_id" class="form-control-label"
                        >ธนาคาร</label
                      >
                      <select
                        class="form-control"
                        v-model="Bank.name"
                        :disabled="Role.reserve_numname != '1'"
                      >
                        <option selected value="">-- เลือกธนาคาร --</option>
                        <option value="กรุงเทพ">
                          ธนาคารกรุงเทพ จำกัด (มหาชน)
                        </option>
                        <option value="กสิกรไทย">
                          ธนาคารกสิกรไทย จำกัด (มหาชน)
                        </option>
                        <option value="กรุงไทย">
                          ธนาคารกรุงไทย จำกัด (มหาชน)
                        </option>
                        <option value="ทหารไทยธนชาต">
                          ธนาคารทหารไทยธนชาต จำกัด (มหาชน)
                        </option>
                        <option value="ไทยพาณิชย์">
                          ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                        </option>
                        <option value="ซิตี้แบงค์">
                          ธนาคารซิตี้แบงก์ เอ็น.เอ.
                        </option>
                        <option value="แสตนดาร์ดชาร์เตอร์">
                          ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)
                        </option>
                        <option value="ซีไอเอ็มบีไทย">
                          ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)
                        </option>
                        <option value="ยูโอบี">
                          ธนาคารยูโอบี จำกัด (มหาชน)
                        </option>
                        <option value="กรุงศรีฯ">
                          ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)
                        </option>
                        <option value="ออมสิน">ธนาคารออมสิน</option>
                        <option value="ธอส">ธนาคารอาคารสงเคราะห์</option>
                        <option value="ธกส">
                          ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
                        </option>
                        <option value="อิสลาม">
                          ธนาคารอิสลามแห่งประเทศไทย
                        </option>
                        <option value="ทิสโก้">
                          ธนาคารทิสโก้ จำกัด (มหาชน)
                        </option>
                        <option value="เกียรตินาคินภัทร">
                          ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)
                        </option>
                        <option value="ไอซีบีซี">
                          ธนาคารไอซีบีซี (ไทย) จำกัด (มหาชน)
                        </option>
                        <option value="ไทยเครดิต">
                          ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)
                        </option>
                        <option value="LHBANK">
                          ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="bank_deposit_id" class="form-control-label"
                        >บัญชีฝาก (ยอดถึงแล้วจะโยกไปถอน)</label
                      >
                      <input
                        id="bank_deposit_id"
                        name="bank_deposit"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Bank.deposit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="bank_withdraw_id" class="form-control-label"
                        >บัญชีถอน (ยอดถึงแล้วจะโยกไปพัก)</label
                      >
                      <input
                        id="bank_withdraw_id"
                        name="bank_withdraw"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Bank.withdraw"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="bank_delay_id" class="form-control-label"
                        >หน่วงเวลาตรวจสอบ(นาที)</label
                      >
                      <input
                        id="bank_delay_id"
                        name="bank_delay"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Bank.delay"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.reserve_save === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onBankSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">Truewallet</div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Truewallet.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row" v-if="Role.reserve_trans === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-danger w-30"
                      @click="transfertruemoneyData"
                    >
                      โยกเงิน
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Truewallet.number"
                        :disabled="Role.reserve_numname != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="tmn_name_id" class="form-control-label"
                        >ธนาคาร</label
                      >
                      <select
                        class="form-control"
                        v-model="Truewallet.name"
                        :disabled="Role.reserve_numname != '1'"
                      >
                        <option selected value="">-- เลือกธนาคาร --</option>
                        <option value="กสิกรไทย">
                          ธนาคารกสิกรไทย จำกัด (มหาชน)
                        </option>
                        <option value="ไทยพาณิชย์">
                          ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                        </option>
                        <option value="กรุงเทพ">
                          ธนาคารกรุงเทพ จำกัด (มหาชน)
                        </option>
                        <option value="กรุงไทย">
                          ธนาคารกรุงไทย จำกัด (มหาชน)
                        </option>
                        <option value="กรุงศรีฯ">
                          ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)
                        </option>
                        <option value="TMN">TRUEMONEY WALLET</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="tmn_deposit_id" class="form-control-label"
                        >ยอดคงเหลือในบัญชี</label
                      >
                      <input
                        id="tmn_deposit_id"
                        name="tmn_deposit"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Truewallet.deposit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="tmn_withdraw_id" class="form-control-label"
                        >ถอนเเมื่อยอดถอนได้เกิน</label
                      >
                      <input
                        id="tmn_withdraw_id"
                        name="tmn_withdraw"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Truewallet.withdraw"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="tmn_delay_id" class="form-control-label"
                        >หน่วงเวลาตรวจสอบ(นาที)</label
                      >
                      <input
                        id="tmn_delay_id"
                        name="tmn_delay"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Truewallet.delay"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.reserve_save === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onTruewalletSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      GatewayPayment
                    </div>
                    <div class="row text-center mt-4">
                      <div class="col-md-12">
                        <label class="custom-toggle">
                          <input type="checkbox" v-model="Gatewayp.status" />
                          <span
                            class="custom-toggle-slider rounded-circle"
                            data-label-off="ปิด"
                            data-label-on="เปิด"
                          >
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="row" v-if="Role.reserve_trans === '1'">
                      <div class="col-md-12 text-center">
                        <button
                          type="submit"
                          class="btn btn-danger w-30"
                          @click="transferGatewaypData"
                        >
                          โยกเงิน
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="banknumber_id" class="form-control-label"
                            >เลขบัญชี</label
                          >
                          <input
                            id="banknumber_id"
                            name="banknumber"
                            type="text"
                            class="form-control input-group-alternative"
                            v-model="Gatewayp.number"
                            :disabled="Role.reserve_numname != '1'"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="tmn_name_id" class="form-control-label"
                            >ธนาคาร</label
                          >
                          <select
                            class="form-control"
                            v-model="Gatewayp.name"
                            :disabled="Role.reserve_numname != '1'"
                          >
                            <option selected value="">-- เลือกธนาคาร --</option>
                            <option value="กสิกรไทย">
                              ธนาคารกสิกรไทย จำกัด (มหาชน)
                            </option>
                            <option value="ไทยพาณิชย์">
                              ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                            </option>
                            <option value="กรุงเทพ">
                              ธนาคารกรุงเทพ จำกัด (มหาชน)
                            </option>
                            <option value="กรุงไทย">
                              ธนาคารกรุงไทย จำกัด (มหาชน)
                            </option>
                            <option value="กรุงศรีฯ">
                              ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)
                            </option>
                            <option value="TMN">TRUEMONEY WALLET</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="tmn_deposit_id" class="form-control-label"
                            >ยอดคงเหลือในบัญชี</label
                          >
                          <input
                            id="tmn_deposit_id"
                            name="tmn_deposit"
                            type="text"
                            class="form-control input-group-alternative"
                            v-model="Gatewayp.deposit"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label
                            for="tmn_withdraw_id"
                            class="form-control-label"
                            >ถอนเเมื่อยอดถอนได้เกิน</label
                          >
                          <input
                            id="tmn_withdraw_id"
                            name="tmn_withdraw"
                            type="text"
                            class="form-control input-group-alternative"
                            v-model="Gatewayp.withdraw"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="tmn_delay_id" class="form-control-label"
                            >หน่วงเวลาตรวจสอบ(นาที)</label
                          >
                          <input
                            id="tmn_delay_id"
                            name="tmn_delay"
                            type="text"
                            class="form-control input-group-alternative"
                            v-model="Gatewayp.delay"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="Role.reserve_save === '1'">
                      <div class="col-md-12 text-center">
                        <button
                          type="submit"
                          class="btn btn-primary w-50"
                          @click="onGatewaypSubmit"
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      Bank: {
        status: false,
        number: "",
        name: "",
        withdraw: "",
        deposit: "",
        delay: "",
      },
      Deposit: {
        status: false,
        number: "",
        name: "",
        pin: "",
        device: "",
        transPage: "",
      },
      Truewallet: {
        status: false,
        number: "",
        name: "",
        withdraw: "",
        deposit: "",
        delay: "",
      },
      Gatewayp: {
        status: false,
        number: "",
        name: "",
        withdraw: "",
        deposit: "",
        delay: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchReserveData();
        this.fetchDepositData();
      }
    }
  },
  methods: {
    onDepositSubmit() {
      if (
        this.Deposit.number != "" &&
        this.Deposit.name != "" &&
        this.Deposit.pin != "" &&
        this.Deposit.device != "" &&
        this.Deposit.transPage != ""
      ) {
        HTTP.put(
          "/admin/bank_scb?deposit",
          {
            number: this.Deposit.number,
            name: "ไทยพาณิชย์",
            fullname: this.Deposit.name,
            deviceID: this.Deposit.device,
            pin: this.Deposit.pin,
            trans_page: this.Deposit.transPage,
            status: this.Deposit.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchDepositData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchDepositData() {
      HTTP.get("/admin/bank_scb?deposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Deposit.status = resp.data.data.status === "1" ? true : false;
            this.Deposit.number = resp.data.data.number;
            this.Deposit.name = resp.data.data.fullname;
            this.Deposit.pin = resp.data.data.pin;
            this.Deposit.device = resp.data.data.deviceID;
            this.Deposit.transPage = resp.data.data.trans_page;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onBankSubmit() {
      if (
        this.Bank.number != "" &&
        this.Bank.name != "" &&
        this.Bank.withdraw != "" &&
        this.Bank.deposit != "" &&
        this.Bank.delay != ""
      ) {
        HTTP.put(
          "/admin/bank_reserve?bank",
          {
            number: this.Bank.number,
            name: this.Bank.name,
            withdraw: this.Bank.withdraw,
            status: this.Bank.status === true ? "1" : "0",
            deposit: this.Bank.deposit,
            delay: this.Bank.delay * 60,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchReserveData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    transfermoneyData() {
      HTTP.get("/cronjob/bank_reserve", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินสำเร็จ"
            );
          } else {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินไม่สำเร็จ"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onTruewalletSubmit() {
      if (
        this.Truewallet.number != "" &&
        this.Truewallet.name != "" &&
        this.Truewallet.withdraw != "" &&
        this.Truewallet.deposit != "" &&
        this.Truewallet.delay != ""
      ) {
        HTTP.put(
          "/admin/bank_reserve?truewallet",
          {
            number: this.Truewallet.number,
            name: this.Truewallet.name,
            withdraw: this.Truewallet.withdraw,
            status: this.Truewallet.status === true ? "1" : "0",
            deposit: this.Truewallet.deposit,
            delay: this.Truewallet.delay * 60,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchReserveData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onGatewaypSubmit() {
      if (
        this.Gatewayp.number != "" &&
        this.Gatewayp.name != "" &&
        this.Gatewayp.withdraw != "" &&
        this.Gatewayp.deposit != "" &&
        this.Gatewayp.delay != ""
      ) {
        HTTP.put(
          "/admin/bank_reserve?Gatewayp",
          {
            number: this.Gatewayp.number,
            name: this.Gatewayp.name,
            withdraw: this.Gatewayp.withdraw,
            status: this.Gatewayp.status === true ? "1" : "0",
            deposit: this.Gatewayp.deposit,
            delay: this.Gatewayp.delay * 60,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchReserveData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchReserveData() {
      HTTP.get("/admin/bank_reserve", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Bank.status = resp.data.data[0].status === "1" ? true : false;
            this.Bank.number = resp.data.data[0].number;
            this.Bank.name = resp.data.data[0].name;
            this.Bank.withdraw = resp.data.data[0].withdraw;
            this.Bank.deposit = resp.data.data[0].deposit;
            this.Bank.delay = resp.data.data[0].delay / 60;

            this.Truewallet.status =
              resp.data.data[1].status === "1" ? true : false;
            this.Truewallet.number = resp.data.data[1].number;
            this.Truewallet.name = resp.data.data[1].name;
            this.Truewallet.withdraw = resp.data.data[1].withdraw;
            this.Truewallet.deposit = resp.data.data[1].deposit;
            this.Truewallet.delay = resp.data.data[1].delay / 60;

            this.Gatewayp.status =
              resp.data.data[2].status === "1" ? true : false;
            this.Gatewayp.number = resp.data.data[2].number;
            this.Gatewayp.name = resp.data.data[2].name;
            this.Gatewayp.withdraw = resp.data.data[2].withdraw;
            this.Gatewayp.deposit = resp.data.data[2].deposit;
            this.Gatewayp.delay = resp.data.data[2].delay / 60;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    transfertruemoneyData() {
      HTTP.get("/cronjob/truewallet_reserve", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินสำเร็จ"
            );
          } else {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินไม่สำเร็จ"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    transferGatewaypData() {
      HTTP.get("/cronjob/gatewayp_reserve", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.status == 200) {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินสำเร็จ"
            );
          } else {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined ? resp.data.msg : "โยกเงินไม่สำเร็จ"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
