<template>
  <div class="content">
    <base-header>
      <div class="row">
        <div class="col-xl-4 col-md-6">
          <stats-card
            title="betflix"
            type="gradient-primary"
            icon=" fa fa-university "
            ><template v-slot:body>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดเคลื่อนไหวเครดิตวันนี้</span
              >

              <div class="font-weight-bold" style="font-size: 20px">
                {{ betflix_moving_balance }}
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดบวกกระดานวันนี้</span
              >
              <div class="font-weight-bold" style="font-size: 20px">
                <a @click="hidden = !hidden">
                  <a v-if="!hidden"
                    >{{ betflix_withdraw_total }} <i class="arrow down"></i></a
                  ><a v-else
                    >{{ betflix_withdraw_total }} <i class="arrow up"></i
                  ></a>
                  <p></p>
                </a>
                <ul v-if="!hidden"></ul>
                <ul class="dropdown" style="font-size: 13px" v-else>
                  <li>ยอดถอน / {{ betflix_withdraw_w_move }}</li>
                  <li>เครดิตส่วนเกินจากถอน / {{ betflix_withdraw_w_cut }}</li>
                </ul>
              </div>
              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดลบกระดานวันนี้</span
              >
              <div class="font-weight-bold navigation" style="font-size: 20px">
                <a @click="hidden1 = !hidden1">
                  <a v-if="!hidden1"
                    >{{ betflix_deposit_total }} <i class="arrow down"></i></a
                  ><a v-else
                    >{{ betflix_deposit_total }} <i class="arrow up"></i
                  ></a>
                  <p></p>
                </a>
                <ul v-if="!hidden1"></ul>
                <ul class="dropdown" style="font-size: 13px" v-else>
                  <li>ยอดฝากวันนี้ / {{ betflix_deposit }}</li>
                  <li>โบนัสโปรโมชั่นวันนี้ / {{ bonus_promotion }}</li>
                  <li>เครดิตฟรีวันนี้ / {{ credit_free }}</li>
                </ul>
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-md-6">
          <stats-card
            title="credit"
            type="gradient-green"
            icon=" fa fa-university "
            ><template v-slot:body>
                <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดฝากวันนี้</span
              >
              <div class="font-weight-bold" style="font-size: 20px">
                <a @click="hidden2 = !hidden2">
                  <a v-if="!hidden2"
                    >{{ deposit_all }} <i class="arrow down"></i></a
                  ><a v-else
                    >{{ deposit_all }} <i class="arrow up"></i
                  ></a>
                  <p></p>
                </a>
                <ul v-if="!hidden2"></ul>
                <ul class="dropdown" style="font-size: 13px" v-else>
                  <li>ฝากออโต้ / {{ deposit_auto }}</li>
                  <li>เพิ่มเครดิต / {{ deposit_manual }}</li>
                </ul>
              </div>

              <span class="text-nowrap font-weight-bold" style="font-size: 12px"
                >ยอดถอนวันนี้</span
              >

              <div class="font-weight-bold" style="font-size: 20px">
                <a @click="hidden3 = !hidden3">
                  <a v-if="!hidden3"
                    >{{ withdraw_all }} <i class="arrow down"></i></a
                  ><a v-else
                    >{{ withdraw_all }} <i class="arrow up"></i
                  ></a>
                  <p></p>
                </a>
                <ul v-if="!hidden3"></ul>
                <ul class="dropdown" style="font-size: 13px" v-else>
                  <li>ถอนออโต้ / {{ withdraw_auto }}</li>
                  <li>ตัดเครดิต / {{ withdraw_manual }}</li>
                  <li>ยกเลิกถอน / {{ withdraw_calcel }}</li>
                </ul>
              </div>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานเข้าออกเครดิตกระเป๋ากลาง
                  </h2>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    รายการเข้าออกเครดิตทั้งหมด {{ CurcountCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="สมาชิก"
                      >
                        <template v-slot:default="props">
                          {{ props.row.phone }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่ / เวลา"
                      >
                        <template v-slot:default="props">
                          {{ props.row.date }}
                          <br />
                          {{ props.row.time }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="w_before"
                      >
                        <template v-slot:default="props">
                          {{ props.row.w_before }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="w_move"
                      >
                        <template v-slot:default="props">
                          {{ props.row.w_move }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="w_cut"
                      >
                        <template v-slot:default="props">
                          {{ props.row.w_cut }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="w_after"
                      >
                        <template v-slot:default="props">
                          {{ props.row.w_after }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="โปรโมชั่น"
                      >
                        <template v-slot:default="props">
                          {{ props.row.promotion }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="ref"
                      >
                        <template v-slot:default="props">
                          {{ props.row.ref }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";

var moment = require("moment");

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    flatPicker,
  },
  data() {
    return {
      betflix_moving_balance: "",
      betflix_deposit: "",
      betflix_deposit_total: "",
      betflix_withdraw_total: "",
      betflix_withdraw_w_cut: "",
      betflix_withdraw_w_move: "",
      bonus_promotion: "",
      credit_free: "",
      withdraw_all: "",
      websitename: "",
      withdraw_auto: "",
      withdraw_calcel: "",
      withdraw_manual: "",
      deposit_all: "",
      deposit_auto: "",
      deposit_manual: "",
      hidden: false,
      hidden1: false,
      hidden2: false,
      hidden3: false,
      user: "",
      DateStart: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      DateEnd: moment(new Date()).format("YYYY-MM-DD 23:59"),
      CurcountCount: "0",
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [500, 1000],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "date",
        "time",
        "w_before",
        "w_move",
        "w_cut",
        "w_after",
        "promotion",
        "ref",
        "phone",
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchShowData();
    }
  },
  mounted() {
    this.fetchWebSetting();
  },
  methods: {
    checkAmountWallet(amount) {
      if (amount != undefined) {
        let value = amount.toString();
        let result = value.includes("-");
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    fetchWebSetting() {
      HTTP.get(`system/info`)
        .then((resp) => {
          if(resp.data.data != null && resp.data.data.website_name != null)
          {
            this.websitename = resp.data.data.website_name;
          }          
        })
        .catch((err) => {
          this.$toast.error(
            err.data.msg != undefined
              ? err.data.msg
              : "ไม่สามารถโหลดได้ กรุณาลองใหม่"
          );
        });
    },
    checkNote(note) {
      if (note != undefined) {
        let result = note.includes(this.websitename);
        if (result) {
          return true;
        } else {
          return false;
        }
      }
    },
    onClickQuery() {
      if (
        this.DateStart != null &&
        this.DateEnd != null &&
        this.user != ""
      ) {
        this.fetchByDate();
      } else if (
        this.user != "" &&
        this.DateStart == null &&
        this.DateEnd == null
      ) {
        this.fetchAllData();
      }
    },
    fetchByDate() {
      const startArr = this.DateStart.split(" ");
      const endArr = this.DateEnd.split(" ");
      HTTP.get(
        "/admin/tranfer_log?s_date=" +
          startArr[0] +
          "&e_date=" +
          endArr[0] +
          "&s_time=" +
          startArr[1] +
          ":00&e_time=" +
          endArr[1] +
          ":59",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchShowData() {
      HTTP.get("/admin/tranfer_log", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.withdraw_all = resp.data.withdraw_all;
            this.withdraw_auto = resp.data.withdraw_auto;
            this.withdraw_calcel = resp.data.withdraw_calcel;
          this.withdraw_manual = resp.data.withdraw_manual;
            this.deposit_all = resp.data.deposit_all;
            this.deposit_auto = resp.data.deposit_auto;
            this.deposit_manual = resp.data.deposit_manual;
            this.betflix_deposit = resp.data.betflix_deposit;
            this.betflix_deposit_total = resp.data.betflix_deposit_total;
            this.betflix_withdraw_total = resp.data.betflix_withdraw_total;
            this.betflix_withdraw_w_cut = resp.data.betflix_withdraw_w_cut;
            this.betflix_withdraw_w_move = resp.data.betflix_withdraw_w_move;
            this.bonus_promotion = resp.data.bonus_promotion;
            this.credit_free = resp.data.credit_free;
            this.betflix_moving_balance = resp.data.betflix_moving_balance;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchAllData() {
      HTTP.get("/admin/tranfer_log?", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.tableData = resp.data.data;
            this.CurcountCount = this.tableData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style>
.dropdown li {
  display: block;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
