<template>
  <div>
    <modal
      v-model:show="showModal"
      size="lg"
      style="overflow-y: auto; max-height: 100%"
    >
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไขโบนัส</h3>
          </div>
        </template>
        <div>
          <div class="row mb-3">
            <div class="col-md-12 text-center">
              <label class="custom-toggle">
                <input type="checkbox" v-model="Form.statusSwitches" />
                <span
                  class="custom-toggle-slider rounded-circle"
                  data-label-off="ปิด"
                  data-label-on="เปิด"
                >
                </span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="type_id" class="form-control-label">ประเภท</label>
                <select
                  class="form-control input-group-alternative"
                  id="type_id"
                  name="type"
                  v-model="Form.type"
                >
                  <option selected value="">-- เลือก --</option>
                  <option value="สมัครสมาชิกใหม่">สมัครสมาชิกใหม่</option>
                  <option value="รับครั้งแรกของวัน">รับครั้งแรกของวัน</option>
                  <option value="รับได้ทั้งวัน">รับได้ทั้งวัน</option>
                  <option value="CODE เครดิตฟรี">CODE เครดิตฟรี</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="promotion_id" class="form-control-label"
                  >ชื่อโปรโมชั่น</label
                >
                <input
                  id="promotion_id"
                  name="promotion"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="descriptionbonus_id" class="form-control-label"
                  >รายละเอียด</label
                >
                <input
                  id="descriptionbonus_id"
                  name="descriptionbonus"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.description"
                />
              </div>
            </div>
          </div>
          <a v-if="Form.genre == 'B'">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="deposit_id" class="form-control-label">ฝาก</label>
                  <input
                    id="deposit_id"
                    name="deposit"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="credit_id" class="form-control-label">รับ</label>
                  <input
                    id="credit_id"
                    name="credit"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.credit"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="wallet_id" class="form-control-label"
                    >ยอดเงินในกระเป๋า</label
                  >
                  <input
                    id="wallet_id"
                    name="wallet"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.wallet"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="withdraw_max_id" class="form-control-label"
                    >ถอนได้สูงสุด</label
                  >
                  <input
                    id="withdraw_max_id"
                    name="withdraw_max"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw_max"
                  />
                </div>
              </div>
            </div>
          </a>
          <a v-if="Form.genre == 'P'">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="deposit_id" class="form-control-label"
                    >ฝาก(step1)</label
                  >
                  <input
                    id="deposit_id"
                    name="deposit"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="credit_id" class="form-control-label">รับ</label>
                  <input
                    id="credit_id"
                    name="credit"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.credit"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="withdraw_max_id" class="form-control-label"
                    >ถอนได้สูงสุด</label
                  >
                  <input
                    id="withdraw_max_id"
                    name="withdraw_max"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw_max"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="deposit2_id" class="form-control-label"
                    >ฝาก(step2)</label
                  >
                  <input
                    id="deposit2_id"
                    name="deposit2"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit2"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="credit2_id" class="form-control-label">รับ</label>
                  <input
                    id="credit2_id"
                    name="credit2"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.credit2"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="withdraw2_max_id" class="form-control-label"
                    >ถอนได้สูงสุด</label
                  >
                  <input
                    id="withdraw2_max_id"
                    name="withdraw2_max"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw2_max"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="deposit3_id" class="form-control-label"
                    >ฝาก(step3)</label
                  >
                  <input
                    id="deposit3_id"
                    name="deposit3"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit3"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="credit3_id" class="form-control-label">รับ</label>
                  <input
                    id="credit3_id"
                    name="credit3"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.credit3"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="withdraw3_max_id" class="form-control-label"
                    >ถอนได้สูงสุด</label
                  >
                  <input
                    id="withdraw3_max_id"
                    name="withdraw3_max"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw3_max"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="deposit4_id" class="form-control-label"
                    >ฝาก(step4)</label
                  >
                  <input
                    id="deposit4_id"
                    name="deposit4"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit4"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="credit4_id" class="form-control-label">รับ</label>
                  <input
                    id="credit4_id"
                    name="credit4"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.credit4"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="withdraw4_max_id" class="form-control-label"
                    >ถอนได้สูงสุด</label
                  >
                  <input
                    id="withdraw4_max_id"
                    name="withdraw4_max"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw4_max"
                  />
                </div>
              </div>
            </div>
          </a>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="turnover_id" class="form-control-label"
                  >เทิร์นโอเวอร์</label
                >
                <input
                  id="turnover_id"
                  name="turnover"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.turnover"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="position_id" class="form-control-label"
                  >ลำดับที่โชว์</label
                >
                <input
                  id="position_id"
                  name="position"
                  type="number"
                  class="form-control input-group-alternative"
                  v-model="Form.position"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="pic_id" class="form-control-label">รูปภาพ</label>
                <input
                  class="form-control"
                  @change="ImgfilesChange"
                  ref="fileupload"
                  type="file"
                />
                <small class="form-text text-muted mt-2">
                  แนะนำ: ใช้รูปภาพที่มีขนาดไม่เกิน 300KB
                  เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                  กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                  <a
                    href="https://shortpixel.com/online-image-compression"
                    target="_blank"
                    rel="noopener noreferrer"
                    >แนะนำเว็บย่อขนาดรูป</a
                  >.
                </small>
              </div>
              <div class="text-center" v-if="ImgUrl != ''">
                <img :src="ImgUrl" width="350" />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              ยืนยัน
            </button>
            <base-button type="danger" @click="onClickCloseButton"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import modal from "../../../Modal.vue";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../Notification.vue";

export default {
  components: {
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      QueryId: "",
      Form: {
        statusSwitches: false,
        type: "",
        name: "",
        description: "",
        genre: "",
        deposit: "",
        deposit2: "",
        deposit3: "",
        deposit4: "",
        credit: "",
        credit2: "",
        credit3: "",
        credit4: "",
        wallet: "",
        withdraw_max: "",
        withdraw2_max: "",
        withdraw3_max: "",
        withdraw4_max: "",
        turnover: "",
        position: "",
        img: "",
      },
      ImgUrl: "",
    };
  },
  methods: {
    onSubmit() {
      if (this.Form.genre == "B") {
        if (
          this.Form.name != "" &&
          this.Form.type != "" &&
          this.Form.description != "" &&
          this.Form.genre != "" &&
          this.Form.deposit != "" &&
          this.Form.credit != "" &&
          this.Form.turnover != null &&
          this.Form.wallet != "" &&
          this.Form.withdraw_max != "" &&
          this.Form.position != ""
        ) {
          let formData = new FormData();
          formData.append("name", this.Form.name);
          formData.append("type", this.Form.type);
          formData.append("detail", this.Form.description);
          formData.append("type_promotion", this.Form.genre);
          formData.append("deposit", this.Form.deposit);
          formData.append("deposit2", this.Form.deposit2);
          formData.append("deposit3", this.Form.deposit3);
          formData.append("deposit4", this.Form.deposit4);
          formData.append("credit", this.Form.credit);
          formData.append("credit2", this.Form.credit2);
          formData.append("credit3", this.Form.credit3);
          formData.append("credit4", this.Form.credit4);
          formData.append("turnover", this.Form.turnover * this.Form.credit);
          formData.append("wallet", this.Form.wallet);
          formData.append("withdraw_max", this.Form.withdraw_max);
          formData.append("withdraw_max2", this.Form.withdraw2_max);
          formData.append("withdraw_max3", this.Form.withdraw3_max);
          formData.append("withdraw_max4", this.Form.withdraw4_max);
          formData.append("position", this.Form.position);
          formData.append("status", this.Form.statusSwitches == true ? 1 : 0);
          formData.append("picture", this.Form.img);

          HTTP.post("/admin/promotion?edit&id=" + this.QueryId, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          })
            .then((resp) => {
              if (resp.data.status != undefined && !resp.data.status) {
                this.runToast(
                  "top-right",
                  "danger",
                  resp.data.msg != undefined
                    ? resp.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              } else {
                this.runToast(
                  "top-right",
                  "success",
                  resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
                );
                this.onClickCloseButton();
              }
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 401) {
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  this.runToast(
                    "top-right",
                    "danger",
                    err.response.data.msg != undefined
                      ? err.response.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                }
              }
            });
        } else {
          this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
        }
      }
      if (this.Form.genre == "P") {
        if (
          this.Form.name != "" &&
          this.Form.type != "" &&
          this.Form.description != "" &&
          this.Form.genre != "" &&
          this.Form.deposit != "" &&
          this.Form.deposit2 != "" &&
          this.Form.deposit3 != "" &&
          this.Form.deposit4 != "" &&
          this.Form.credit != "" &&
          this.Form.credit2 != "" &&
          this.Form.credit3 != "" &&
          this.Form.credit4 != "" &&
          this.Form.turnover != null &&
          // this.Form.wallet != "" &&
          this.Form.withdraw_max != "" &&
          this.Form.withdraw2_max != "" &&
          this.Form.withdraw3_max != "" &&
          this.Form.withdraw4_max != "" &&
          this.Form.position != ""
        ) {
          let formData = new FormData();
          formData.append("name", this.Form.name);
          formData.append("type", this.Form.type);
          formData.append("detail", this.Form.description);
          formData.append("type_promotion", this.Form.genre);
          formData.append("deposit", this.Form.deposit);
          formData.append("deposit2", this.Form.deposit2);
          formData.append("deposit3", this.Form.deposit3);
          formData.append("deposit4", this.Form.deposit4);
          formData.append("credit", this.Form.credit);
          formData.append("credit2", this.Form.credit2);
          formData.append("credit3", this.Form.credit3);
          formData.append("credit4", this.Form.credit4);
          formData.append("turnover", this.Form.turnover * this.Form.credit);
          formData.append("wallet", this.Form.wallet);
          formData.append("withdraw_max", this.Form.withdraw_max);
          formData.append("withdraw_max2", this.Form.withdraw2_max);
          formData.append("withdraw_max3", this.Form.withdraw3_max);
          formData.append("withdraw_max4", this.Form.withdraw4_max);
          formData.append("position", this.Form.position);
          formData.append("status", this.Form.statusSwitches == true ? 1 : 0);
          formData.append("picture", this.Form.img);

          HTTP.post("/admin/promotion?edit&id=" + this.QueryId, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          })
            .then((resp) => {
              if (resp.data.status != undefined && !resp.data.status) {
                this.runToast(
                  "top-right",
                  "danger",
                  resp.data.msg != undefined
                    ? resp.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              } else {
                this.runToast(
                  "top-right",
                  "success",
                  resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
                );
                this.onClickCloseButton();
              }
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 401) {
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  this.runToast(
                    "top-right",
                    "danger",
                    err.response.data.msg != undefined
                      ? err.response.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                }
              }
            });
        } else {
          this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
        }
      }
    },
    ImgfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.img = event.target.files[0];
        console.log(this.Form.img);
      }
    },
    fetchPromotionData() {
      HTTP.get("/admin/promotion?id=" + this.QueryId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
            this.onClickCloseButton();
          } else {
            this.Form.statusSwitches =
              resp.data.data.status == 1 ? true : false;
            this.Form.type = resp.data.data.type;
            this.Form.name = resp.data.data.name;
            this.Form.description = resp.data.data.detail;
            this.Form.genre = resp.data.data.type_promotion;
            this.Form.deposit = resp.data.data.deposit;
            this.Form.deposit2 = resp.data.data.deposit2;
            this.Form.deposit3 = resp.data.data.deposit3;
            this.Form.deposit4 = resp.data.data.deposit4;
            this.Form.credit = resp.data.data.credit;
            this.Form.credit2 = resp.data.data.credit2;
            this.Form.credit3 = resp.data.data.credit3;
            this.Form.credit4 = resp.data.data.credit4;
            this.Form.wallet = resp.data.data.wallet;
            this.Form.withdraw_max = resp.data.data.withdraw_max;
            this.Form.withdraw2_max = resp.data.data.withdraw_max2;
            this.Form.withdraw3_max = resp.data.data.withdraw_max3;
            this.Form.withdraw4_max = resp.data.data.withdraw_max4;
            this.Form.turnover =
              resp.data.data.turnover / resp.data.data.credit;
            this.Form.position = resp.data.data.position;
            console.log(this.Form.genre);
            if (
              resp.data.data.picture != null ||
              resp.data.data.picture != ""
            ) {
              this.ImgUrl = resp.data.data.picture;
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
              this.onClickCloseButton();
            }
          }
        });
    },
    resetFormData() {
      this.Form.statusSwitches = false;
      this.Form.type = "";
      this.Form.name = "";
      this.Form.description = "";
      this.Form.deposit = "";
      this.Form.credit = "";
      this.Form.wallet = "";
      this.Form.withdraw_max = "";
      this.Form.turnover = "";
      this.Form.position = "";
      this.Form.img = "";
      this.ImgUrl = "";
    },
    onClickCloseButton() {
      this.$emit("CloseModalEditBonus", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      this.resetFormData();
      this.$refs.fileupload.value = null;
      this.showModal = val;
      if (val) {
        this.QueryId = this.id;
        this.fetchPromotionData();
      }
    },
  },
};
</script>

<style></style>
