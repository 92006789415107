<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    สถานะระบบ
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #66cc00">
                    <h5 class="h3 mb-0">ระบบอัตโนมัติ</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCBฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.scb_depstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.scb_depstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCBถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.scb_widstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.scb_widstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">KBANKฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.kbank_depstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.kbank_depstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">KBANKถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.kbank_widstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.kbank_widstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">GSBฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.gsb_depstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.gsb_depstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">GSBถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.gsb_widstatus"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.gsb_widstatus"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">TrueWallet</h4>
                          </div>
                          <div class="col-auto">
                            <badge
                              type="success"
                              v-if="dataStatus.truewallet_status"
                              >ออนไลน์</badge
                            >
                            <badge
                              type="danger"
                              v-if="!dataStatus.truewallet_status"
                              >ออฟไลน์</badge
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #009999">
                    <h5 class="h3 mb-0">ยอดเงินของระบบ</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงิน BETFLIX</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflik.betflix_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button
                            class="btn btn-warning"
                            @click="fetchbetflikData"
                          >
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #4c0099">
                    <h5 class="h3 mb-0" style="color: white">ยอดเงิน SCB</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCB ฝากบัญชี1</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb.scb_deposit_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCB ฝากบัญชี2</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb.scb_deposit_balance3
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCB ฝากบัญชี3</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb.scb_deposit_balance5
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">SCB ถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb.scb_withdraw_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button class="btn btn-warning" @click="fetchscbData">
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #009900">
                    <h5 class="h3 mb-0" style="color: white">ยอดเงิน KBANK</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">KBANK ฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank.kbank_deposit_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">KBANK ถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank.kbank_withdraw_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button
                            class="btn btn-warning"
                            @click="fetchkbankData"
                          >
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #e204d0">
                    <h5 class="h3 mb-0" style="color: white">ยอดเงิน GSB</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">GSB ฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              gsb.gsb_deposit_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">GSB ถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              gsb.gsb_withdraw_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button class="btn btn-warning" @click="fetchgsbData">
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #cc0000">
                    <h5 class="h3 mb-0" style="color: white">
                      ยอดเงิน TrueWallet
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงิน TrueWallet</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              truewallet.truewallet_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button
                            class="btn btn-warning"
                            @click="fetchtruewalletData"
                          >
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-header" style="background-color: #ccb800">
                    <h5 class="h3 mb-0" style="color: white">
                      ยอดเงิน Egateway
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงิน Egateway</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              paymentgate.paymentgate_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div
                          class="row align-items-center"
                          style="margin: auto"
                        >
                          <div class="col-auto"></div>
                          <button
                            class="btn btn-warning"
                            @click="fetchtestData"
                          >
                            request
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";

export default {
  components: {},
  data() {
    return {
      dataStatus: [],
      betflik: [],
      scb: [],
      kbank: [],
      gsb: [],
      truewallet: [],
      paymentgate: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchmonitorData();
    }
  },
  methods: {
    fetchmonitorData() {
      HTTP.get("/admin/monitor", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.dataStatus = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchbetflikData() {
      HTTP.get("/admin/monitor?betflik", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.betflik = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchscbData() {
      HTTP.get("/admin/monitor?scb", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.scb = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchtestData(){
      HTTP.get("/admin/monitor?EpayGate", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.paymentgate = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchtruewalletData() {
      HTTP.get("/admin/monitor?truewallet", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.truewallet = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchkbankData() {
      HTTP.get("/admin/monitor?kbank", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.kbank = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchgsbData() {
      HTTP.get("/admin/monitor?gsb", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.gsb = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style></style>
