<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายงานบัญชี(ตัดรอบ)
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-3">
                <base-input
                  alternative
                  name="date1"
                  label="วันที่"
                  addon-right-icon="ni ni-calendar-grid-58"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-picker
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ disableMobile: true,allowInput: true, enableTime: false }"
                      class="form-control datepicker"
                      v-model="DateStart"
                    >
                    </flat-picker>
                  </template>
                </base-input>
              </div>
              <div class="col-md-9 align-self-center">
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="fetchAllData"
                >
                  ค้นหา
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="(IsEditSummaryModal = true)"
                >
                  แก้ไข
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #4c0099">
                    <h5 class="h3 mb-0" style="color: white">Report SCB ฝาก</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_deposit.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_deposit.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_deposit.deposit
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_deposit.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_deposit.out }}</badge>
                          </div>
                        </div>
                      </li>
                      <!-- <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_deposit.fee }}</badge>
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #4c0099">
                    <h5 class="h3 mb-0" style="color: white">Report SCB ถอน</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_withdraw.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_withdraw.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              scb_withdraw.withdraw
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_withdraw.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_withdraw.out }}</badge>
                          </div>
                        </div>
                      </li>
                      <!-- <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ scb_withdraw.fee }}</badge>
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div
                    class="card-header"
                    style="background: linear-gradient(87deg, #4c0099, #009900)"
                  >
                    <h5 class="h3 mb-0" style="color: white">Reportบัญชีพัก</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              bank_reserve.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              bank_reserve.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ bank_reserve.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ bank_reserve.out }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ bank_reserve.fee }}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #009900">
                    <h5 class="h3 mb-0" style="color: white">
                      Report KBANK ฝาก
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_deposit.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_deposit.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_deposit.deposit
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ kbank_deposit.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_deposit.out
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <!-- <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_deposit.fee
                            }}</badge>
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #009900">
                    <h5 class="h3 mb-0" style="color: white">
                      Report KBANK ถอน
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.withdraw
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.in
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.out
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <!-- <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              kbank_withdraw.fee
                            }}</badge>
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card"></div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #ff0000">
                    <h5 class="h3 mb-0" style="color: white">
                      Report TrueMoneyWallet ฝาก/ถอน
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_deposit.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_deposit.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดฝาก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_deposit.deposit
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดถอน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_deposit.withdraw
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_deposit.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_deposit.out }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_deposit.fee }}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #ff0000">
                    <h5 class="h3 mb-0" style="color: white">
                      Report TrueMoneyWallet พัก
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_reserve.yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเงินวันนี้</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              tmn_reserve.daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_reserve.in }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">โยกออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_reserve.out }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ค่าใช้จ่าย</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{ tmn_reserve.tax }}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card"></div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #00b6bb">
                    <h5 class="h3 mb-0" style="color: white">
                      สรุปเครดิต Betflix
                    </h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflix.betflix_balance_yesterday
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดลบกระดาน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflix.betflix_deposit_total
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดบวกกระดาน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflix.betflix_withdraw_total
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเคลื่อนไหวเครดิต</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflix.betflix_moving_balance
                            }}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตปัจจุบัน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{
                              betflix.betflix_balance_daily
                            }}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--<div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #ff7c00">
                    <h5 class="h3 mb-0" style="color: white">เครดิตบวกระบบ</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตหลัง</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตเข้า</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
               <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #ff7c00">
                    <h5 class="h3 mb-0" style="color: white">เครดิตลบระบบ</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตหลัง</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header" style="background-color: #ff7c00">
                    <h5 class="h3 mb-0" style="color: white">สรุปเครดิตระบบ</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush list my--3">
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตก่อน</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตหลัง</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item px-0">
                        <div class="row align-items-center">
                          <div class="col-auto"></div>
                          <div class="col ml--2">
                            <h4 class="mb-0">ยอดเครดิตเข้า/ออก</h4>
                          </div>
                          <div class="col-auto">
                            <badge type="success">{{}}</badge>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-md-12">
              <base-button type="primary" onclick="window.print()">
                พิมพ์</base-button
              >
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div>
     
      <EditSummaryModal
        :show="IsEditSummaryModal"
        :dateparam="DateStart"
        @CloseModalEdit="CloseModalEdit"
      />
    </div>

</template>

<script>
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { mapGetters } from "vuex";
import { HTTP } from "@/services/http-common";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import EditSummaryModal from "./EditSummaryModal.vue"

var moment = require("moment");

export default {
  components: {
    flatPicker,
    EditSummaryModal,
  },
  data() {
    return {
      IsEditSummaryModal:false,
      betflix: [],
      tmn_reserve: [],
      tmn_deposit: [],
      kbank_deposit: [],
      kbank_withdraw: [],
      scb_deposit: [],
      scb_withdraw: [],
      bank_reserve: [],
      DateStart: moment(new Date()).format("YYYY-MM-DD HH:mm"),
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    fetchAllData() {
      HTTP.get("/admin/summary", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
        params: {
          date: this.DateStart.split(" ", 1)[0],
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.betflix = resp.data.betflix;
            this.tmn_reserve = resp.data.tmn_reserve;
            this.tmn_deposit = resp.data.tmn_deposit;
            this.kbank_deposit = resp.data.kbank_deposit;
            this.kbank_withdraw = resp.data.kbank_withdraw;
            this.scb_deposit = resp.data.scb_deposit;
            this.scb_withdraw = resp.data.scb_withdraw;
            this.bank_reserve = resp.data.bank_reserve;
            this.runToast("top-right", "success", "ค้นหาข้อมูลสำเร็จ");
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    CloseModalEdit(value) {
      this.IsEditSummaryModal = value;
      if (!value) {
        this.fetchAllData();
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
  },
};
</script>

<style></style>
