<template>
  <div>
    <modal v-model:show="showModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">เพิ่มรายการโยกเงิน</h3>
          </div>
        </template>
        <div>
          <!-- <div class="row mb-4">
            <div class="col-md-12">
              <label for="datetime" class="form-control-label"
                >วันที่ / เวลา</label
              >
              <flat-picker
                :config="{ allowInput: true, enableTime: true }"
                class="form-control datepicker input-group-alternative"
                id="datetime"
                v-model="Form.datetime"
              >
              </flat-picker>
              <input v-model="Form.datetime" v-show="false" name="datetime" />
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="fromBank" class="form-control-label">ธนาคาร</label>
                <div>
                  <select
                    class="form-control input-group-alternative"
                    id="fromBank"
                    name="fromBank"
                    v-model="Form.bank"
                  >
                    <option selected value="">-- เลือก --</option>
                    <option value="SCB">SCB</option>
                    <option value="KBANK">KBANK</option>
                    <option value="TMN">TrueMoneywallet</option>
                    <option value="FEE">ค่าใช้จ่าย</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="credit_id" class="form-control-label"
                  >ยอดเงิน</label
                >
                <input
                  id="credit_id"
                  name="credit"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.credit"
                />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" @click="onSubmit()" id="submitAdd">
              ยืนยัน
            </button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    flatPicker,
    modal,
  },
  props: { show: Boolean, phone: String },
  data() {
    return {
      showModal: false,
      typeCredit: "wallet",
      Form: {
        bank: "",
        credit: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.Form.bank != "" && this.Form.credit != "") {
        if (!isNaN(this.Form.credit)) {
          document.getElementById("submitAdd").disabled = true;
          HTTP.post(
            "/admin/reserve_log?" + this.typeCredit,
            {
              bank: this.Form.bank,
              amount: this.Form.credit,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("adb_token"),
              },
            }
          )
            .then((resp) => {
              if (resp.data.status != undefined && !resp.data.status) {
                this.runToast(
                  "top-right",
                  "danger",
                  resp.data.msg != undefined
                    ? resp.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              } else {
                this.runToast(
                  "top-right",
                  "success",
                  resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
                );
                this.onClickCloseButton();
              }
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 401) {
                  this.$router.push("/login");
                  this.$store.dispatch("logoutAuth");
                } else {
                  this.runToast(
                    "top-right",
                    "danger",
                    err.response.data.msg != undefined
                      ? err.response.data.msg
                      : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                  );
                }
              }
            });
        } else {
          this.runToast("top-right", "danger", "ยอดเงินต้องเป็นตัวเลขเท่านั้น");
        }
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalAddreserv", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetFormDate() {
      this.Form.datetime = "";
      this.Form.credit = "";
      this.Form.desc = "";
      this.Form.bank = "";
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.resetFormDate();
      this.Form.datetime = new Date();
    },
    phone: function (val) {
      this.Form.phone = val;
    },
  },
};
</script>

<style></style>
