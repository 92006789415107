<template>
  <div>
    <modal v-model:show="showModal" size="lg">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไขผู้ดูแล</h3>
          </div>
        </template>
        <div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="user_id" class="form-control-label">ยูสเซอร์</label>
                <input
                  id="user_id"
                  name="user"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.username"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="pass_id" class="form-control-label">รหัสผ่าน</label>
                <input
                  id="pass_id"
                  name="pass"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.password"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="gauth_id" class="form-control-label">Gauth</label>
                <input
                  id="gauth_id"
                  name="gauth"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.gauth"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="role_id" class="form-control-label">Role</label>
                <select
                  class="form-control input-group-alternative"
                  id="role_id"
                  name="role"
                  v-model="Form.role"
                >
                  <option selected value="">-- เลือก --</option>
                  <option
                    :value="name.id"
                    v-for="(name, index) in roleNameData"
                    :key="index"
                  >
                    {{ name.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-primary" @click="onSubmit">ยืนยัน</button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import modal from "../../Modal.vue";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      roleNameData: [],
      queryId: "",
      Form: {
        username: "",
        password: "",
        role: "",
        gauth: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.Form.username != "" && this.Form.role != "") {
        HTTP.put(
          "/admin/admin?updateAdmin&id=" + this.queryId,
          {
            username: this.Form.username,
            password: this.Form.password,
            role_id: this.Form.role,
            gauth: this.Form.gauth,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchAdminData() {
      HTTP.get("/admin/admin?updateAdmin&id=" + this.queryId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.username = resp.data.data.username;
            this.Form.role = resp.data.data.role_id;
            this.Form.gauth = resp.data.data.gauth;
            this.showModal = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchRoleName() {
      HTTP.get("/admin/admin?role", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.roleNameData = resp.data.data;
            this.fetchAdminData();
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onClickCloseButton() {
      this.$emit("CloseModalEditAdmin", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    resetForm() {
      this.Form.username = "";
      this.Form.password = "";
      this.Form.role = "";
      this.queryId = "";
      this.gauth = "";
    },
  },
  watch: {
    show: function (val) {
      this.resetForm();
      if (val) {
        this.queryId = this.id;
        this.fetchRoleName();
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
