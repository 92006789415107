<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าบัญชีออมสิน
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-12 text-center">
                <button
                  type="submit"
                  class="btn btn-info w-15"
                  @click="requestdeviceModal.show = true"
                >
                  Device ID<br />
                  request
                </button>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ฝาก</div>
                  </div>
                </div>
                <div
                  class="row text-center mt-4"
                  v-show="Role.scb_status === '1'"
                >
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Deposit.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="btn btn-warning w-15"
                    @click="onCheckAPde"
                  >
                    checkAPI
                  </button>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.number"
                        :disabled="Role.scb_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >ชื่อ - นามสกุล</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.name"
                        :disabled="Role.scb_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-show="Role.scb_pin_device === '1'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="pin_id" class="form-control-label">Pin</label>
                      <input
                        id="pin_id"
                        name="pin"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Deposit.pin"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-show="Role.scb_pin_device === '1'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="citizen_id" class="form-control-label"
                        >เลขบัตรประชาชน</label
                      >
                      <input
                        id="citizen_id"
                        name="citizen"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Deposit.citizenId"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.scb_pin_device === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onDepositSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">ถอน</div>
                  </div>
                </div>
                <div
                  class="row text-center mt-4"
                  v-show="Role.scb_status === '1'"
                >
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Withdraw.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="btn btn-warning w-15"
                    @click="onCheckAPwi"
                  >
                    checkAPI
                  </button>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.number"
                        :disabled="Role.scb_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >ชื่อ - นามสกุล</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.name"
                        :disabled="Role.scb_num_name != '1'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-show="Role.scb_pin_device === '1'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="pin_id" class="form-control-label">Pin</label>
                      <input
                        id="pin_id"
                        name="pin"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.pin"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-show="Role.scb_pin_device === '1'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="citizen_id" class="form-control-label"
                        >เลขบัตรประชาชน</label
                      >
                      <input
                        id="citizen_id"
                        name="citizen"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Withdraw.citizenId"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-if="Role.scb_pin_device === '1'">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onWithdrawSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <RequestDeviceModal
      :show="requestdeviceModal.show"
      @CloseModalAddreserv="CloseModalAddreserv"
    />
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import RequestDeviceModal from "../../../components/Autoinfi/Bank/RequestDeviceModalgsb.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  components: {
    RequestDeviceModal,
  },
  data() {
    return {
      requestdeviceModal: {
        show: false,
      },
      Deposit: {
        status: false,
        number: "",
        name: "",
        pin: "",
        deviceId: "",
        citizenId: "",
        phoneNumber: "",
      },
      Withdraw: {
        status: false,
        number: "",
        name: "",
        pin: "",
        deviceId: "",
        citizenId: "",
        phoneNumber: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchDepositData();
        this.fetchWithdrawData();
      }
    }
  },
  methods: {
    onDepositSubmit() {
      if (
        this.Deposit.number != "" &&
        this.Deposit.name != "" &&
        this.Deposit.pin != "" &&
        this.Deposit.citizenId != ""
      ) {
        HTTP.put(
          "/admin/bank_gsb?deposit",
          {
            number: this.Deposit.number,
            name: "ออมสิน",
            fullname: this.Deposit.name,
            pin: this.Deposit.pin,
            citizenId: this.Deposit.citizenId,
            status: this.Deposit.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบคววามถูกต้องของข้อมูลหรือแจ้งเจ้าหน้าที่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "บันทึกข้อมูลสำเร็จ"
              );
              this.fetchDepositData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ เข้าสู่ระบบใหม่อีกครั้ง"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onDepositdevicereq() {
      HTTP.get("/bank/gsb/deposit?Gendevice", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            this.showDepModal = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    ondepositSubmitOtp() {
      if (this.FormdOtp != "") {
        HTTP.post(
          "/bank/gsb/deposit?Subotp",
          {
            otp: this.FormdOtp
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            this.showDepModal = false;
            if (resp.data.status === true) {
              this.runToast("top-right", "success", "สำเร็จ");
              this.fetchAllData();
            } else {
              this.runToast(
                "top-right",
                "danger",
                "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onCheckAPde() {
      HTTP.get("/admin/bank_gsb?statusDeposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != 200 && resp.data.status === false) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onCheckAPwi() {
      HTTP.get("/admin/bank_gsb?statusWithdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != 200 && resp.data.status === false) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onWithdrawSubmit() {
      if (
        this.Withdraw.number != "" &&
        this.Withdraw.name != "" &&
        this.Withdraw.pin != "" &&
        this.Withdraw.citizenId != ""
      ) {
        HTTP.put(
          "/admin/bank_gsb?withdraw",
          {
            number: this.Withdraw.number,
            name: "ออมสิน",
            fullname: this.Withdraw.name,
            pin: this.Withdraw.pin,
            citizenId: this.Withdraw.citizenId,
            status: this.Withdraw.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบคววามถูกต้องของข้อมูลหรือแจ้งเจ้าหน้าที่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "บันทึกข้อมูลสำเร็จ"
              );
              this.fetchWithdrawData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onWithdrawdevicereq() {
      HTTP.get("/bank/gsb/withdraw?verify", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.message === "Successfully") {
            this.showwdModal = true;
          } else {
            this.runToast(
              "top-right",
              "danger",
              "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onwithdrawSubmitOtp() {
      if (this.FormwOtp != "") {
        HTTP.post(
          "/bank/gsb/withdraw?validateOtp",
          {
            otp: this.FormwOtp
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status === true) {
              this.runToast("top-right", "success", "สำเร็จ");
              this.showwdModal = false;
              this.fetchAllData();
            } else {
              this.runToast(
                "top-right",
                "danger",
                "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchDepositData() {
      HTTP.get("/admin/bank_gsb?deposit", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Deposit.status = resp.data.data.status === "1" ? true : false;
            this.Deposit.number = resp.data.data.number;
            this.Deposit.name = resp.data.data.fullname;
            this.Deposit.pin = resp.data.data.pin;
            this.Deposit.deviceId = resp.data.data.deviceId;
            this.Deposit.citizenId = resp.data.data.citizenId;
            this.Deposit.phoneNumber = resp.data.data.phoneNumber;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    fetchWithdrawData() {
      HTTP.get("/admin/bank_gsb?withdraw", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Withdraw.status = resp.data.data.status === "1" ? true : false;
            this.Withdraw.number = resp.data.data.number;
            this.Withdraw.name = resp.data.data.fullname;
            this.Withdraw.pin = resp.data.data.pin;
            this.Withdraw.deviceId = resp.data.data.deviceId;
            this.Withdraw.citizenId = resp.data.data.citizenId;
            this.Withdraw.phoneNumber = resp.data.data.phoneNumber;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    CloseModalAddreserv(value) {
      this.requestdeviceModal.show = value;
      this.fetchAllData();
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
