<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าเว็บไซต์
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title_id" class="form-control-label">Title*</label>
                  <input
                    id="title_id"
                    name="title"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.title"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="text-center" v-if="showImg != ''">
                  <img :src="showImg" style="width: 50%; height: auto" />
                </div>
                <div class="form-group">
                  <label for="pic_id" class="form-control-label">WEBSITE LOGO*</label>
                  <input
                    class="form-control"
                    @change="ImgfilesChange"
                    ref="fileupload"
                    type="file"
                  />
                  <small class="form-text text-muted mt-2">
                    แนะนำ: ใช้รูปภาพที่มีขนาด 1:1 หรือ (500*500)px และ ไม่เกิน 300KB
                    เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                    กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                    <a
                      href="https://shortpixel.com/online-image-compression"
                      target="_blank"
                      rel="noopener noreferrer"
                      >แนะนำเว็บย่อขนาดรูป</a
                    >.
                  </small>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="websitename_id" class="form-control-label"
                    >ชื่อเว็บไซต์*</label
                  >
                  <input
                    id="websitename_id"
                    name="websitename"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.websitename"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="color1TextId" class="form-control-label"
                    >THEME สีหลัก ของเว็บ*
                  </label>
                  <input
                    id="color1TextId"
                    name="color1"
                    type="color"
                    class="form-control input-group-alternative"
                    v-model="Form.color1"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lblAnnounceText" class="form-control-label"
                    >ประกาศเว็บไซต์</label
                  >
                  <input
                    id="lblAnnounceText"
                    name="AnnounceText"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.AnnounceText"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lineoa_id" class="form-control-label"
                    >LineOA URL*
                  </label>
                  <input
                    id="lineoa_id"
                    name="lineoa"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.lineoa"
                    :disabled="Role.website_line === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="teleg_id" class="form-control-label"
                    >Telegram URL
                  </label>
                  <input
                    id="teleg_id"
                    name="teleg"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.teleg"
                    :disabled="Role.website_line === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="gauth_id" class="form-control-label"
                    >GAuth*
                  </label>
                  <input
                    id="gauth_id"
                    name="gauth"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.gauth"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <!-- <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6 text-center">
                <label class="custom-toggle">
                  <input type="checkbox" v-model="Form.deposit_status" />
                  <span
                    class="custom-toggle-slider rounded-circle"
                    data-label-off="ปิด"
                    data-label-on="เปิด"
                  >
                  </span>
                </label>
              </div>
              <div class="col-md-3"></div>
            </div>
             <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="credit_percent_id" class="form-control-label"
                    >เปอร์เซ็นฝาก
                  </label>
                  <input
                    id="credit_percent_id"
                    name="credit_percent"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.deposit_percent"
                    :disabled="Role.website_percent_deposit === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div> -->
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6 text-center">
                <label class="custom-toggle">
                  <input type="checkbox" v-model="Form.withdraw_status" />
                  <span
                    class="custom-toggle-slider rounded-circle"
                    data-label-off="ปิด"
                    data-label-on="เปิด"
                  >
                  </span>
                </label>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="withdraw_percent_id" class="form-control-label"
                    >เปอร์เซ็นถอน
                  </label>
                  <input
                    id="withdraw_percent_id"
                    name="withdraw_percent"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdraw_percent"
                    :disabled="Role.website_percent_withdraw === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="deposit_min_id" class="form-control-label"
                    >ฝากขั้นต่ำ
                  </label>
                  <input
                    id="deposit_min_id"
                    name="deposit_min"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.DepositMin"
                    :disabled="Role.website_deposit_min === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="withdraw_min_id" class="form-control-label"
                    >ถอนขั้นต่ำ
                  </label>
                  <input
                    id="withdraw_min_id"
                    name="withdraw_min"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.WithdrawMin"
                    :disabled="Role.website_withdraw_min === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="auto_id" class="form-control-label"
                    >ถอน Auto
                  </label>
                  <input
                    id="auto_id"
                    name="auto"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.Auto"
                    :disabled="Role.website_auto === '0'"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="auto_id" class="form-control-label"
                    >ทำรายการถอน สูงสุดต่อวัน
                  </label>
                  <input
                    id="withdrawtimemax_id"
                    name="withdrawtimemax"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.withdrawtimemax"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row" v-show="Role.betflix_agent === '1'">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="betflixagent_id" class="form-control-label"
                    >Betflix Agent*
                  </label>
                  <input
                    id="betflixagent_id"
                    name="betflixagent"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.betflixAgent"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row" v-show="Role.betflix_api === '1'">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="betflixapi_id" class="form-control-label"
                    >Betflix Api*
                  </label>
                  <input
                    id="betflixapi_id"
                    name="betflixapi"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="Form.betflixApi"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row" v-show="Role.betflix_apikey === '1'">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="betflixapikey_id" class="form-control-label"
                    >Betflix Apikey*
                  </label>
                  <input
                    id="betflixapikey_id"
                    name="betflixapikey"
                    type="password"
                    class="form-control input-group-alternative"
                    v-model="Form.betflixKey"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="text-center" v-if="showImgBanner != ''">
                  <img :src="showImgBanner" style="width: 50%; height: auto" />
                </div>
                <div class="form-group">
                  <label for="pic_id" class="form-control-label"
                    >รูปภาพ banner(V.2/PC MODE)</label
                  >
                  <input
                    class="form-control"
                    @change="ImgBannerfilesChange"
                    ref="fileupload"
                    type="file"
                  />
                  <small class="form-text text-muted mt-2">
                    แนะนำ: ใช้รูปภาพที่มีขนาด (800*637)px และ ไฟล์ PNG ไม่เกิน 880KB
                    เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                    กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                    <a
                      href="https://shortpixel.com/online-image-compression"
                      target="_blank"
                      rel="noopener noreferrer"
                      >แนะนำเว็บย่อขนาดรูป</a
                    >.
                  </small>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="text-center" v-if="showImgBGPic != ''">
                  <img :src="showImgBGPic" style="width: 50%; height: auto" />
                </div>
                <div class="form-group">
                  <label for="pic_id" class="form-control-label"
                    >รูปภาพ background</label
                  >
                  <input
                    class="form-control"
                    @change="ImgBGfilesChange"
                    ref="fileupload"
                    type="file"
                  />
                  <small class="form-text text-muted mt-2">
                    แนะนำ: ใช้รูปภาพที่มีขนาด (1920*940)px และ ไม่เกิน 500KB
                    เพื่อทำให้การเข้าถึงเว็บของลูกค้าโหลดเร็วขึ้น.
                    กรุณาย่อขนาดไฟล์ภาพทุกครั้งก่อนอัพโหลดได้ที่ >>
                    <a
                      href="https://shortpixel.com/online-image-compression"
                      target="_blank"
                      rel="noopener noreferrer"
                      >แนะนำเว็บย่อขนาดรูป</a
                    >.
                  </small>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="URLAFFTextId" class="form-control-label"
                    >URL Affiliate*
                  </label>
                  <input
                    id="URLAFFTextId"
                    name="URLAFFTextId"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="Form.url_affiliate"
                  />
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary" @click="onSubmit">
                ยืนยัน
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import { HTTP } from "@/services/http-common";

export default {
  components: {
  },
  data() {
    return {
      Form: {
        title: "",
        websitename: "",
        lineoa: "",
        teleg: "",
        gauth: "",
        deposit_status: false,
        deposit_percent: "",
        withdraw_status: false,
        withdraw_percent: "",
        betflixAgent: "",
        betflixApi: "",
        betflixKey: "",
        img: "",
        DepositMin: "",
        WithdrawMin: "",
        Auto: "",
        withdrawtimemax: "",
        AnnounceText: "",
        banner: "",
        bgpicture: "",
        color1: "",
        url_affiliate: "",
      },
      showImg: "",
      showImgBanner: "",
      showImgBGPic: "",
      originalBanner: "",
      originalBGPicture: "",
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.website != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    onSubmit() {
      if (
        this.Form.title != "" &&
        this.Form.websitename != "" &&
        this.Form.lineoa != "" &&
        this.Form.gauth != "" &&
        this.Form.deposit_percent != "" &&
        this.Form.withdraw_percent != "" &&
        this.Form.betflixAgent != "" &&
        this.Form.betflixApi != "" &&
        this.Form.betflixKey != "" &&
        this.Form.DepositMin != "" &&
        this.Form.WithdrawMin != "" &&
        this.Form.Auto != "" &&
        this.Form.withdrawtimemax != ""
      ) {
        let formData = new FormData();
        formData.append("website_name", this.Form.websitename);
        formData.append("website_logo", this.Form.img);
        formData.append("banner", this.Form.banner);
        formData.append("bgpicture", this.Form.bgpicture);
        formData.append("originalBanner", this.originalBanner);
        formData.append("originalBGPicture", this.originalBGPicture);

        formData.append("website_title", this.Form.title);
        formData.append("website_lineID", this.Form.lineoa);
        formData.append("website_telegID", this.Form.teleg);
        formData.append("website_gauth", this.Form.gauth);
        formData.append("deposit_percent", this.Form.deposit_percent);
        formData.append("deposit_status", this.Form.deposit_status ? 1 : 0);
        formData.append("withdraw_percent", this.Form.withdraw_percent);
        formData.append("withdraw_status", this.Form.withdraw_status ? 1 : 0);
        formData.append("betflix_agent", this.Form.betflixAgent);
        formData.append("betflix_api", this.Form.betflixApi);
        formData.append("betflix_apikey", this.Form.betflixKey);

        formData.append("deposit_min", this.Form.DepositMin);
        formData.append("withdraw_min", this.Form.WithdrawMin);
        formData.append("withdraw_auto", this.Form.Auto);
        formData.append("withdrawtimemax", this.Form.withdrawtimemax);
        if (this.Form.AnnounceText != "") {
          formData.append("website_annoucetext", this.Form.AnnounceText);
        }
        if (this.Form.color1 != "") {
          formData.append("color1", this.Form.color1);
        }
        if (this.Form.url_affiliate != "") {
          formData.append("url_affiliate", this.Form.url_affiliate);
        }

        HTTP.post("/admin/website", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        })
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.fetchAllData();
              this.$refs.fileupload.value = null;
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push({
                  path: "/login",
                });
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchAllData() {
      HTTP.get("/admin/website", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.title = resp.data.data.website_title;
            this.Form.websitename = resp.data.data.website_name;
            this.Form.lineoa = resp.data.data.website_lineID;
            this.Form.teleg = resp.data.data.website_telegID;
            this.Form.gauth = resp.data.data.website_gauth;
            this.Form.deposit_status =
              resp.data.data.deposit_status == 1 ? true : false;
            this.Form.deposit_percent = resp.data.data.deposit_percent;
            this.Form.withdraw_status =
              resp.data.data.withdraw_status == 1 ? true : false;
            this.Form.withdraw_percent = resp.data.data.withdraw_percent;
            this.Form.betflixAgent = resp.data.data.betflix_agent;
            this.Form.betflixApi = resp.data.data.betflix_api;
            this.Form.betflixKey = resp.data.data.betflix_apikey;
            this.Form.DepositMin = resp.data.data.deposit_min;
            this.Form.WithdrawMin = resp.data.data.withdraw_min;
            this.Form.Auto = resp.data.data.withdraw_auto;
            this.Form.withdrawtimemax = resp.data.data.withdrawtimemax;
            this.Form.AnnounceText = resp.data.data.website_annoucetext;
            this.originalBanner = resp.data.data.banner;
            this.originalBGPicture = resp.data.data.bgpicture;
            this.Form.color1 = resp.data.data.color1;
            this.Form.url_affiliate = resp.data.data.url_affiliate;
            if (
              resp.data.data.website_logo != "" &&
              resp.data.data.website_logo != null
            ) {
              this.showImg = resp.data.data.website_logo;
            } else {
              this.showImg = "";
            }
            if (resp.data.data.banner != "" && resp.data.data.banner != null) {
              this.showImgBanner = resp.data.data.banner;
            } else {
              this.showImgBanner = "";
            }
            if (
              resp.data.data.bgpicture != "" &&
              resp.data.data.bgpicture != null
            ) {
              this.showImgBGPic = resp.data.data.bgpicture;
            } else {
              this.showImgBGPic = "";
            }

            this.$refs.fileupload.value = null;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    ImgfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.img = event.target.files[0];
        console.log(this.Form.img);
      }
    },
    ImgBannerfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.banner = event.target.files[0];
      }
    },
    ImgBGfilesChange(event) {
      if (event.target.files[0] != undefined) {
        this.Form.bgpicture = event.target.files[0];
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Role"]),
    ...mapGetters(["Auth"]),
  },
};
</script>

<style>
input[type="color"] {
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
