<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    ตั้งค่าบัญชีทรูมันนี่วอลเล็ท
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-12">
                <div
                  class="row text-center mt-4"
                  v-show="Role.tw_status === '1'"
                >
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="Form.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    class="btn btn-warning w-15"
                    @click="onCheckAPI"
                  >
                    checkAPI
                  </button>
                </div>
                <div class="row" v-show="Role.tw_pw_pin === '1'">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="banknumber_id" class="form-control-label"
                        >เลขบัญชี</label
                      >
                      <input
                        id="banknumber_id"
                        name="banknumber"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.number"
                        :disabled="Role.tw_num_name != '1'"
                      />
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fname_id" class="form-control-label"
                        >ชื่อ - นามสกุล</label
                      >
                      <input
                        id="fname_id"
                        name="fname"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.name"
                        :disabled="Role.tw_num_name != '1'"
                      />
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row" v-show="Role.tw_pw_pin === '1'">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="password_id" class="form-control-label"
                        >Password</label
                      >
                      <input
                        id="password_id"
                        name="password"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Form.password"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="pin_id" class="form-control-label">Pin</label>
                      <input
                        id="pin_id"
                        name="pin"
                        type="password"
                        class="form-control input-group-alternative"
                        v-model="Form.pin"
                      />
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
                <div class="row" v-show="Role.tw_pw_pin === '1'">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="key_id" class="form-control-label"
                        >Keyid</label
                      >
                      <input
                        id="key_id"
                        name="key"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.key"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="Logintoken_id" class="form-control-label"
                        >Logintoken</label
                      >
                      <input
                        id="Logintoken_id"
                        name="Logintoken"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.logintoken"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="TMN_id" class="form-control-label"
                        >TMNid</label
                      >
                      <input
                        id="TMN_id"
                        name="TMNid"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="Form.tmnid"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 text-center mb-3">
                    <button
                      type="submit"
                      class="btn btn-warning w-15"
                      @click="getOTP"
                    >
                      ขอ OTP
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onSubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <modal v-model:show="showModal" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">OTP</h3>
            </div>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="ref_id" class="form-control-label">REF.</label>
                  <input
                    id="ref_id"
                    name="ref"
                    type="text"
                    class="form-control input-group-alternative"
                    disabled
                    v-model="FormOtp.ref"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="otp_id" class="form-control-label">OTP</label>
                  <input
                    id="otp_id"
                    name="otp"
                    type="text"
                    class="form-control input-group-alternative"
                    v-model="FormOtp.otp"
                  />
                </div>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-primary" @click="onSubmitOtp">
                ยืนยัน
              </button>
              <base-button type="danger" @click="showModal = false"
                >ปิด</base-button
              >
            </div>
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";
import modal from "../../../components/Modal.vue";

export default {
  components: {
    modal,
  },
  data() {
    return {
      showModal: false,
      FormOtp: {
        ref: "",
        otp: "",
      },
      Form: {
        status: false,
        number: "",
        name: "",
        password: "",
        pin: "",
        key: "",
        logintoken: "",
        tmnid: "",
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchAllData();
      }
    }
  },
  methods: {
    // onSubmitOtp() {
    //   if (this.FormOtp.otp != "") {
    //     HTTP.post(
    //       "/bank/truewallet/truewallet?SubmitLoginOTP",
    //       {
    //         otp_mobile: this.FormOtp.otp,
    //         otp_reference: this.FormOtp.ref,
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("adb_token"),
    //         },
    //       }
    //     )
    //       .then((resp) => {
    //         if (resp.data.code === "MAS-200") {
    //           this.runToast("top-right", "success", "สำเร็จ");
    //           this.showModal = false;
    //           this.fetchAllData();
    //         } else {
    //           this.runToast(
    //             "top-right",
    //             "danger",
    //             "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
    //           );
    //         }
    //       })
    //       .catch((err) => {
    //         if (err.response) {
    //           if (err.response.status === 401) {
    //             this.$router.push("/login");
    //             this.$store.dispatch("logoutAuth");
    //           } else {
    //             this.runToast(
    //               "top-right",
    //               "danger",
    //               err.response.data.msg != undefined
    //                 ? err.response.data.msg
    //                 : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
    //             );
    //           }
    //         }
    //       });
    //   } else {
    //     this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
    //   }
    // },
    // getOTP() {
    //   HTTP.get("/bank/truewallet/truewallet?DraftTransferbank", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("adb_token"),
    //     },
    //   })
    //     .then((resp) => {
    //       if (resp.data.code === "MAS-200") {
    //         this.FormOtp.ref = resp.data.data.otp_reference;
    //         this.showModal = true;
    //       } else {
    //         this.runToast(
    //           "top-right",
    //           "danger",
    //           "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
    //         );
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         if (err.response.status === 401) {
    //           this.$router.push("/login");
    //           this.$store.dispatch("logoutAuth");
    //         } else {
    //           this.runToast(
    //             "top-right",
    //             "danger",
    //             err.response.data.msg != undefined
    //               ? err.response.data.msg
    //               : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
    //           );
    //         }
    //       }
    //     });
    // },
    onSubmit() {
      if (
        this.Form.number != "" &&
        this.Form.name != "" &&
        this.Form.password != "" &&
        this.Form.pin != "" &&
        this.Form.key != "" &&
        this.Form.logintoken != "" &&
        this.Form.tmnid != ""
      ) {
        HTTP.put(
          "/admin/bank_truewallet",
          {
            number: this.Form.number,
            name: "ทรูมันนี่วอลเล็ท",
            fullname: this.Form.name,
            password: this.Form.password,
            pin: this.Form.pin,
            keyid: this.Form.key,
            Ltoken: this.Form.logintoken,
            tmnid: this.Form.tmnid,
            status: this.Form.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchAllData();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchAllData() {
      HTTP.get("/admin/bank_truewallet", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Form.status = resp.data.data.status === "1" ? true : false;
            this.Form.number = resp.data.data.number;
            this.Form.name = resp.data.data.fullname;
            this.Form.password = resp.data.data.password;
            this.Form.pin = resp.data.data.pin;
            this.Form.key = resp.data.data.keyid;
            this.Form.logintoken = resp.data.data.Ltoken;
            this.Form.tmnid = resp.data.data.tmnid;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    onCheckAPI() {
      HTTP.get("/admin/bank_truewallet?status", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.runToast(
              "top-right",
              "success",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
