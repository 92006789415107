<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการเดินบัญชี ออมสิน GSB
                  </h2>
                </div>
              </div>
            </template>

            <div>
              <card
                header-classes="bg-primary"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    บัญชีฝาก {{ CurcountCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.transactionDate).format(
                              "YYYY-MM-DD"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เวลา(HHMMSS)"
                      >
                        <template v-slot:default="props">
                          {{ props.row.transactionTime }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="เลขบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="ชื่อบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.subDescription }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
              <card
                header-classes="bg-orange"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-0" style="color: white">
                    บัญชีถอน {{ CurcountCount1 }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      :data="queriedData1"
                      row-key="id"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่"
                      >
                        <template v-slot:default="props">
                          {{
                            moment(props.row.transactionDate).format(
                              "YYYY-MM-DD"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="เวลา(HHMMSS)"
                      >
                        <template v-slot:default="props">
                          {{ props.row.transactionTime }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="description"
                      >
                        <template v-slot:default="props">
                          {{ props.row.description }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="เลขบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.accountNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="ชื่อบัญชี"
                      >
                        <template v-slot:default="props">
                          {{ props.row.subDescription }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="txnAmount"
                      >
                        <template v-slot:default="props">
                          {{ props.row.amount }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div class="row"></div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import moment from "moment";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      CurcountCount: "0",
      CurcountCount1: "0",
      DepositData: [],
      WithdrawData: [],
      fuseSearch: null,
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchDeposit();
      this.fetchWithdraw();
    }
  },
  methods: {
    moment: function (row) {
      return moment(row);
    },
    fetchDeposit() {
      HTTP.get("/bank/gsb/deposit?getTimeline", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.msg) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.DepositData = resp.data.msg;
            this.CurcountCount = this.DepositData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    fetchWithdraw() {
      HTTP.get("/bank/gsb/withdraw?getTimeline", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (!resp.data.msg) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.WithdrawData = resp.data.msg;
            this.CurcountCount1 = this.WithdrawData.length;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    pagedData() {
      return this.DepositData.slice(this.from, this.to);
    },
    pagedData1() {
      return this.WithdrawData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.DepositData.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    formattedTransactionTime() {
      const transactionTime = this.props.row.transactionTime;
      if (!transactionTime) return "";
      return `${transactionTime.slice(0, 2)}:${transactionTime.slice(
        2,
        4
      )}:${transactionTime.slice(4, 6)}`;
    },
    queriedData1() {
      if (!this.searchQuery) {
        return this.pagedData1;
      }
      let result = this.WithdrawData.filter((row) => {
        let isIncluded = false;
        let rowValue = row[key].toString();
        if (rowValue.includes && rowValue.includes(this.searchQuery)) {
          isIncluded = true;
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
  },
};
</script>

<style></style>
