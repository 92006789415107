<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-primary" type="secondary">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    แจ้งยอดชำระทุกเว็บ
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpaySubmit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay2.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay2.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay2.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay2.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay2.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay2.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay2.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpay2Submit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay3.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay3.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay3.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay3.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay3.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay3.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay3.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpay3Submit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay4.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay4.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay4.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay4.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay4.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay4.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay4.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpay4Submit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay5.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay5.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay5.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay5.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay5.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay5.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay5.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpay5Submit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center font-weight-bold">
                      {{ this.Pay6.name }}
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-md-12">
                    <label class="custom-toggle">
                      <input type="checkbox" v-model="this.Pay6.status" />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="ปิด"
                        data-label-on="เปิด"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="systemfee_id" class="form-control-label"
                        >ค่าบริการระบบ(บาท)</label
                      >
                      <input
                        id="systemfee_id"
                        name="systemfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay6.sysFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="proxyfee_id" class="form-control-label"
                        >ค่าproxy+Decryptธนาคาร(บาท)</label
                      >
                      <input
                        id="proxyfee_id"
                        name="proxyfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay6.proxFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="creditfee_id" class="form-control-label"
                        >ค่าเครดิตกระดาน(บาท)</label
                      >
                      <input
                        id="creditfee_id"
                        name="creditfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay6.creditFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="etcfee_id" class="form-control-label"
                        >ค่าบริการอื่นๆ(บาท)</label
                      >
                      <input
                        id="etcfee_id"
                        name="etcfee"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay6.etcFee"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="discount_id" class="form-control-label"
                        >ส่วนลด(บาท)</label
                      >
                      <input
                        id="discount_id"
                        name="discount"
                        type="text"
                        class="form-control input-group-alternative"
                        v-model="this.Pay6.discounT"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-50"
                      @click="onpay6Submit"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import { useToast } from "vue-toastification";
import Notification from "../../../components/Notification.vue";
import "sweetalert2/dist/sweetalert2.css";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      Pay: [],
      Pay2: [],
      Pay3: [],
      Pay4: [],
      Pay5: [],
      Pay6: [],
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      if (this.Role.bank != "1") {
        this.$router.push("/dashboard");
      } else {
        this.fetchvaulepay();
      }
    }
  },
  methods: {
    fetchvaulepay() {
      HTTP.get("/admin/payservice?vaulepaY", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
            );
          } else {
            this.Pay = resp.data.data;
            this.Pay.status = resp.data.data.status === "1" ? true : false;
            this.Pay2 = resp.data.data2;
            this.Pay2.status = resp.data.data2.status === "1" ? true : false;
            this.Pay3 = resp.data.data3;
            this.Pay3.status = resp.data.data3.status === "1" ? true : false;
            this.Pay4 = resp.data.data4;
            this.Pay4.status = resp.data.data4.status === "1" ? true : false;
            this.Pay5 = resp.data.data5;
            this.Pay5.status = resp.data.data5.status === "1" ? true : false;
            this.Pay6 = resp.data.data6;
            this.Pay6.status = resp.data.data6.status === "1" ? true : false;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push({
                path: "/login",
              });
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              if (err.response.data != "") {
                this.runToast("top-right", "danger", err.response.data.msg);
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  "ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่"
                );
              }
            }
          }
        });
    },
    onpaySubmit() {
      if (
        this.Pay.sysFee != "" &&
        this.Pay.proxFee != "" &&
        this.Pay.creditFee != "" &&
        this.Pay.etcFee != "" &&
        this.Pay.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay1",
          {
            sys: this.Pay.sysFee,
            prox: this.Pay.proxFee,
            creditf: this.Pay.creditFee,
            etcf: this.Pay.etcFee,
            disco: this.Pay.discounT,
            stat: this.Pay.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onpay2Submit() {
      if (
        this.Pay2.sysFee != "" &&
        this.Pay2.proxFee != "" &&
        this.Pay2.creditFee != "" &&
        this.Pay2.etcFee != "" &&
        this.Pay2.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay2",
          {
            sys: this.Pay2.sysFee,
            prox: this.Pay2.proxFee,
            creditf: this.Pay2.creditFee,
            etcf: this.Pay2.etcFee,
            disco: this.Pay2.discounT,
            stat: this.Pay2.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onpay3Submit() {
      if (
        this.Pay3.sysFee != "" &&
        this.Pay3.proxFee != "" &&
        this.Pay3.creditFee != "" &&
        this.Pay3.etcFee != "" &&
        this.Pay3.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay3",
          {
            sys: this.Pay3.sysFee,
            prox: this.Pay3.proxFee,
            creditf: this.Pay3.creditFee,
            etcf: this.Pay3.etcFee,
            disco: this.Pay3.discounT,
            stat: this.Pay3.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onpay4Submit() {
      if (
        this.Pay4.sysFee != "" &&
        this.Pay4.proxFee != "" &&
        this.Pay4.creditFee != "" &&
        this.Pay4.etcFee != "" &&
        this.Pay4.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay4",
          {
            sys: this.Pay4.sysFee,
            prox: this.Pay4.proxFee,
            creditf: this.Pay4.creditFee,
            etcf: this.Pay4.etcFee,
            disco: this.Pay4.discounT,
            stat: this.Pay4.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onpay5Submit() {
      if (
        this.Pay5.sysFee != "" &&
        this.Pay5.proxFee != "" &&
        this.Pay5.creditFee != "" &&
        this.Pay5.etcFee != "" &&
        this.Pay5.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay5",
          {
            sys: this.Pay5.sysFee,
            prox: this.Pay5.proxFee,
            creditf: this.Pay5.creditFee,
            etcf: this.Pay5.etcFee,
            disco: this.Pay5.discounT,
            stat: this.Pay5.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    onpay6Submit() {
      if (
        this.Pay6.sysFee != "" &&
        this.Pay6.proxFee != "" &&
        this.Pay6.creditFee != "" &&
        this.Pay6.etcFee != "" &&
        this.Pay6.discounT != ""
      ) {
        HTTP.post(
          "/admin/payservice?Pay6",
          {
            sys: this.Pay6.sysFee,
            prox: this.Pay6.proxFee,
            creditf: this.Pay6.creditFee,
            etcf: this.Pay6.etcFee,
            disco: this.Pay6.discounT,
            stat: this.Pay6.status === true ? "1" : "0",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "สำเร็จ"
              );
              this.fetchvaulepay();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),
  },
};
</script>

<style></style>
