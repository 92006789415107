<template>
  <div class="content">
    <base-header>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="สมาชิกเดือนนี้"
            type="gradient-green"
            :sub-title="Log.member_monthly"
            icon="fa fa-users"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="สมาชิกใหม่วันนี้"
            type="gradient-red"
            :sub-title="Log.member_daily"
            icon="fa fa-user-plus"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="สมาชิกที่เคลื่อนไหววันนี้"
            type="gradient-info"
            :sub-title="Log.member_login"
            icon="fa fa-child"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="สมาชิกที่มีรายการฝากวันนี้"
            type="gradient-orange"
            :sub-title="Log.deposit_daily"
            icon="fa fa-coins"
          >
          </stats-card>
        </div>
      </div>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 mb-8 mb-xl-0">
          <card header-classes="bg-default">
            <template v-slot:header>
              <div class="row align-items-center text-center">
                <div class="col py-1">
                  <h2 class="text-uppercase ls-1 mb-1" style="color: white">
                    รายการสมาชิก
                  </h2>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="search" class="form-control-label">ค้นหา</label>
                  <input
                    type="search"
                    v-model="searchQuery"
                    id="search"
                    class="form-control input-group-alternative"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>

            <div>
              <card
                header-classes="bg-warning"
                class="no-border-card"
                body-classes="px-0 pb-1"
                footer-classes="pb-2"
              >
                <template v-slot:header>
                  <h3 class="mb-01" style="color: white">
                    สมาชิกทั้งหมด {{ MemberCount }} รายการ
                  </h3>
                </template>
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-table
                      v-loading="loading"
                      :data="queriedData"
                      :default-sort="{ prop: 'credit', order: 'descending' }"
                      row-key="id"
                      header-row-class-name="thead-light"
                      Boolean="true"
                    >
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="สมาชิก"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-user text-primary mr-2"></i
                          >{{ props.row.fname }} {{ props.row.lname }}
                          <br />
                          <i class="fa fa-mobile-alt text-dark mr-2"></i>
                          {{ props.row.phone }}
                          <br />
                          <i class="fa fa-lock text-danger mr-2"></i>
                          {{ props.row.password }}
                          <br />
                          <i class="fab fa-line text-success mr-2"></i>
                          {{ props.row.line_id }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="ยูสเซอร์ BETFLIX"
                      >
                        <template v-slot:default="props">
                          <i class="fa fa-gamepad text-info mr-1"></i>
                          {{ props.row.user_betflix }}
                          <br />
                          <i class="fa fa-lock text-info mr-1"></i>
                          {{ props.row.password_betflix }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="180px"
                        align="center"
                        label="ธนาคาร"
                      >
                        <template v-slot:default="props">
                          <img
                            :src="checkBankImg(props.row.bank_name)"
                            width="30"
                            alt=""
                            srcset=""
                            class="mr-1"
                          />
                          {{ props.row.bank_name }}
                          <div v-if="props.row.bank_number != ''">
                            {{ props.row.bank_number }}
                          </div>
                          <div v-if="props.row.tmn_id != ''">
                            วอลเล็ท ID : {{ props.row.tmn_id }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="เครดิต"
                        prop="credit"
                        sortable
                        :sort-method="test"
                      >
                        <template v-slot:default="props">
                          กระเป๋า : {{ checkCredit(props.row.credit) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="เทิร์น / วงล้อ"
                        prop="game_token"
                        sortable
                      >
                        <template v-slot:default="props">
                          เทิร์น : {{ checkCredit(props.row.turnover) }}
                          <br />
                          วงล้อ : {{ props.row.game_token }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="150px"
                        align="center"
                        label="วันที่"
                        prop="login_at"
                        sortable
                      >
                        <template v-slot:default="props">
                          สมัคร : {{ props.row.create_at }}
                          <br />
                          เข้าล่าสุด : {{ props.row.login_at }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="แก้ไขเครดิต"
                        v-if="Role.member_credit === '1'"
                      >
                        <template v-slot:default="props">
                          <div>
                            <base-button
                              round
                              size="sm"
                              type="success"
                              @click="
                                (addcreditModal.show = true),
                                  (addcreditModal.phone = props.row.phone)
                              "
                              ><i class="fa fa-plus"></i
                            ></base-button>
                            <base-button
                              round
                              size="sm"
                              type="danger"
                              @click="
                                (deletecreditModal.show = true),
                                  (deletecreditModal.phone = props.row.phone)
                              "
                              ><i class="fa fa-minus"></i
                            ></base-button>
                          </div>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column
                        min-width="120px"
                        align="center"
                        label="ปรับสถานะ"
                      >
                        <template v-slot:default="props">
                          <div>
                            <base-button
                              round
                              size="lg"
                              type="danger"
                              @click="
                                (modals.userStatus = true),
                                  (modals.userStatusGameId =
                                    props.row.game_user),
                                  (modals.userStatusId = props.row.user_id),
                                  (modals.userStatusStatus =
                                    props.row.user_status)
                              "
                              ><i class="fa fa-times"></i
                            ></base-button>
                          </div>
                        </template>
                      </el-table-column> -->
                      <el-table-column
                        min-width="120px"
                        align="center"
                        label="แก้ไขข้อมูล"
                        v-if="Role.member_edit === '1'"
                      >
                        <template v-slot:default="props">
                          <div>
                            <base-button
                              round
                              size="lg"
                              type="default"
                              @click="
                                (edituserModal.show = true),
                                  (edituserModal.id = props.row.id)
                              "
                              ><i class="fa fa-pen-square"></i
                            ></base-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <template v-slot:footer>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        แสดง {{ from + 1 }} ถึง {{ to }} จากทั้งหมด
                        {{ total }} รายการ
                      </p>
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div>
      <AddCreditModal
        :show="addcreditModal.show"
        :phone="addcreditModal.phone"
        @CloseModalAddcredit="CloseModalAddcredit"
      />
      <DeleteCreditModal
        :show="deletecreditModal.show"
        :phone="deletecreditModal.phone"
        @CloseModalDeletecredit="CloseModalDeletecredit"
      />
      <EditUserModal
        :show="edituserModal.show"
        :id="edituserModal.id"
        @CloseModalEditUser="CloseModalEditUser"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import { HTTP } from "@/services/http-common";
import AddCreditModal from "../../../components/Autoinfi/Member/AddCreditModal.vue";
import DeleteCreditModal from "../../../components/Autoinfi/Member/DeleteCreditModal.vue";
import EditUserModal from "../../../components/Autoinfi/Member/EditUserModal.vue";

export default {
  components: {
    AddCreditModal,
    DeleteCreditModal,
    EditUserModal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      sortKey: "",
      reverse: false,
      addcreditModal: {
        show: false,
        phone: "",
      },
      deletecreditModal: {
        show: false,
        phone: "",
      },
      edituserModal: {
        show: false,
        id: "",
      },
      MemberCount: "",
      Log: [],
      DepositData: {
        phone: "",
      },
      loading: false,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 1000, 2000, 5000],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "phone",
        "fname",
        "lname",
        "bank_number",
        "bank_name",
        "tmn_id",
        "user_betflix",
      ],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      BankImg: {
        bbl: require("../../../assets/images/bank/bangkok.svg"),
        kbank: require("../../../assets/images/bank/kbank.svg"),
        ktb: require("../../../assets/images/bank/krungthai.svg"),
        tmb: require("../../../assets/images/bank/tmb.svg"),
        scb: require("../../../assets/images/bank/scb.svg"),
        bay: require("../../../assets/images/bank/krungsri.svg"),
        gsb: require("../../../assets/images/bank/aomsin.svg"),
        baac: require("../../../assets/images/bank/baac.svg"),
        tbank: require("../../../assets/images/bank/tnc.svg"),
        ghb: require("../../../assets/images/bank/ghb.png"),
        scbt: require("../../../assets/images/bank/scbt.png"),
        ttb: require("../../../assets/images/bank/ttb.png"),
        city: require("../../../assets/images/bank/city.png"),
        cimb: require("../../../assets/images/bank/cimb.png"),
        uob: require("../../../assets/images/bank/uob.png"),
        islamic: require("../../../assets/images/bank/islamic.png"),
        tisco: require("../../../assets/images/bank/tisco.png"),
        kkp: require("../../../assets/images/bank/kkp.png"),
        icbc: require("../../../assets/images/bank/icbc.png"),
        thaicredit: require("../../../assets/images/bank/thaicredit.png"),
        lh: require("../../../assets/images/bank/lh.svg"),
        truewallet: require("../../../assets/images/bank/truewallet.png"),
      },
    };
  },
  created() {
    if (!this.Auth) {
      this.$router.push("/login");
    } else {
      this.fetchAllData();
    }
  },
  methods: {
    test: function (a, b) {
      console.log(a);
      console.log(b);
      return a.credit - b.credit;
    },
    toFloat: function (num) {
      return parseFloat(num.replace(".", "").replace(",", "."));
    },
    fetchAllData() {
      this.loading = true;
      HTTP.get("/admin/member", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status === true) {
            this.loading = false;
            this.tableData = resp.data.data;
            this.MemberCount = this.tableData.length;
            this.Log = resp.data.log;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    checkCredit(credit) {
      parseFloat(credit);
      if (credit === null) {
        return (Math.round(0 * 100) / 100).toFixed(2);
      } else {
        return (Math.round(parseFloat(credit) * 100) / 100).toFixed(2);
      }
    },
    checkBankImg(bank) {
      if (bank === "กรุงเทพ") return this.BankImg.bbl;
      if (bank === "กสิกรไทย") return this.BankImg.kbank;
      if (bank === "กรุงไทย") return this.BankImg.ktb;
      if (bank === "ทหารไทยธนชาต") return this.BankImg.tmb;
      if (bank === "ไทยพาณิชย์") return this.BankImg.scb;
      if (bank === "ธกส") return this.BankImg.baac;
      if (bank === "กรุงศรีฯ") return this.BankImg.bay;
      if (bank === "ออมสิน") return this.BankImg.gsb;
      if (bank === "ธอส") return this.BankImg.ghb;
      if (bank === "แสตนดาร์ดชาร์เตอร์") return this.BankImg.scbt;
      if (bank === "ซิตี้แบงค์") return this.BankImg.city;
      if (bank === "ซีไอเอ็มบีไทย") return this.BankImg.cimb;
      if (bank === "ยูโอบี") return this.BankImg.uob;
      if (bank === "อิสลาม") return this.BankImg.islamic;
      if (bank === "ทิสโก้") return this.BankImg.tisco;
      if (bank === "เกียรตินาคินภัทร") return this.BankImg.kkp;
      if (bank === "ไอซีบีซี") return this.BankImg.icbc;
      if (bank === "ไทยเครดิต") return this.BankImg.thaicredit;
      if (bank === "LHBANK") return this.BankImg.lh;
      if (bank === "truewallet") return this.BankImg.truewallet;
    },
    CloseModalAddcredit(value) {
      this.addcreditModal.show = value;
      this.fetchAllData();
    },
    CloseModalDeletecredit(value) {
      this.deletecreditModal.show = value;
      this.fetchAllData();
    },
    CloseModalEditUser(value) {
      this.edituserModal.show = value;
      this.fetchAllData();
    },
  },
  computed: {
    ...mapGetters(["Auth"]),
    ...mapGetters(["Role"]),

    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
};
</script>

<style></style>
