import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout";
import AuthLayout from "../views/Layout/AuthLayout.vue";

// Pages
import Login from "../views/Pages/Login.vue";
// Dashboard
import Dashboard from "../views/Pages/Dashboard/Dashboard.vue";
// Member
import Member from "../views/Pages/Member/Member.vue";
import Rank from "../views/Pages/Member/Rank.vue";
import Report from "../views/Pages/Member/Report.vue";
// Edit
import Edit from "../views/Pages/Edit/Edit.vue";
// Wallet
import Deposit from "../views/Pages/Wallet/Deposit.vue";
import Withdraw from "../views/Pages/Wallet/Withdraw.vue";
import Transfer from "../views/Pages/Wallet/Transfer.vue";
import NotiWithdraw from "../views/Pages/Wallet/NotiWithdraw.vue";
// Events
import Eventsetting from "../views/Pages/Events/Eventsetting.vue";
import Notifaceshare from "../views/Pages/Events/Notifaceshare.vue";
// Website
import Website from "../views/Pages/Website/Website.vue";
// Bank
import Scb from "../views/Pages/Bank/Scb.vue";
import Gatewaypayment from "../views/Pages/Bank/Gatewaypayment.vue";
import Gsb from "../views/Pages/Bank/Gsb.vue";
import Kbank from "../views/Pages/Bank/Kbank.vue";
import Truewallet from "../views/Pages/Bank/Truewallet.vue";
import Bankreserve from "../views/Pages/Bank/Bankreserve.vue";

// Notification
import Line from "../views/Pages/Notification/Line.vue";
// Promotion
import Bonus from "../views/Pages/Promotion/Bonus.vue";
import BonusHistory from "../views/Pages/Promotion/BonusHistory.vue";
import BonusCurrent from "../views/Pages/Promotion/BonusCurrent.vue";
// Boardcast
import Boardcast from "../views/Pages/Boardcast/Boardcast.vue";
// Zean
import Zean from "../views/Pages/Zean/Zean.vue";
// Log
import BetflixLog from "../views/Pages/Log/BetflixLog.vue";
import BonusLog from "../views/Pages/Log/BonusLog.vue";
import CreditLog from "../views/Pages/Log/CreditLog.vue";
import CodeLog from "../views/Pages/Log/CodeLog.vue";
import CodeCreateLog from "../views/Pages/Log/CodeCreateLog.vue";
import WithdrawCheckLog from "../views/Pages/Log/WithdrawCheckLog.vue";
import LoseLog from "../views/Pages/Log/LoseLog.vue";
// Code
import Code from "../views/Pages/Code/Code.vue";
// Tag
import Tag from "../views/Pages/Tag/Tag.vue";
// Popup
import Popup from "../views/Pages/Popup/Popup.vue";
// Admin
import Admin from "../views/Pages/Admin/Admin.vue";
// Spin
import Spin from "../views/Pages/Spin/Spin.vue";
import SpinHistory from "../views/Pages/Spin/SpinHistory.vue";
// Status
import Status from "../views/Pages/Status/Status.vue";
// Transaction
import Transaction_scb from "../views/Pages/Transaction/Transaction-scb.vue";
import Transaction_gsb from "../views/Pages/Transaction/Transaction-gsb.vue";
import Transaction_truewallet from "../views/Pages/Transaction/Transaction-truewallet.vue";
import Transaction_kbank from "../views/Pages/Transaction/Transaction-kbank.vue";
import Transaction_gatewayp from "../views/Pages/Transaction/Transaction-gatewayp.vue";
// Audit
import Summary from "../views/Pages/Audit/Summary.vue";
// payservice
import Payservice from "../views/Pages/Payservice/Payservicef.vue";
import Servicecalpay from "../views/Pages/Payservice/servicecalpay";
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/member",
        name: "Member",
        components: { default: Member },
      },
      {
        path: "/rank",
        name: "Rank",
        components: { default: Rank },
      },
      {
        path: "/report",
        name: "Report",
        components: { default: Report },
      },
      {
        path: "/bankreserve",
        name: "Bankreserve",
        components: { default: Bankreserve },
      },
      {
        path: "/edit",
        name: "Edit",
        components: { default: Edit },
      },
      {
        path: "/deposit",
        name: "Deposit",
        components: { default: Deposit },
      },
      {
        path: "/withdraw",
        name: "Withdraw",
        components: { default: Withdraw },
      },
      {
        path: "/noti-withdraw",
        name: "NotiWithdraw",
        components: { default: NotiWithdraw },
      },
      {
        path: "/eventsetting",
        name: "Eventsetting",
        components: { default: Eventsetting },
      },
      {
        path: "/notifaceshare",
        name: "Notifaceshare",
        components: { default: Notifaceshare },
      },
      {
        path: "/website",
        name: "Website",
        components: { default: Website },
      },
      {
        path: "/scb",
        name: "Scb",
        components: { default: Scb },
      },
      {
        path: "/Gatewaypayment",
        name: "Gatewaypayment",
        components: { default: Gatewaypayment },
      },
      {
        path: "/gsb",
        name: "Gsb",
        components: { default: Gsb },
      },
      {
        path: "/line",
        name: "Line",
        components: { default: Line },
      },
      {
        path: "/bonus",
        name: "Bonus",
        components: { default: Bonus },
      },
      {
        path: "/bonushistory",
        name: "BonusHistory",
        components: { default: BonusHistory },
      },
      {
        path: "/bonuscurrent",
        name: "BonusCurrent",
        components: { default: BonusCurrent },
      },
      {
        path: "/boardcast",
        name: "Boardcast",
        components: { default: Boardcast },
      },
      {
        path: "/zean",
        name: "Zean",
        components: { default: Zean },
      },
      {
        path: "/kbank",
        name: "Kbank",
        components: { default: Kbank },
      },
      {
        path: "/truewallet",
        name: "Truewallet",
        components: { default: Truewallet },
      },
      {
        path: "/betflixlog",
        name: "BetflixLog",
        components: { default: BetflixLog },
      },
      {
        path: "/logbonus",
        name: "BonusLog",
        components: { default: BonusLog },
      },
      {
        path: "/creditlog",
        name: "CreditLog",
        components: { default: CreditLog },
      },
      {
        path: "/loselog",
        name: "Loselog",
        components: { default: LoseLog },
      },
      {
        path: "/logcode",
        name: "CodeLog",
        components: { default: CodeLog },
      }, 
      {
        path: "/logcodecreate",
        name: "CodeCreateLog",
        components: { default: CodeCreateLog },
      },
      {
        path: "/code",
        name: "Code",
        components: { default: Code },
      },
      {
        path: "/tag",
        name: "Tag",
        components: { default: Tag },
      },
      {
        path: "/checklog",
        name: "CheckLog",
        components: { default: WithdrawCheckLog },
      },
      {
        path: "/popup",
        name: "Popup",
        components: { default: Popup },
      },
      {
        path: "/admin",
        name: "Admin",
        components: { default: Admin },
      },
      {
        path: "/spin",
        name: "Spin",
        components: { default: Spin },
      },
      {
        path: "/spinhistory",
        name: "SpinHistory",
        components: { default: SpinHistory },
      },
      {
        path: "/status",
        name: "Status",
        components: { default: Status },
      },
      {
        path: "/transaction-scb",
        name: "Transaction_scb",
        components: { default: Transaction_scb },
      },
      {
        path: "/transaction-gsb",
        name: "Transaction_GSB",
        components: { default: Transaction_gsb },
      },
      {
        path: "/transaction-truewallet",
        name: "Transaction_truewallet",
        components: { default: Transaction_truewallet },
      },
      {
        path: "/transaction-kbank",
        name: "Transaction_kbank",
        components: { default: Transaction_kbank },
      },
      {
        path: "/transaction-gatewayp",
        name: "Transaction_gatewayp",
        components: { default: Transaction_gatewayp },
      },
      {
        path: "/transfer",
        name: "Transfer",
        components: { default: Transfer },
      },
      {
        path: "/summary",
        name: "Summary",
        components: { default: Summary },
      },
      {
        path: "/payservice",
        name: "Payservice",
        components: { default: Payservice },
      },
      {
        path: "/servicecalpay",
        name: "Servicecalpay",
        components: { default: Servicecalpay },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
